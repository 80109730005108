import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 362 360">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >
 
 

 <path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M211.000000,361.000000 
	C140.690842,361.000000 70.881683,361.000000 1.072525,361.000000 
	C1.048350,360.857574 1.002986,360.715118 1.002983,360.572693 
	C0.999975,240.780853 1.000000,120.988998 1.000000,1.098576 
	C121.559105,1.098576 242.118317,1.098576 362.838745,1.098576 
	C362.838745,120.999825 362.838745,240.999893 362.838745,361.000000 
	C312.464844,361.000000 261.982422,361.000000 211.000000,361.000000 
M296.321014,187.919296 
	C296.415741,187.848099 296.592987,187.773224 296.589996,187.706390 
	C296.580261,187.488800 296.504700,187.274155 296.415070,186.383041 
	C296.410919,185.932068 296.406799,185.481079 297.136475,185.005798 
	C298.087402,184.045288 299.816406,183.124527 299.867828,182.118073 
	C300.340546,172.864120 300.557190,163.595840 300.687439,154.329453 
	C300.693939,153.868073 299.214783,153.385803 298.521393,152.401901 
	C298.627472,152.074997 298.733582,151.748108 298.952911,150.635361 
	C298.691254,149.429825 298.429596,148.224274 297.972504,146.276245 
	C297.921906,145.513550 297.871307,144.750854 298.579956,143.881714 
	C298.418030,140.884689 298.848236,137.686035 297.916901,134.951019 
	C297.092438,132.529907 298.379395,128.393127 293.651459,127.763466 
	C293.651459,127.763466 293.708984,127.443024 293.891663,126.635056 
	C294.071899,122.838127 294.279785,119.041992 294.389374,115.243027 
	C294.400269,114.865761 293.858154,114.187195 293.494446,114.127846 
	C289.004150,113.395142 288.946320,109.251656 287.252197,106.306992 
	C285.388275,103.067223 283.184784,100.022377 281.120026,96.898697 
	C279.633057,94.649086 278.245636,92.320030 276.592407,90.198997 
	C275.605286,88.932541 274.127319,88.062057 272.980011,86.904556 
	C269.264038,83.155624 265.840393,79.073669 261.843781,75.658813 
	C258.117218,72.474693 253.733231,70.071365 249.792267,67.119247 
	C248.738480,66.329865 247.627579,64.798431 247.611359,63.593281 
	C247.559555,59.744926 248.009933,55.889820 248.279449,51.840916 
	C246.571884,52.426586 245.661133,52.738956 244.673920,52.858074 
	C244.673920,52.858074 244.726974,52.657116 244.746368,51.989323 
	C244.457077,51.675587 244.167801,51.361855 243.972580,51.034935 
	C243.972580,51.034935 243.908890,51.105389 243.959518,50.483704 
	C243.643234,49.992428 243.326950,49.501156 242.510864,48.775219 
	C242.351105,48.683811 242.191345,48.592403 242.030014,48.196716 
	C242.030014,48.196716 241.929932,47.909351 241.662460,47.470592 
	C241.676971,47.133713 241.691498,46.796829 241.877380,45.580379 
	C241.917816,44.386555 241.958237,43.192730 242.278625,41.892620 
	C242.186127,41.928883 242.093613,41.965145 241.235443,42.003407 
	C239.159409,41.339508 237.083359,40.675613 234.175308,39.898621 
	C227.444656,39.585609 220.713989,39.272602 213.220047,38.861591 
	C210.818359,38.902279 208.394394,39.169334 206.019745,38.935520 
	C201.382446,38.478920 199.651611,40.596760 200.024048,45.866299 
	C200.013351,48.243916 200.002640,50.621532 199.417953,53.536488 
	C199.277878,55.690495 199.137802,57.844505 198.418518,60.555420 
	C198.273911,63.056911 198.129303,65.558403 197.400391,67.466698 
	C197.260071,65.659409 197.074738,63.854271 196.988480,62.044407 
	C196.788605,57.850937 196.636612,53.655186 196.477463,49.760040 
	C192.439362,50.297981 189.738556,50.750366 187.019150,51.002407 
	C179.363297,51.711979 171.643295,51.963795 164.055542,53.106453 
	C156.754837,54.205879 150.357788,59.256805 142.497284,58.403996 
	C141.953827,58.345032 141.281265,58.912178 140.758987,59.307438 
	C133.729889,64.627151 126.614594,69.840294 119.733368,75.345711 
	C114.590660,79.460205 109.293228,83.542847 104.915855,88.405838 
	C100.744308,93.040176 98.338509,99.332169 93.961105,103.696495 
	C87.864883,109.774506 86.969177,118.048195 83.534721,125.145531 
	C80.716621,130.969162 81.052605,138.319046 79.239342,145.134430 
	C74.768425,150.983322 77.770531,157.715485 76.938644,164.719360 
	C74.147545,169.128510 71.781448,173.893692 68.460068,177.858643 
	C64.097214,183.066879 63.709343,184.211212 67.957703,190.414597 
	C67.957703,190.414597 67.893654,190.810699 67.221420,190.967636 
	C65.083038,193.582001 62.944656,196.196350 60.125412,199.039230 
	C58.746685,203.361465 57.367958,207.683701 55.447952,212.394150 
	C53.924835,214.472382 51.961254,216.368439 51.004898,218.681061 
	C50.084293,220.907196 50.205143,223.564026 49.942245,226.858719 
	C49.944874,228.244370 49.947502,229.630020 49.426033,231.485367 
	C49.167469,237.644043 53.876850,241.738708 59.828430,240.085831 
	C66.858398,241.569809 73.888367,243.053802 81.818954,244.865814 
	C88.213982,244.602753 94.609016,244.339691 101.461578,244.226395 
	C101.461578,244.226395 101.914925,244.065323 102.559891,244.394226 
	C106.322166,243.824326 110.084435,243.254440 114.431221,243.040771 
	C117.952965,246.384842 121.474709,249.728912 125.017464,253.817169 
	C125.394463,254.492462 125.630043,255.589966 126.172379,255.771606 
	C128.844360,256.666534 130.804245,257.709351 129.241974,261.080292 
	C128.991684,261.620300 129.882141,262.593475 129.844131,263.346130 
	C129.746429,265.281799 130.080780,268.389832 129.084152,268.943176 
	C122.503532,272.596985 126.008949,276.212189 128.905731,279.587616 
	C131.406174,282.501221 131.484726,285.385620 130.187958,289.031708 
	C128.396988,288.840027 126.766144,288.665497 124.812836,288.456451 
	C125.761909,286.443726 126.352219,285.191864 127.211609,283.369324 
	C125.169228,283.369324 123.705025,283.369324 122.149376,283.369324 
	C122.397125,281.523834 122.586990,280.109558 122.776855,278.695282 
	C122.387321,278.307983 121.997780,277.920654 121.608246,277.533356 
	C119.295708,279.426697 116.983162,281.320038 113.708420,284.001160 
	C118.390175,286.995056 122.086304,289.358643 126.051819,291.894501 
	C122.194336,293.420868 119.068420,294.657745 115.609894,296.026276 
	C114.339844,293.695007 113.138832,291.490417 111.884186,289.187439 
	C111.254997,289.699646 110.747635,289.929932 110.548248,290.318298 
	C110.256256,290.887024 110.116074,291.559814 110.030121,292.204132 
	C109.155167,298.762787 108.447983,299.115509 101.735306,300.087341 
	C95.399025,301.004700 88.986412,302.072540 82.938141,304.073853 
	C78.581360,305.515411 74.811523,308.678375 70.699516,310.926514 
	C66.305611,313.328857 65.728172,317.514191 69.679146,320.767731 
	C72.954849,323.465149 75.498543,326.197357 80.753304,327.102692 
	C86.777489,328.140564 92.802704,330.840302 99.246262,331.682800 
	C110.666550,333.175995 121.965302,335.762238 133.413864,336.833649 
	C147.263168,338.129761 161.210449,338.479492 175.125427,338.920166 
	C180.231720,339.081879 185.363174,338.140717 190.489655,338.042725 
	C199.394547,337.872528 208.305374,338.029175 217.211777,337.907318 
	C219.504593,337.875946 221.781143,337.201843 224.080734,337.020874 
	C232.987076,336.320007 241.898727,335.685547 250.810364,335.054047 
	C255.257919,334.738831 259.709076,334.472870 264.159760,334.203766 
	C269.202881,333.898804 274.334869,334.100281 279.270660,333.209045 
	C286.708069,331.866119 294.010040,329.771332 301.365662,327.979980 
	C302.142670,327.790771 303.271088,327.730072 303.597046,327.209442 
	C306.390533,322.747253 311.215240,322.384949 315.508606,320.734161 
	C317.034637,320.147430 318.248810,317.624146 318.688751,315.767639 
	C318.945679,314.683472 317.664032,312.866608 316.599640,311.905182 
	C313.172699,308.809784 307.955841,309.266235 304.556671,307.271973 
	C291.356628,299.527649 276.327484,300.667145 262.362427,296.981598 
	C258.329132,295.917175 254.405685,294.442505 250.389160,293.306976 
	C246.336258,292.161255 246.698044,290.517303 249.222107,287.816223 
	C251.434830,285.448395 253.120697,282.588196 254.999634,280.000122 
	C251.179184,280.466797 247.871536,280.870819 243.695847,281.380890 
	C244.557709,283.146942 245.534225,285.147949 246.647705,287.429596 
	C245.016388,289.518707 240.596603,290.698517 242.361465,294.951202 
	C241.574310,294.633331 240.787155,294.315460 239.683578,293.364258 
	C238.395081,291.042358 237.106583,288.720459 235.791245,286.350189 
	C228.433853,291.378387 220.455429,294.621643 211.373840,293.930664 
	C206.489029,293.558990 201.359238,291.322449 196.838470,292.280243 
	C186.550201,294.459900 176.410416,290.698425 165.873688,293.511505 
	C157.034485,295.871368 147.064331,293.936188 137.596085,294.027039 
	C133.686874,294.064545 133.297897,292.227844 134.880325,289.361267 
	C136.458176,286.503021 138.283691,283.781464 140.000000,281.000000 
	C140.000000,281.000000 139.999390,281.000000 140.732544,280.986786 
	C145.327820,278.344604 145.740189,276.325470 142.349152,272.861664 
	C141.188400,271.675964 140.019012,270.483032 138.739334,269.432587 
	C135.532654,266.800201 137.378906,265.316772 139.932114,264.050537 
	C140.956650,263.542419 142.105209,263.284424 143.818710,262.946411 
	C144.544785,263.304871 145.270844,263.663330 146.089645,264.731842 
	C149.157242,269.072784 153.960861,270.594421 160.007919,268.960541 
	C163.296555,269.644928 166.563828,270.771179 169.877808,270.931427 
	C180.095306,271.425476 190.330643,271.550598 201.457336,271.956543 
	C204.778168,271.728210 208.097931,271.482330 211.420105,271.275818 
	C215.843872,271.000793 220.495087,271.523834 224.637817,270.306335 
	C229.919479,268.754120 234.738831,265.682495 239.864120,263.518433 
	C241.959747,262.633575 244.334595,262.410065 246.738205,261.849365 
	C247.681229,260.300049 249.159012,257.959534 250.526962,255.556503 
	C250.826965,255.029510 250.736069,254.279984 251.215988,253.099854 
	C258.466553,246.393097 265.717102,239.686340 273.000061,233.000000 
	C273.000061,233.000000 272.979279,232.967743 273.602661,232.758347 
	C276.872345,227.988281 280.142029,223.218201 283.400604,218.368866 
	C283.400604,218.368866 283.437378,218.297775 283.906403,217.809036 
	C285.636688,214.870483 287.367004,211.931931 289.561005,208.583954 
	C289.623199,208.185608 289.685425,207.787262 289.726349,207.143234 
	C289.726349,207.143234 289.930267,207.004593 290.471191,206.601562 
	C290.544434,206.169708 290.617706,205.737839 290.659180,205.152954 
	C290.659180,205.152954 290.741547,205.020126 291.359863,204.641754 
	C291.465149,204.186005 291.570404,203.730240 291.559540,202.812927 
	C291.602112,202.481522 291.751770,202.208969 292.519379,201.597916 
	C292.592499,201.182709 292.665619,200.767502 292.590393,199.868210 
	C292.741821,199.576355 292.893250,199.284500 293.540619,198.592087 
	C293.607971,198.181732 293.675354,197.771362 293.685181,197.151581 
	C293.685181,197.151581 293.831543,196.991135 294.217010,196.909470 
	C294.315887,196.826355 294.511993,196.712692 294.497101,196.665344 
	C294.426361,196.440186 294.298157,196.233078 294.201233,195.322052 
	C294.368469,194.890747 294.535706,194.459442 295.211639,193.841187 
	C295.230713,192.926102 295.249756,192.011032 295.341492,190.389664 
	C295.348602,189.916458 295.355682,189.443253 295.681976,188.716385 
	C295.865540,188.501022 295.964478,188.252029 295.996704,188.002823 
	C295.996704,188.002823 295.966614,187.977951 296.321014,187.919296 
M242.542648,29.199377 
	C242.542648,31.885025 242.542648,34.570675 242.542648,37.609421 
	C245.271683,37.219746 247.973312,36.833984 250.742218,36.438618 
	C248.746277,33.680611 247.234222,31.398560 245.488388,29.312143 
	C245.042908,28.779764 243.856567,28.867319 242.542648,29.199377 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M140.000610,281.000000 
	C138.283691,283.781464 136.458176,286.503021 134.880325,289.361267 
	C133.297897,292.227844 133.686874,294.064545 137.596085,294.027039 
	C147.064331,293.936188 157.034485,295.871368 165.873688,293.511505 
	C176.410416,290.698425 186.550201,294.459900 196.838470,292.280243 
	C201.359238,291.322449 206.489029,293.558990 211.373840,293.930664 
	C220.455429,294.621643 228.433853,291.378387 235.791245,286.350189 
	C237.106583,288.720459 238.395081,291.042358 239.841797,294.097565 
	C239.999191,296.219360 239.998367,297.607849 239.628601,298.990204 
	C236.696457,299.356445 235.557770,293.586456 232.333740,297.253357 
	C229.951172,299.963226 228.049301,298.510529 225.936340,296.642456 
	C225.061813,295.884888 224.115784,294.985199 223.464661,295.163361 
	C219.273819,296.309998 215.147507,297.692505 210.666962,298.997925 
	C209.889893,298.998016 209.445862,298.997772 208.910339,298.601349 
	C202.031418,297.503174 195.236832,296.163147 188.458145,296.239258 
	C176.379532,296.374817 164.311234,297.380524 152.237030,297.990845 
	C148.992966,298.154877 145.626221,297.696045 142.519226,298.409302 
	C135.043045,300.125519 127.689384,302.344788 121.243668,295.479034 
	C120.030365,297.696198 119.181511,299.247375 118.332657,300.798553 
	C115.345314,300.658966 112.870705,301.475861 110.394333,302.287384 
	C107.595749,303.204529 104.693573,303.887665 102.017349,305.078979 
	C96.481903,307.542908 90.657188,309.681732 85.724129,313.076935 
	C81.361984,316.079224 82.291786,318.310547 86.620644,321.388977 
	C95.792175,327.911285 106.873489,329.202606 117.128952,330.535156 
	C138.585159,333.323059 160.348206,333.922302 182.008774,334.898102 
	C194.474350,335.459717 206.985947,335.005463 219.476822,334.992798 
	C220.972610,334.991272 222.477432,334.978149 223.962616,334.822754 
	C235.952454,333.568298 248.021103,332.783600 259.900391,330.843353 
	C269.824219,329.222443 279.580750,326.437439 289.295502,323.749237 
	C292.867401,322.760803 296.107178,320.572052 299.644714,318.859985 
	C300.167908,313.982422 298.387299,311.381866 293.751495,309.928680 
	C289.078278,308.463684 284.503540,306.687103 279.863739,305.111481 
	C278.459412,304.634552 276.968994,304.417999 275.540710,304.003815 
	C264.730621,300.869171 252.848343,301.606873 243.000000,295.000000 
	C240.596603,290.698517 245.016388,289.518707 246.647705,287.429596 
	C245.534225,285.147949 244.557709,283.146942 243.695847,281.380890 
	C247.871536,280.870819 251.179184,280.466797 254.999634,280.000122 
	C253.120697,282.588196 251.434830,285.448395 249.222107,287.816223 
	C246.698044,290.517303 246.336258,292.161255 250.389160,293.306976 
	C254.405685,294.442505 258.329132,295.917175 262.362427,296.981598 
	C276.327484,300.667145 291.356628,299.527649 304.556671,307.271973 
	C307.955841,309.266235 313.172699,308.809784 316.599640,311.905182 
	C317.664032,312.866608 318.945679,314.683472 318.688751,315.767639 
	C318.248810,317.624146 317.034637,320.147430 315.508606,320.734161 
	C311.215240,322.384949 306.390533,322.747253 303.597046,327.209442 
	C303.271088,327.730072 302.142670,327.790771 301.365662,327.979980 
	C294.010040,329.771332 286.708069,331.866119 279.270660,333.209045 
	C274.334869,334.100281 269.202881,333.898804 264.159760,334.203766 
	C259.709076,334.472870 255.257919,334.738831 250.810364,335.054047 
	C241.898727,335.685547 232.987076,336.320007 224.080734,337.020874 
	C221.781143,337.201843 219.504593,337.875946 217.211777,337.907318 
	C208.305374,338.029175 199.394547,337.872528 190.489655,338.042725 
	C185.363174,338.140717 180.231720,339.081879 175.125427,338.920166 
	C161.210449,338.479492 147.263168,338.129761 133.413864,336.833649 
	C121.965302,335.762238 110.666550,333.175995 99.246262,331.682800 
	C92.802704,330.840302 86.777489,328.140564 80.753304,327.102692 
	C75.498543,326.197357 72.954849,323.465149 69.679146,320.767731 
	C65.728172,317.514191 66.305611,313.328857 70.699516,310.926514 
	C74.811523,308.678375 78.581360,305.515411 82.938141,304.073853 
	C88.986412,302.072540 95.399025,301.004700 101.735306,300.087341 
	C108.447983,299.115509 109.155167,298.762787 110.030121,292.204132 
	C110.116074,291.559814 110.256256,290.887024 110.548248,290.318298 
	C110.747635,289.929932 111.254997,289.699646 111.884186,289.187439 
	C113.138832,291.490417 114.339844,293.695007 115.609894,296.026276 
	C119.068420,294.657745 122.194336,293.420868 126.051819,291.894501 
	C122.086304,289.358643 118.390175,286.995056 113.708420,284.001160 
	C116.983162,281.320038 119.295708,279.426697 121.608246,277.533356 
	C121.997780,277.920654 122.387321,278.307983 122.776855,278.695282 
	C122.586990,280.109558 122.397125,281.523834 122.149376,283.369324 
	C123.705025,283.369324 125.169228,283.369324 127.211609,283.369324 
	C126.352219,285.191864 125.761909,286.443726 124.812836,288.456451 
	C126.766144,288.665497 128.396988,288.840027 130.187958,289.031708 
	C131.484726,285.385620 131.406174,282.501221 128.905731,279.587616 
	C126.008949,276.212189 122.503532,272.596985 129.084152,268.943176 
	C130.080780,268.389832 129.746429,265.281799 129.844131,263.346130 
	C129.882141,262.593475 128.991684,261.620300 129.241974,261.080292 
	C130.804245,257.709351 128.844360,256.666534 126.172379,255.771606 
	C125.630043,255.589966 125.394463,254.492462 125.371056,253.395172 
	C131.548874,256.286285 137.373093,259.599426 143.197311,262.912567 
	C142.105209,263.284424 140.956650,263.542419 139.932114,264.050537 
	C137.378906,265.316772 135.532654,266.800201 138.739334,269.432587 
	C140.019012,270.483032 141.188400,271.675964 142.349152,272.861664 
	C145.740189,276.325470 145.327820,278.344604 140.371399,280.617981 
	C139.996368,278.332397 140.435608,276.266479 139.865005,274.532959 
	C138.953125,271.762604 133.388382,269.579132 132.114563,271.430267 
	C130.965012,273.100800 130.415695,276.298889 131.262985,277.991821 
	C132.914108,281.290833 136.150024,282.708771 140.000610,281.000000 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M239.997559,298.996338 
	C239.998367,297.607849 239.999191,296.219360 240.000000,294.414215 
	C240.787155,294.315460 241.574310,294.633331 242.680725,294.975586 
	C252.848343,301.606873 264.730621,300.869171 275.540710,304.003815 
	C276.968994,304.417999 278.459412,304.634552 279.863739,305.111481 
	C284.503540,306.687103 289.078278,308.463684 293.751495,309.928680 
	C298.387299,311.381866 300.167908,313.982422 299.644714,318.859985 
	C296.107178,320.572052 292.867401,322.760803 289.295502,323.749237 
	C279.580750,326.437439 269.824219,329.222443 259.900391,330.843353 
	C248.021103,332.783600 235.952454,333.568298 223.962616,334.822754 
	C222.477432,334.978149 220.972610,334.991272 219.476822,334.992798 
	C206.985947,335.005463 194.474350,335.459717 182.008774,334.898102 
	C160.348206,333.922302 138.585159,333.323059 117.128952,330.535156 
	C106.873489,329.202606 95.792175,327.911285 86.620644,321.388977 
	C82.291786,318.310547 81.361984,316.079224 85.724129,313.076935 
	C90.657188,309.681732 96.481903,307.542908 102.017349,305.078979 
	C104.693573,303.887665 107.595749,303.204529 110.394333,302.287384 
	C112.870705,301.475861 115.345314,300.658966 118.332657,300.798553 
	C119.181511,299.247375 120.030365,297.696198 121.243668,295.479034 
	C127.689384,302.344788 135.043045,300.125519 142.519226,298.409302 
	C145.626221,297.696045 148.992966,298.154877 152.237030,297.990845 
	C164.311234,297.380524 176.379532,296.374817 188.458145,296.239258 
	C195.236832,296.163147 202.031418,297.503174 208.442230,298.602234 
	C201.632614,299.328247 195.202774,299.822205 188.765823,299.945770 
	C180.054169,300.113007 171.329239,299.796478 162.627670,300.137878 
	C160.383896,300.225952 158.206207,301.998718 155.665359,302.998138 
	C154.888779,302.998718 154.444992,302.998749 153.910767,302.604065 
	C149.518677,301.985718 145.166962,301.254242 140.928604,301.672180 
	C136.072968,302.150970 131.341904,303.781891 126.510948,304.660461 
	C123.192635,305.263977 119.745850,305.192505 116.448013,305.868927 
	C112.598434,306.658508 108.906357,308.345703 105.039932,308.889130 
	C100.665283,309.503967 96.871765,311.589233 97.447151,315.683868 
	C97.824989,318.372681 101.609665,321.327667 104.551811,322.724701 
	C108.320122,324.514008 112.808083,324.734802 116.928543,325.844604 
	C133.991623,330.440521 151.513321,331.065857 169.447128,331.997101 
	C172.260635,331.997772 174.630325,331.997650 177.293915,332.294678 
	C178.397903,332.727356 179.212646,333.004333 180.017334,332.977692 
	C199.579285,332.329651 219.152359,331.881195 238.694748,330.852173 
	C245.783600,330.478851 252.897232,329.114716 259.819550,327.452820 
	C266.705536,325.799622 273.389954,323.276093 280.107269,320.972443 
	C281.770203,320.402161 284.484711,319.232666 284.504486,318.292145 
	C284.546906,316.273529 283.674500,313.640900 282.215576,312.325989 
	C280.069031,310.391327 277.229492,308.823181 274.426483,308.096619 
	C266.801941,306.120239 259.002167,304.827454 251.351471,302.941010 
	C247.478104,301.985962 243.776703,300.333496 239.997559,298.996338 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M235.007324,40.011719 
	C237.083359,40.675613 239.159409,41.339508 241.617722,42.001701 
	C242.000000,42.000000 241.998672,41.998905 241.998672,41.998905 
	C241.958237,43.192730 241.917816,44.386555 241.450653,46.072548 
	C240.054535,47.653675 238.375412,48.660965 238.239090,49.845779 
	C237.661850,54.862221 237.543259,59.931431 237.147095,65.421890 
	C236.953720,68.872429 236.870300,71.882423 236.644745,75.017761 
	C236.458649,75.261185 236.349167,75.405045 236.383514,75.492287 
	C236.471313,75.715309 236.623184,75.913116 236.750198,76.543076 
	C236.642838,80.876373 236.534698,84.787605 236.271667,89.066635 
	C236.134003,89.960968 236.151199,90.487503 236.138947,91.422028 
	C236.156067,93.190437 236.202637,94.550842 236.158295,96.339828 
	C236.076035,98.509987 236.084686,100.251564 236.052216,102.370255 
	C236.010040,103.507301 236.008972,104.267235 236.001129,105.481567 
	C236.001053,114.826210 236.001038,114.826042 244.646317,113.945435 
	C244.811157,113.928642 244.977753,113.929161 245.512207,113.949234 
	C247.938522,113.335381 249.996170,112.693878 252.466187,112.051590 
	C257.217194,110.799896 261.555847,109.549004 266.052429,108.550140 
	C267.283478,108.588295 268.356537,108.374428 269.551941,108.440460 
	C269.807190,108.874626 269.940125,109.028908 269.977386,109.537483 
	C268.487885,115.715813 267.094055,121.539841 265.743774,127.434967 
	C265.787354,127.506058 265.939514,127.437889 265.618591,127.605652 
	C264.939819,129.132019 264.581940,130.490646 264.064453,132.191360 
	C263.321655,134.675095 262.738464,136.816742 261.934326,139.281006 
	C261.113068,142.398987 260.512726,145.194305 259.646790,148.121063 
	C258.587921,148.815659 257.794647,149.378815 256.653137,149.998611 
	C255.189972,150.384506 254.075027,150.713776 252.542603,150.992126 
	C248.070053,151.662994 244.037140,152.581421 239.952606,153.041946 
	C236.898300,153.386307 236.157318,154.843018 236.371521,157.642044 
	C236.762024,162.744949 236.907684,167.866577 237.088959,173.435593 
	C237.090942,177.258896 237.159470,180.626999 237.167374,184.266388 
	C237.182220,184.706909 237.257690,184.876160 237.331619,185.420959 
	C237.413422,186.788498 237.496780,187.780487 237.420288,189.183609 
	C237.554535,195.389664 237.848618,201.184555 238.079559,207.440918 
	C238.355591,213.597977 238.694748,219.293564 239.013504,225.438568 
	C239.352493,230.919403 239.711884,235.950806 240.066040,241.390823 
	C240.115265,242.613815 240.169754,243.428207 240.071533,244.611557 
	C226.354294,248.461685 212.734589,246.965088 199.364899,245.932861 
	C198.223862,241.867569 197.231262,238.331131 196.276672,234.404587 
	C196.153320,230.020477 195.991974,226.026505 195.761383,221.632126 
	C195.401993,218.498230 195.111847,215.764709 194.893585,212.598907 
	C194.333435,207.101624 193.701401,202.036667 193.058472,196.513153 
	C192.970993,192.404648 192.894424,188.754684 192.836243,184.765747 
	C192.524704,183.635513 192.194778,182.844238 192.241547,182.072998 
	C194.084137,182.399231 195.550018,182.705414 197.036301,183.306549 
	C197.370529,184.067871 197.684357,184.534225 197.995010,185.376266 
	C197.992340,186.503571 197.992813,187.255219 197.991531,188.339264 
	C197.994339,189.114853 197.998886,189.558029 198.003738,190.448242 
	C198.035217,193.283432 198.066376,195.671616 198.086639,198.456482 
	C198.411880,201.244003 198.748047,203.634842 199.064545,206.441406 
	C199.350616,209.233871 199.656357,211.610611 199.954575,214.428802 
	C200.031174,216.924164 200.115265,218.978073 200.175217,221.368988 
	C200.416107,222.795547 200.681152,223.885117 200.932999,225.403259 
	C201.211639,228.248779 201.503464,230.665726 201.801880,233.489899 
	C202.457779,237.704056 201.973648,242.364212 208.282928,242.350983 
	C210.038010,242.637711 211.510788,242.696411 213.448395,242.828278 
	C217.852997,242.907166 221.793518,242.965912 225.732346,242.908112 
	C235.401596,242.766235 235.401276,242.744644 234.947632,232.611053 
	C234.917465,231.480927 234.861679,230.728241 234.859268,229.526703 
	C234.292618,224.668762 233.672607,220.259674 233.040817,215.638702 
	C233.029037,215.426788 232.955963,215.008652 232.991623,214.614975 
	C233.037613,213.170258 233.047943,212.119232 233.346375,210.796417 
	C233.441574,207.341202 233.248672,204.157745 233.064789,200.531982 
	C233.043686,197.728745 233.013565,195.367783 232.997253,192.548767 
	C232.994720,188.737442 232.978394,185.384201 232.976990,181.585327 
	C232.983353,178.764404 232.974792,176.389114 232.975586,173.552032 
	C232.658600,169.059723 232.332260,165.029190 232.005798,160.554810 
	C232.005157,159.452988 232.030212,158.793854 232.000076,158.137238 
	C231.789734,153.553375 232.324600,149.606873 238.286438,149.259354 
	C239.233994,149.204117 240.103134,147.803574 241.371811,147.059189 
	C243.492706,147.066559 245.250351,147.039764 247.424500,147.009949 
	C256.234619,146.489960 256.453125,146.254623 258.219574,137.801117 
	C258.822906,134.913727 259.650940,132.072510 260.402557,129.217087 
	C261.665833,124.417885 262.950165,119.624199 264.486450,113.847397 
	C259.866272,115.120911 256.437561,116.066002 252.612900,117.008194 
	C249.475906,118.000710 246.734863,118.996124 243.676849,120.109230 
	C241.247269,120.154495 239.134644,120.082069 236.630951,120.004349 
	C231.806808,120.235924 231.432449,116.491272 231.475952,113.926796 
	C231.617157,105.604248 232.431458,97.293114 232.989517,88.512512 
	C232.989441,82.693611 232.986420,77.339775 232.986664,71.548576 
	C232.986694,69.069328 232.983444,67.027451 232.989655,64.563385 
	C233.313797,60.413250 233.628479,56.685303 233.960480,52.526245 
	C234.320953,48.067329 234.664139,44.039524 235.007324,40.011719 
z"/>
<path fill="#F3F3F7" opacity="1.000000" stroke="none" 
	d="
M232.986572,88.977585 
	C232.431458,97.293114 231.617157,105.604248 231.475952,113.926796 
	C231.432449,116.491272 231.806808,120.235924 236.615784,120.398911 
	C235.199127,126.432610 233.839890,132.253922 231.504059,137.652710 
	C228.597549,144.370483 226.480118,151.691544 219.673004,156.082291 
	C214.673370,159.307159 209.883240,162.882965 205.189255,166.545227 
	C202.590729,168.572647 200.046875,169.284409 196.846405,167.646606 
	C195.273773,163.905823 195.709885,161.508514 199.430771,159.628281 
	C203.602600,157.520187 208.029053,155.250641 211.166641,151.936127 
	C214.776123,148.123108 217.124741,143.107178 219.964508,138.578323 
	C221.670502,135.857605 224.103714,133.247925 224.739822,130.273880 
	C226.023773,124.271004 226.654556,118.068520 226.916550,111.921173 
	C227.298767,102.952827 224.918472,94.927704 216.998550,89.525543 
	C214.386276,87.743706 212.029266,84.827553 208.252380,87.603508 
	C207.762985,87.963203 206.206802,86.871483 205.144775,86.452065 
	C205.912292,85.636749 206.600159,84.719666 207.471817,84.037384 
	C208.207199,83.461769 209.155899,83.158691 210.396072,82.543579 
	C205.460876,79.762703 201.214340,77.369881 196.967926,74.742081 
	C196.968033,74.507103 196.980453,74.037315 197.123322,73.709816 
	C197.503891,72.236725 197.741592,71.091125 197.975464,69.630867 
	C197.975998,68.897430 197.980347,68.478668 197.984695,68.059898 
	C198.129303,65.558403 198.273911,63.056911 198.917191,60.559753 
	C200.615311,63.221085 201.709930,65.932968 203.059616,68.511322 
	C203.708618,69.751122 204.778381,71.578117 205.765427,71.649574 
	C207.357559,71.764824 209.717941,71.006607 207.563782,68.406387 
	C208.376251,67.937080 209.188736,67.467773 210.137894,67.286560 
	C211.515610,68.717857 212.756653,69.861053 214.142075,71.328568 
	C215.164413,73.221535 215.980881,74.829002 216.939453,76.346741 
	C217.729767,77.598038 218.618851,79.701912 219.584183,79.771866 
	C224.161316,80.103539 226.237411,83.219131 227.755630,86.692307 
	C228.945816,89.415062 230.435852,90.054016 232.986572,88.977585 
z"/>
<path fill="#EBFAFD" opacity="1.000000" stroke="none" 
	d="
M49.868412,226.031616 
	C50.205143,223.564026 50.084293,220.907196 51.004898,218.681061 
	C51.961254,216.368439 53.924835,214.472382 56.067696,212.165649 
	C58.814163,210.061417 60.940887,208.185684 63.425491,206.237579 
	C66.188652,204.766281 68.593925,203.367386 71.330971,201.928467 
	C73.112648,200.866348 74.562561,199.844269 76.360733,198.838455 
	C78.904076,197.639481 81.099159,196.424240 83.635620,195.159912 
	C85.646278,194.370377 87.315559,193.629929 89.278809,192.880569 
	C90.034676,192.548325 90.496574,192.224991 91.327682,191.890991 
	C96.458923,189.902786 101.220955,187.925232 106.355240,185.936813 
	C109.823784,184.624664 112.920082,183.323395 116.344894,182.008514 
	C117.111183,181.971985 117.548973,181.949036 118.363159,181.945557 
	C120.488022,182.979477 122.236481,183.993942 123.993973,185.473923 
	C124.327629,190.965851 124.652245,195.992249 124.999573,201.400177 
	C125.104279,202.542389 125.186264,203.303101 125.223152,204.469254 
	C125.512604,207.264343 125.847160,209.654007 126.148727,212.457245 
	C126.410835,214.915039 126.705933,216.959213 127.011284,219.422485 
	C127.069542,221.235214 127.117546,222.628876 127.148087,224.374451 
	C133.643524,227.819397 140.106705,231.024536 146.695419,233.946671 
	C149.106094,235.015823 151.814606,235.413483 154.903000,236.258606 
	C154.404404,232.920105 154.116608,230.993179 153.876038,228.797119 
	C153.905075,228.346756 153.886887,228.165512 153.914810,227.561417 
	C153.846558,225.444946 153.732193,223.751328 153.663239,221.655426 
	C153.427979,218.180252 153.147293,215.107376 152.914764,211.606354 
	C152.917282,209.466187 152.871658,207.754166 152.866272,205.629257 
	C152.575409,202.484100 152.244324,199.751816 151.936813,196.620316 
	C151.954102,195.154831 151.947800,194.088593 151.961060,192.603912 
	C151.992493,190.792160 152.004379,189.398834 152.284515,187.779053 
	C152.040451,185.364899 151.528152,183.177155 151.173584,180.629089 
	C151.887177,177.181808 152.443069,174.094879 153.349762,171.009674 
	C157.133911,170.340820 160.567261,169.670227 164.414948,168.990936 
	C169.498032,167.721649 174.112839,166.162216 178.854675,165.306931 
	C181.951019,164.748444 185.231659,165.211777 188.102661,165.433502 
	C179.836807,167.666351 171.898041,169.683121 163.842865,171.575699 
	C163.486115,171.612930 163.245789,171.774338 162.995331,171.971100 
	C162.985184,172.006485 162.914551,171.985779 162.589233,171.980804 
	C161.517181,172.301590 160.770477,172.627350 159.792664,173.072266 
	C159.221466,173.197876 158.881393,173.204315 158.190491,173.158661 
	C157.231384,173.370056 156.623093,173.633545 155.685669,174.178345 
	C155.299072,180.830811 155.197632,187.202316 155.246094,193.572678 
	C155.251465,194.280121 156.111298,194.981033 156.497391,196.156097 
	C156.339218,199.395844 156.258530,202.164490 156.101822,205.340881 
	C156.088120,206.856064 156.150421,207.963470 156.148544,209.484344 
	C156.469238,213.904678 156.854126,217.911514 157.260712,222.284653 
	C157.639709,224.067841 157.997009,225.484726 158.254547,227.317780 
	C158.457169,232.162521 158.759552,236.591125 158.716705,241.083496 
	C157.905121,241.156433 157.438766,241.165588 156.599731,241.081665 
	C148.628708,238.328674 141.029480,235.671280 133.432297,233.008057 
	C127.657600,230.983688 122.632233,228.287888 122.821243,220.589294 
	C122.425964,216.438065 121.971886,212.707504 121.546097,208.577103 
	C121.254997,205.127930 120.935600,202.078613 120.652069,198.619766 
	C120.292755,194.242172 119.897575,190.274094 119.425919,185.538208 
	C112.891815,188.121948 106.864532,190.505280 100.620811,192.882172 
	C100.404381,192.875732 99.996475,193.021149 99.692268,193.003082 
	C98.905487,193.309509 98.422920,193.633987 97.715973,194.009033 
	C97.491608,194.059586 97.087929,194.280121 96.782379,194.234375 
	C95.823914,194.616302 95.170990,195.043976 94.340912,195.505615 
	C94.163742,195.539566 93.858147,195.731323 93.484047,195.738617 
	C88.747559,197.865479 84.385170,199.985062 79.780746,202.129044 
	C79.203262,202.281189 79.006203,202.522141 78.918121,202.871063 
	C78.888710,202.865860 78.838516,202.898239 78.489304,202.916962 
	C73.705902,205.582977 69.271713,208.230286 64.496017,210.938873 
	C57.935444,214.625854 52.858471,219.337021 49.868412,226.031616 
z"/>
<path fill="#2C3C8E" opacity="1.000000" stroke="none" 
	d="
M296.402649,185.030106 
	C296.406799,185.481079 296.410919,185.932068 296.270782,186.928284 
	C296.073181,187.641663 296.019897,187.809814 295.966614,187.977951 
	C295.966614,187.977951 295.996704,188.002823 295.731262,188.039337 
	C295.431488,188.373917 295.397125,188.671982 295.362793,188.970047 
	C295.355682,189.443253 295.348602,189.916458 294.934509,190.811005 
	C294.585999,192.164276 294.644470,193.096207 294.702942,194.028137 
	C294.535706,194.459442 294.368469,194.890747 294.016357,195.880524 
	C293.831543,196.623047 293.831543,196.807098 293.831543,196.991135 
	C293.831543,196.991135 293.685181,197.151581 293.470581,197.461731 
	C293.185577,198.178802 293.115112,198.585724 293.044678,198.992645 
	C292.893250,199.284500 292.741821,199.576355 292.414551,200.316162 
	C292.161957,201.174500 292.085236,201.584869 292.008545,201.995239 
	C291.751770,202.208969 291.602112,202.481522 291.342407,203.259521 
	C290.997406,204.144119 290.869476,204.582123 290.741547,205.020126 
	C290.741547,205.020126 290.659180,205.152954 290.427795,205.446808 
	C290.107666,206.161972 290.018951,206.583282 289.930267,207.004593 
	C289.930267,207.004593 289.726349,207.143234 289.515198,207.385498 
	C289.189575,207.750412 289.075073,207.873032 288.646240,208.146027 
	C284.567902,210.586563 282.786133,213.846832 283.437378,218.297775 
	C283.437378,218.297775 283.400604,218.368866 283.043060,218.303589 
	C280.345612,221.028931 277.808746,223.682587 275.738159,226.660446 
	C274.453003,228.508698 273.874817,230.848557 272.979279,232.967743 
	C272.979279,232.967743 273.000061,233.000000 272.615967,232.994751 
	C269.763397,235.051285 267.336578,237.165695 264.814880,239.160156 
	C262.263733,241.177887 259.618683,243.076904 257.012817,244.678619 
	C258.364838,240.936172 254.974930,240.222885 253.477325,238.559830 
	C253.245758,238.302658 251.102783,239.816757 249.810028,240.442032 
	C249.243805,240.715942 248.592773,240.814529 248.025604,240.616486 
	C248.616928,237.737518 249.162872,235.234787 249.692978,232.835602 
	C249.677124,232.939163 249.676086,232.729111 249.875458,232.756058 
	C250.189926,232.721817 250.305008,232.660614 250.420105,232.599426 
	C250.279877,232.402695 250.139648,232.205963 250.016876,231.666077 
	C250.082031,230.867432 250.129730,230.411942 250.423279,229.676208 
	C251.777359,226.260254 252.885620,223.124573 253.996582,220.419708 
	C253.999283,223.055267 253.999283,225.260040 253.999283,228.248093 
	C258.030029,225.333313 261.224060,223.352921 264.025726,220.920578 
	C268.247803,217.255112 272.434662,213.490265 276.180237,209.354462 
	C278.902954,206.348114 280.318512,202.019775 283.306610,199.417175 
	C293.267883,190.740952 294.977753,178.865250 296.990753,166.554840 
	C296.670380,162.384506 296.346832,158.653625 296.331543,154.930115 
	C297.049896,154.965927 297.459991,154.994339 297.921509,155.441956 
	C298.297852,158.587982 299.081543,161.353012 298.858490,164.034271 
	C298.274628,171.052414 297.251007,178.033966 296.402649,185.030106 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M196.980453,74.037315 
	C196.980453,74.037315 196.968033,74.507103 196.691376,75.006195 
	C195.608170,78.999451 194.801636,82.493614 193.989990,86.450806 
	C193.982147,89.009758 194.214081,91.140511 193.909714,93.191673 
	C193.614807,95.178940 192.768509,97.084381 192.080109,98.620270 
	C191.906479,97.120415 191.817108,96.026054 191.782135,94.513641 
	C191.820847,92.409271 191.805176,90.722969 191.892700,88.671860 
	C191.966431,87.555725 191.936981,86.804390 191.959900,85.619492 
	C192.274231,81.820610 192.536209,78.455284 192.847076,74.794022 
	C192.856598,74.302055 192.817215,74.106010 192.858383,73.477577 
	C193.128265,70.435547 193.317581,67.825912 193.662399,64.824356 
	C194.028915,60.843090 194.239944,57.253746 194.483307,53.114597 
	C174.426956,52.526550 156.057739,56.338440 138.572235,65.833565 
	C129.405212,72.558052 120.587967,79.198395 111.521904,85.934090 
	C111.073364,86.316162 110.873642,86.602882 110.440582,87.014763 
	C109.777573,87.679848 109.347908,88.219780 108.629799,88.944183 
	C93.748001,105.820770 83.779884,124.669731 81.231934,147.368103 
	C80.994141,148.159836 80.865341,148.613159 80.520416,148.787567 
	C80.199692,147.336563 80.095100,146.164490 79.990509,144.992401 
	C81.052605,138.319046 80.716621,130.969162 83.534721,125.145531 
	C86.969177,118.048195 87.864883,109.774506 93.961105,103.696495 
	C98.338509,99.332169 100.744308,93.040176 104.915855,88.405838 
	C109.293228,83.542847 114.590660,79.460205 119.733368,75.345711 
	C126.614594,69.840294 133.729889,64.627151 140.758987,59.307438 
	C141.281265,58.912178 141.953827,58.345032 142.497284,58.403996 
	C150.357788,59.256805 156.754837,54.205879 164.055542,53.106453 
	C171.643295,51.963795 179.363297,51.711979 187.019150,51.002407 
	C189.738556,50.750366 192.439362,50.297981 196.477463,49.760040 
	C196.636612,53.655186 196.788605,57.850937 196.988480,62.044407 
	C197.074738,63.854271 197.260071,65.659409 197.692535,67.763298 
	C197.980347,68.478668 197.975998,68.897430 197.633575,69.698807 
	C197.190506,71.400055 197.085480,72.718681 196.980453,74.037315 
z"/>
<path fill="#E3E5EC" opacity="1.000000" stroke="none" 
	d="
M213.997696,71.004257 
	C212.756653,69.861053 211.515610,68.717857 210.176300,66.922287 
	C212.018127,63.011356 212.643692,59.616264 209.113998,57.252079 
	C206.359512,55.407139 203.053085,54.386234 199.991928,52.999149 
	C200.002640,50.621532 200.013351,48.243916 200.457794,45.428528 
	C203.736053,45.960388 206.749725,46.604057 209.387146,47.972713 
	C213.827332,50.276882 218.225784,52.768394 222.273315,55.691334 
	C226.089432,58.447151 229.430283,61.861126 232.980194,64.985573 
	C232.983444,67.027451 232.986694,69.069328 232.577728,71.554764 
	C231.184143,72.136269 230.204437,72.373802 229.221054,72.390266 
	C227.476868,72.419472 225.730438,72.314484 223.984924,72.265633 
	C224.076340,70.681427 224.167755,69.097214 224.258591,67.512985 
	C218.053528,69.990021 217.452637,69.636482 217.041428,62.996284 
	C213.969467,64.088234 211.667328,65.236198 214.263199,68.933174 
	C214.558655,69.353951 214.104355,70.301147 213.997696,71.004257 
z"/>
<path fill="#E1F3ED" opacity="1.000000" stroke="none" 
	d="
M68.016724,190.017792 
	C63.709343,184.211212 64.097214,183.066879 68.460068,177.858643 
	C71.781448,173.893692 74.147545,169.128510 77.323685,164.299057 
	C78.421906,162.247864 79.135071,160.616989 79.933319,159.417099 
	C80.697624,164.548279 78.931129,168.209045 76.031364,171.937027 
	C72.916519,175.941513 70.786148,180.711746 68.167496,185.191269 
	C68.108803,185.234695 68.138222,185.153564 68.399155,184.938843 
	C72.165520,182.175293 75.670944,179.626465 79.513039,177.014832 
	C83.759605,174.341583 87.565964,171.555008 91.612328,169.176758 
	C94.818283,167.292480 98.318512,165.908890 101.811691,164.350647 
	C101.935326,164.398346 102.146370,164.235870 102.514915,164.217758 
	C107.308464,162.115204 111.818008,160.186264 116.108261,157.853973 
	C117.439621,157.130203 118.175911,155.311783 119.508438,153.934265 
	C120.879280,152.910873 121.926308,151.947769 122.980865,151.415497 
	C122.994156,153.570435 122.999924,155.294525 122.864632,157.333984 
	C121.143120,159.426300 119.562675,161.203293 117.633911,162.986832 
	C116.190697,163.326767 115.095802,163.660141 113.668129,164.043213 
	C111.884979,165.052414 110.434608,166.011932 108.622238,166.978973 
	C106.174507,167.986618 104.088768,168.986725 101.655609,170.022369 
	C99.529114,171.029160 97.750023,172.000412 95.624374,173.026672 
	C92.495628,174.901413 89.713432,176.721146 86.573273,178.646698 
	C83.771309,180.134628 81.327316,181.516724 78.578888,183.053818 
	C76.157257,184.797577 74.040077,186.386368 71.613602,188.021454 
	C70.844810,188.664200 70.385323,189.260605 69.607727,189.885468 
	C68.865318,189.948547 68.441017,189.983170 68.016724,190.017792 
z"/>
<path fill="#A8DAEF" opacity="1.000000" stroke="none" 
	d="
M49.905327,226.445160 
	C52.858471,219.337021 57.935444,214.625854 64.749603,211.232697 
	C71.648651,211.730194 77.952614,211.995132 84.122391,212.575989 
	C82.750137,213.625061 81.472939,214.300079 80.281410,215.102356 
	C76.823631,217.430496 73.345291,219.734955 69.988205,222.202652 
	C68.475182,223.314835 67.230820,224.792511 65.540466,226.165833 
	C61.298206,229.114243 57.407631,232.020035 57.074360,237.702728 
	C54.453926,235.537216 52.202030,233.276443 49.950134,231.015671 
	C49.947502,229.630020 49.944874,228.244370 49.905327,226.445160 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M143.508011,262.929504 
	C137.373093,259.599426 131.548874,256.286285 125.360550,253.023071 
	C121.474709,249.728912 117.952965,246.384842 114.212036,242.716705 
	C113.968513,241.945816 113.944168,241.499008 114.281197,241.071381 
	C116.189201,241.991852 117.763565,242.849045 119.277855,243.801712 
	C127.870728,249.207657 136.449234,254.636429 145.251495,260.315491 
	C147.639481,260.719818 149.808594,260.866089 151.984924,261.333191 
	C153.656265,262.446259 155.320404,263.238495 157.210403,264.300568 
	C160.146667,265.709717 162.812729,267.698975 165.575027,267.842987 
	C178.400055,268.511780 191.316040,269.584351 204.071350,268.688416 
	C212.601105,268.089203 220.958725,264.575073 229.310883,262.088257 
	C234.240082,260.620605 239.008347,258.612427 243.914169,256.913635 
	C243.979340,256.979889 244.109497,257.112610 243.894989,257.385193 
	C230.321899,265.469604 215.668121,269.337524 200.559052,271.819061 
	C190.330643,271.550598 180.095306,271.425476 169.877808,270.931427 
	C166.563828,270.771179 163.296555,269.644928 159.484985,268.569519 
	C154.640350,266.792908 150.318634,265.407349 145.996918,264.021790 
	C145.270844,263.663330 144.544785,263.304871 143.508011,262.929504 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M244.750397,53.051331 
	C245.661133,52.738956 246.571884,52.426586 248.279449,51.840916 
	C248.009933,55.889820 247.559555,59.744926 247.611359,63.593281 
	C247.627579,64.798431 248.738480,66.329865 249.792267,67.119247 
	C253.733231,70.071365 258.117218,72.474693 261.843781,75.658813 
	C265.840393,79.073669 269.264038,83.155624 272.980011,86.904556 
	C274.127319,88.062057 275.605286,88.932541 276.592407,90.198997 
	C278.245636,92.320030 279.633057,94.649086 281.120026,96.898697 
	C283.184784,100.022377 285.388275,103.067223 287.252197,106.306992 
	C288.946320,109.251656 289.004150,113.395142 293.494446,114.127846 
	C293.858154,114.187195 294.400269,114.865761 294.389374,115.243027 
	C294.279785,119.041992 294.071899,122.838127 293.563660,126.757057 
	C291.568634,123.088478 289.901611,119.297897 288.168884,115.177063 
	C281.904053,101.559769 272.883423,90.441002 261.798096,80.508987 
	C258.297699,77.781357 254.938507,75.345078 251.400879,72.602493 
	C249.501419,71.180023 247.780396,70.063843 246.006500,68.613464 
	C245.812561,67.850815 245.671509,67.422348 245.664749,66.553253 
	C245.449478,61.758858 245.099945,57.405098 244.750397,53.051331 
z"/>
<path fill="#DADEE5" opacity="1.000000" stroke="none" 
	d="
M232.989655,64.563385 
	C229.430283,61.861126 226.089432,58.447151 222.273315,55.691334 
	C218.225784,52.768394 213.827332,50.276882 209.387146,47.972713 
	C206.749725,46.604057 203.736053,45.960388 200.482498,44.983471 
	C199.651611,40.596760 201.382446,38.478920 206.019745,38.935520 
	C208.394394,39.169334 210.818359,38.902279 213.653473,39.252476 
	C217.742920,43.727783 221.212555,48.007568 225.166458,51.779762 
	C226.381348,52.938820 228.908432,52.793980 230.858978,53.080765 
	C231.859375,53.227844 232.913391,53.010181 233.943176,52.957352 
	C233.628479,56.685303 233.313797,60.413250 232.989655,64.563385 
z"/>
<path fill="#D1D5E2" opacity="1.000000" stroke="none" 
	d="
M233.960480,52.526245 
	C232.913391,53.010181 231.859375,53.227844 230.858978,53.080765 
	C228.908432,52.793980 226.381348,52.938820 225.166458,51.779762 
	C221.212555,48.007568 217.742920,43.727783 214.035110,39.301476 
	C220.713989,39.272602 227.444656,39.585609 234.591309,39.955170 
	C234.664139,44.039524 234.320953,48.067329 233.960480,52.526245 
z"/>
<path fill="#EAEBF1" opacity="1.000000" stroke="none" 
	d="
M201.008194,271.887817 
	C215.668121,269.337524 230.321899,265.469604 244.230408,257.308838 
	C246.795288,255.850937 248.810242,254.741943 250.825211,253.632950 
	C250.736069,254.279984 250.826965,255.029510 250.526962,255.556503 
	C249.159012,257.959534 247.681229,260.300049 246.738205,261.849365 
	C244.334595,262.410065 241.959747,262.633575 239.864120,263.518433 
	C234.738831,265.682495 229.919479,268.754120 224.637817,270.306335 
	C220.495087,271.523834 215.843872,271.000793 211.420105,271.275818 
	C208.097931,271.482330 204.778168,271.728210 201.008194,271.887817 
z"/>
<path fill="#EAEBF1" opacity="1.000000" stroke="none" 
	d="
M199.704941,53.267818 
	C203.053085,54.386234 206.359512,55.407139 209.113998,57.252079 
	C212.643692,59.616264 212.018127,63.011356 210.039642,66.634186 
	C209.188736,67.467773 208.376251,67.937080 207.563782,68.406387 
	C209.717941,71.006607 207.357559,71.764824 205.765427,71.649574 
	C204.778381,71.578117 203.708618,69.751122 203.059616,68.511322 
	C201.709930,65.932968 200.615311,63.221085 199.206802,60.281296 
	C199.137802,57.844505 199.277878,55.690495 199.704941,53.267818 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M57.442902,237.607452 
	C57.407631,232.020035 61.298206,229.114243 65.577927,226.557312 
	C65.355904,230.441757 67.655350,232.013428 70.445442,232.803925 
	C73.873520,233.775177 77.425583,234.308823 80.678268,235.317032 
	C78.639618,235.747971 76.846756,235.893509 74.651550,236.019714 
	C71.502548,235.329391 68.755867,234.658447 65.866920,233.671082 
	C64.489563,232.779648 63.254482,232.204636 62.019398,231.629623 
	C61.762833,233.333740 60.781879,235.412796 61.423332,236.652100 
	C62.333717,238.410980 64.438553,239.551620 66.430359,240.968994 
	C68.893547,241.324097 70.959351,241.666656 73.280113,242.299683 
	C75.996162,243.239365 78.457245,243.888580 80.918335,244.537796 
	C73.888367,243.053802 66.858398,241.569809 59.350189,239.749908 
	C58.395599,238.811813 57.919250,238.209641 57.442902,237.607452 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M75.053886,236.039062 
	C76.846756,235.893509 78.639618,235.747971 81.150162,235.255539 
	C86.909401,234.867096 91.950966,234.825546 96.821068,235.087677 
	C95.765282,235.612473 94.880966,235.833588 93.585739,236.047272 
	C91.247765,236.360519 89.213158,236.396805 87.421097,237.074493 
	C85.224785,237.905045 83.231438,239.272354 81.150345,240.407593 
	C81.389015,240.842880 81.627693,241.278152 81.866364,241.713440 
	C85.910103,242.133820 89.953850,242.554214 94.418098,242.981155 
	C96.893753,243.350677 98.948898,243.713654 101.004044,244.076630 
	C94.609016,244.339691 88.213982,244.602753 81.368645,244.701813 
	C78.457245,243.888580 75.996162,243.239365 73.418404,241.975891 
	C73.885788,239.587448 74.469833,237.813263 75.053886,236.039062 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M251.020599,253.366394 
	C248.810242,254.741943 246.795288,255.850937 244.444916,257.036255 
	C244.109497,257.112610 243.979340,256.979889 243.963074,256.572113 
	C243.789200,254.948883 243.631592,253.733429 243.507355,252.775406 
	C245.242783,252.590286 246.815170,252.808289 247.981110,252.220352 
	C250.660049,250.869431 253.200150,249.210495 255.664291,247.487396 
	C256.351654,247.006790 256.577301,245.865906 257.014771,245.027939 
	C259.618683,243.076904 262.263733,241.177887 264.814880,239.160156 
	C267.336578,237.165695 269.763397,235.051285 272.599762,232.984543 
	C265.717102,239.686340 258.466553,246.393097 251.020599,253.366394 
z"/>
<path fill="#406493" opacity="1.000000" stroke="none" 
	d="
M244.712158,52.954704 
	C245.099945,57.405098 245.449478,61.758858 245.322296,66.421921 
	C244.238342,63.353310 243.631134,59.975388 242.945938,56.163639 
	C238.599915,58.421036 239.107208,61.856289 238.674942,65.018280 
	C238.013214,65.002441 237.635132,64.991898 237.257050,64.981354 
	C237.543259,59.931431 237.661850,54.862221 238.239090,49.845779 
	C238.375412,48.660965 240.054535,47.653675 241.364975,46.512337 
	C241.691498,46.796829 241.676971,47.133713 241.586975,47.712440 
	C241.684845,48.136528 241.858231,48.318764 242.031601,48.500999 
	C242.191345,48.592403 242.351105,48.683811 242.777222,49.193218 
	C243.332001,50.109276 243.620453,50.607330 243.908890,51.105389 
	C243.908890,51.105389 243.972580,51.034935 243.947021,51.362953 
	C244.189972,52.013020 244.458466,52.335068 244.726974,52.657120 
	C244.726974,52.657116 244.673920,52.858074 244.712158,52.954704 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M113.919815,241.052185 
	C113.944168,241.499008 113.968513,241.945816 113.919785,242.538605 
	C110.084435,243.254440 106.322166,243.824326 102.521088,244.084427 
	C103.017113,243.434464 103.551941,243.094284 103.777725,242.950684 
	C102.520576,241.885757 100.404900,240.923950 100.286537,239.759201 
	C100.168755,238.600082 102.042191,237.225174 103.070961,235.968201 
	C103.669044,235.237442 104.323059,234.552460 104.953743,233.601288 
	C104.955574,233.355698 105.116585,232.891312 105.338829,232.928406 
	C105.561073,232.965485 106.005157,233.042053 106.130035,233.346100 
	C108.500183,235.771729 110.745445,237.893326 112.995987,240.007828 
	C113.001259,240.000732 112.985352,240.004898 113.070572,240.239334 
	C113.351685,240.765305 113.621315,240.948013 113.954262,241.027481 
	C113.943840,241.033051 113.919815,241.052185 113.919815,241.052185 
z"/>
<path fill="#406493" opacity="1.000000" stroke="none" 
	d="
M288.234589,115.507317 
	C289.901611,119.297897 291.568634,123.088478 293.472290,127.161041 
	C293.708984,127.443024 293.651459,127.763466 293.772827,128.283417 
	C295.203003,133.864975 296.511871,138.926559 297.820709,143.988159 
	C297.871307,144.750854 297.921906,145.513550 298.014465,147.022797 
	C298.317535,148.986649 298.578613,150.203934 298.839661,151.421219 
	C298.733582,151.748108 298.627472,152.074997 298.326904,152.892105 
	C298.044952,153.929123 297.957489,154.475952 297.870056,155.022766 
	C297.459991,154.994339 297.049896,154.965927 296.356232,154.638992 
	C296.051270,153.222183 296.029907,152.103897 296.010559,150.573380 
	C294.338776,143.077713 292.664917,135.994263 290.989075,128.525909 
	C290.069550,123.929787 289.152069,119.718552 288.234589,115.507317 
z"/>
<path fill="#B2BACE" opacity="1.000000" stroke="none" 
	d="
M70.999207,201.968491 
	C68.593925,203.367386 66.188652,204.766281 63.401711,205.809204 
	C62.282120,203.239044 61.544197,201.024887 60.806274,198.810715 
	C62.944656,196.196350 65.083038,193.582001 67.588364,191.320587 
	C68.969940,195.105194 69.984573,198.536850 70.999207,201.968491 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M297.921509,155.441956 
	C297.957489,154.475952 298.044952,153.929123 298.279907,153.147797 
	C299.214783,153.385803 300.693939,153.868073 300.687439,154.329453 
	C300.557190,163.595840 300.340546,172.864120 299.867828,182.118073 
	C299.816406,183.124527 298.087402,184.045288 296.769592,185.017944 
	C297.251007,178.033966 298.274628,171.052414 298.858490,164.034271 
	C299.081543,161.353012 298.297852,158.587982 297.921509,155.441956 
z"/>
<path fill="#278DC9" opacity="1.000000" stroke="none" 
	d="
M104.951912,233.846893 
	C104.323059,234.552460 103.669044,235.237442 103.070961,235.968201 
	C102.042191,237.225174 100.168755,238.600082 100.286537,239.759201 
	C100.404900,240.923950 102.520576,241.885757 103.777725,242.950684 
	C103.551941,243.094284 103.017113,243.434464 102.198601,243.919983 
	C101.914925,244.065323 101.461578,244.226395 101.232811,244.151520 
	C98.948898,243.713654 96.893753,243.350677 94.419540,242.548370 
	C93.999191,240.090927 93.997917,238.072800 93.996643,236.054688 
	C94.880966,235.833588 95.765282,235.612473 97.121872,234.838623 
	C100.046730,234.139542 102.499321,233.993225 104.951912,233.846893 
z"/>
<path fill="#CFCFD1" opacity="1.000000" stroke="none" 
	d="
M60.465843,198.924973 
	C61.544197,201.024887 62.282120,203.239044 63.043827,205.881592 
	C60.940887,208.185684 58.814163,210.061417 56.338341,211.971542 
	C57.367958,207.683701 58.746685,203.361465 60.465843,198.924973 
z"/>
<path fill="#F2F9FB" opacity="1.000000" stroke="none" 
	d="
M79.848244,158.986099 
	C79.135071,160.616989 78.421906,162.247864 77.358978,163.940796 
	C77.770531,157.715485 74.768425,150.983322 79.614929,145.063416 
	C80.095100,146.164490 80.199692,147.336563 80.622894,148.784882 
	C80.941505,149.061111 81.123581,148.966797 80.988594,149.354309 
	C80.518486,152.823242 80.183365,155.904663 79.848244,158.986099 
z"/>
<path fill="#A6AFC5" opacity="1.000000" stroke="none" 
	d="
M71.330971,201.928467 
	C69.984573,198.536850 68.969940,195.105194 67.924484,191.242126 
	C67.893654,190.810699 67.957703,190.414597 67.987213,190.216187 
	C68.441017,189.983170 68.865318,189.948547 69.638664,190.272202 
	C71.995964,193.361053 74.004219,196.091629 76.012466,198.822189 
	C74.562561,199.844269 73.112648,200.866348 71.330971,201.928467 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M242.776627,28.934715 
	C243.856567,28.867319 245.042908,28.779764 245.488388,29.312143 
	C247.234222,31.398560 248.746277,33.680611 250.742218,36.438618 
	C247.973312,36.833984 245.271683,37.219746 242.542648,37.609421 
	C242.542648,34.570675 242.542648,31.885025 242.776627,28.934715 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M273.290955,232.863037 
	C273.874817,230.848557 274.453003,228.508698 275.738159,226.660446 
	C277.808746,223.682587 280.345612,221.028931 283.048615,218.343231 
	C280.142029,223.218201 276.872345,227.988281 273.290955,232.863037 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M298.200317,143.934937 
	C296.511871,138.926559 295.203003,133.864975 293.877869,128.407776 
	C298.379395,128.393127 297.092438,132.529907 297.916901,134.951019 
	C298.848236,137.686035 298.418030,140.884689 298.200317,143.934937 
z"/>
<path fill="#EAEBF1" opacity="1.000000" stroke="none" 
	d="
M146.043274,264.376831 
	C150.318634,265.407349 154.640350,266.792908 159.079636,268.531189 
	C153.960861,270.594421 149.157242,269.072784 146.043274,264.376831 
z"/>
<path fill="#EBFAFD" opacity="1.000000" stroke="none" 
	d="
M57.074364,237.702728 
	C57.919250,238.209641 58.395599,238.811813 59.004341,239.671265 
	C53.876850,241.738708 49.167469,237.644043 49.688084,231.250519 
	C52.202030,233.276443 54.453926,235.537216 57.074364,237.702728 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M283.671875,218.053406 
	C282.786133,213.846832 284.567902,210.586563 288.699921,208.392731 
	C289.077759,208.657196 289.087555,208.825287 289.097321,208.993393 
	C287.367004,211.931931 285.636688,214.870483 283.671875,218.053406 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M294.957275,193.934662 
	C294.644470,193.096207 294.585999,192.164276 294.898193,191.164139 
	C295.249756,192.011032 295.230713,192.926102 294.957275,193.934662 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M298.896301,151.028290 
	C298.578613,150.203934 298.317535,148.986649 298.112183,147.394043 
	C298.429596,148.224274 298.691254,149.429825 298.896301,151.028290 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M243.934204,50.794548 
	C243.620453,50.607330 243.332001,50.109276 243.027130,49.310551 
	C243.326950,49.501156 243.643234,49.992428 243.934204,50.794548 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M293.292633,198.792358 
	C293.115112,198.585724 293.185577,198.178802 293.499390,197.566437 
	C293.675354,197.771362 293.607971,198.181732 293.292633,198.792358 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M289.329163,208.788666 
	C289.087555,208.825287 289.077759,208.657196 289.014282,208.242371 
	C289.075073,207.873032 289.189575,207.750412 289.525879,207.508331 
	C289.685425,207.787262 289.623199,208.185608 289.329163,208.788666 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M242.030807,48.348858 
	C241.858231,48.318764 241.684845,48.136528 241.720703,47.931820 
	C241.929932,47.909351 242.030014,48.196716 242.030807,48.348858 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M291.050720,204.830933 
	C290.869476,204.582123 290.997406,204.144119 291.400513,203.490295 
	C291.570404,203.730240 291.465149,204.186005 291.050720,204.830933 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M290.200745,206.803070 
	C290.018951,206.583282 290.107666,206.161972 290.443665,205.523315 
	C290.617706,205.737839 290.544434,206.169708 290.200745,206.803070 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M242.000549,42.000702 
	C242.093613,41.965145 242.186127,41.928883 242.138641,41.945763 
	C241.998672,41.998905 242.000000,42.000000 242.000549,42.000702 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M292.263977,201.796570 
	C292.085236,201.584869 292.161957,201.174500 292.488708,200.558197 
	C292.665619,200.767502 292.592499,201.182709 292.263977,201.796570 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M294.024292,196.950302 
	C293.831543,196.807098 293.831543,196.623047 294.010956,196.229279 
	C294.298157,196.233078 294.426361,196.440186 294.497101,196.665344 
	C294.511993,196.712692 294.315887,196.826355 294.024292,196.950302 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M244.736664,52.323219 
	C244.458466,52.335068 244.189972,52.013020 243.899994,51.369545 
	C244.167801,51.361855 244.457077,51.675587 244.736664,52.323219 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M295.522369,188.843216 
	C295.397125,188.671982 295.431488,188.373917 295.722351,188.022614 
	C295.964478,188.252029 295.865540,188.501022 295.522369,188.843216 
z"/>
<path fill="#E8F5FA" opacity="1.000000" stroke="none" 
	d="
M296.143799,187.948624 
	C296.019897,187.809814 296.073181,187.641663 296.290466,187.265945 
	C296.504700,187.274155 296.580261,187.488800 296.589996,187.706390 
	C296.592987,187.773224 296.415741,187.848099 296.143799,187.948624 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M239.628601,298.990204 
	C243.776703,300.333496 247.478104,301.985962 251.351471,302.941010 
	C259.002167,304.827454 266.801941,306.120239 274.426483,308.096619 
	C277.229492,308.823181 280.069031,310.391327 282.215576,312.325989 
	C283.674500,313.640900 284.546906,316.273529 284.504486,318.292145 
	C284.484711,319.232666 281.770203,320.402161 280.107269,320.972443 
	C273.389954,323.276093 266.705536,325.799622 259.819550,327.452820 
	C252.897232,329.114716 245.783600,330.478851 238.694748,330.852173 
	C219.152359,331.881195 199.579285,332.329651 180.017334,332.977692 
	C179.212646,333.004333 178.397903,332.727356 177.293777,331.920349 
	C177.000214,330.500061 177.000717,329.751251 177.445282,329.001770 
	C180.259659,329.001129 182.630005,329.001160 185.267151,329.284912 
	C188.022522,329.379517 190.511093,329.190369 193.436188,329.000732 
	C195.914642,329.000916 197.956589,329.001678 200.088959,329.396423 
	C205.581406,332.090973 210.843964,331.603546 216.427643,328.997742 
	C223.569565,328.329468 230.284485,327.662537 237.260895,327.280762 
	C238.819260,327.623840 240.197006,327.997345 241.399979,327.688995 
	C249.669357,325.569305 257.945160,323.450409 266.119781,321.000549 
	C268.476227,320.294342 270.507599,318.503418 272.687988,317.209778 
	C270.730408,315.114349 268.805237,312.986877 266.789490,310.949005 
	C266.356506,310.511261 265.616852,310.322937 264.983185,310.145844 
	C256.921783,307.893219 248.868591,305.606659 240.763306,303.523224 
	C240.017441,303.331482 238.928879,304.473022 237.614136,304.996155 
	C234.150467,304.670502 231.069504,302.803009 227.746780,305.007141 
	C227.498062,305.001740 227.000565,304.994049 226.833496,304.748474 
	C226.110748,304.333191 225.555099,304.163483 224.795349,303.716583 
	C222.433762,302.692841 220.503860,298.550140 217.548401,302.998169 
	C214.399033,302.998566 211.699509,302.998383 208.706604,302.702515 
	C203.275467,302.604004 198.137741,302.801147 192.555832,302.998718 
	C189.741104,302.998840 187.370560,302.998505 184.706635,302.702515 
	C177.607559,302.605774 170.801865,302.804718 163.622375,303.003601 
	C162.501083,303.004395 161.753555,303.005249 160.588745,303.004181 
	C158.780365,303.000702 157.389267,302.999146 155.998169,302.997559 
	C158.206207,301.998718 160.383896,300.225952 162.627670,300.137878 
	C171.329239,299.796478 180.054169,300.113007 188.765823,299.945770 
	C195.202774,299.822205 201.632614,299.328247 208.533722,298.998413 
	C209.445862,298.997772 209.889893,298.998016 210.935837,299.283813 
	C216.358902,298.712524 221.180069,297.855652 226.001221,296.998779 
	C228.049301,298.510529 229.951172,299.963226 232.333740,297.253357 
	C235.557770,293.586456 236.696457,299.356445 239.628601,298.990204 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M140.000305,281.000000 
	C136.150024,282.708771 132.914108,281.290833 131.262985,277.991821 
	C130.415695,276.298889 130.965012,273.100800 132.114563,271.430267 
	C133.388382,269.579132 138.953125,271.762604 139.865005,274.532959 
	C140.435608,276.266479 139.996368,278.332397 140.004822,280.624573 
	C139.999390,281.000000 140.000000,281.000000 140.000305,281.000000 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M225.936340,296.642456 
	C221.180069,297.855652 216.358902,298.712524 211.268875,299.283478 
	C215.147507,297.692505 219.273819,296.309998 223.464661,295.163361 
	C224.115784,294.985199 225.061813,295.884888 225.936340,296.642456 
z"/>
<path fill="#F3F3F3" opacity="1.000000" stroke="none" 
	d="
M169.003296,331.996338 
	C151.513321,331.065857 133.991623,330.440521 116.928543,325.844604 
	C112.808083,324.734802 108.320122,324.514008 104.551811,322.724701 
	C101.609665,321.327667 97.824989,318.372681 97.447151,315.683868 
	C96.871765,311.589233 100.665283,309.503967 105.039932,308.889130 
	C108.906357,308.345703 112.598434,306.658508 116.448013,305.868927 
	C119.745850,305.192505 123.192635,305.263977 126.510948,304.660461 
	C131.341904,303.781891 136.072968,302.150970 140.928604,301.672180 
	C145.166962,301.254242 149.518677,301.985718 153.521484,302.649780 
	C148.826141,303.726807 144.379440,304.127716 140.043823,305.050537 
	C131.836563,306.797424 123.567963,308.440063 115.569962,310.917358 
	C110.773125,312.403137 110.816132,316.343597 114.728554,319.468872 
	C123.709404,326.642792 134.414551,326.508209 145.405334,326.994873 
	C146.869446,326.996277 147.934418,326.996765 149.144440,327.223511 
	C149.859650,327.300629 150.429825,327.151520 151.332748,327.002106 
	C152.109116,327.003235 152.552719,327.004669 153.088364,327.401245 
	C156.916412,328.198090 160.703644,328.352844 164.365479,329.112305 
	C166.038818,329.459351 167.465012,330.998077 169.003296,331.996338 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M145.006180,326.993896 
	C134.414551,326.508209 123.709404,326.642792 114.728554,319.468872 
	C110.816132,316.343597 110.773125,312.403137 115.569962,310.917358 
	C123.567963,308.440063 131.836563,306.797424 140.043823,305.050537 
	C144.379440,304.127716 148.826141,303.726807 153.611938,303.044495 
	C154.444992,302.998749 154.888779,302.998718 155.665359,302.998138 
	C157.389267,302.999146 158.780365,303.000702 160.504974,303.383545 
	C159.345932,304.177429 157.803879,304.466888 156.376083,305.040649 
	C155.181183,305.520813 154.119858,306.333374 152.625122,307.000183 
	C151.502426,307.003113 150.753998,307.004608 149.543884,307.003601 
	C138.687103,306.672058 128.987610,308.424194 120.251259,316.009552 
	C122.345818,317.596771 123.490372,318.951965 124.935623,319.472717 
	C130.454620,321.461517 136.086365,323.135101 141.637512,325.038666 
	C142.842224,325.451782 143.887482,326.329926 145.006180,326.993896 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M169.447128,331.997101 
	C167.465012,330.998077 166.038818,329.459351 164.365479,329.112305 
	C160.703644,328.352844 156.916412,328.198090 153.517792,327.398956 
	C155.572662,326.997772 157.290146,326.993988 159.456818,326.991852 
	C162.604019,326.993683 165.302002,326.993835 168.144684,327.221008 
	C168.859573,327.297455 169.429794,327.146881 170.416000,326.998047 
	C172.217758,327.004639 173.603531,327.009552 175.034256,327.333740 
	C175.719879,328.102814 176.360550,328.552643 177.001221,329.002441 
	C177.000717,329.751251 177.000214,330.500061 176.999847,331.623230 
	C174.630325,331.997650 172.260635,331.997772 169.447128,331.997101 
z"/>
<path fill="#F6F8FA" opacity="1.000000" stroke="none" 
	d="
M193.995117,85.987778 
	C194.801636,82.493614 195.608170,78.999451 196.691254,75.241165 
	C201.214340,77.369881 205.460876,79.762703 210.396072,82.543579 
	C209.155899,83.158691 208.207199,83.461769 207.471817,84.037384 
	C206.600159,84.719666 205.912292,85.636749 205.144775,86.452065 
	C206.206802,86.871483 207.762985,87.963203 208.252380,87.603508 
	C212.029266,84.827553 214.386276,87.743706 216.998550,89.525543 
	C224.918472,94.927704 227.298767,102.952827 226.916550,111.921173 
	C226.654556,118.068520 226.023773,124.271004 224.739822,130.273880 
	C224.103714,133.247925 221.670502,135.857605 219.964508,138.578323 
	C217.124741,143.107178 214.776123,148.123108 211.166641,151.936127 
	C208.029053,155.250641 203.602600,157.520187 199.430771,159.628281 
	C195.709885,161.508514 195.273773,163.905823 196.846558,168.109985 
	C197.010773,172.957458 197.020630,176.988144 197.024872,181.350739 
	C197.018127,182.125641 197.017014,182.568619 197.015900,183.011597 
	C195.550018,182.705414 194.084137,182.399231 191.951019,181.890625 
	C191.537048,180.117920 191.790314,178.547638 192.148834,176.527527 
	C192.254089,172.508560 192.254089,168.939407 192.254089,165.217392 
	C190.633972,165.217392 189.531860,165.217392 188.429749,165.217407 
	C185.231659,165.211777 181.951019,164.748444 178.854675,165.306931 
	C174.112839,166.162216 169.498032,167.721649 164.475647,168.630737 
	C165.660812,167.515869 167.224991,166.798843 168.731186,165.975769 
	C170.170364,165.189301 171.549225,164.292465 173.336914,163.213394 
	C170.128922,163.213394 167.659973,163.927811 165.992035,163.075211 
	C161.548340,160.803741 157.391754,157.942276 153.254608,155.119583 
	C152.241791,154.428558 150.971512,152.847763 151.140350,151.915115 
	C151.808014,148.226593 153.000366,144.633041 154.362030,141.071625 
	C156.373108,144.352158 156.991348,148.404663 161.512100,149.237595 
	C166.338165,150.126755 171.162933,151.875900 175.970520,151.816330 
	C183.551620,151.722412 191.520416,151.829498 197.514236,145.613052 
	C197.989807,145.119827 199.046021,145.123428 199.848373,145.018387 
	C205.451233,144.284851 209.080658,140.481079 210.046158,134.703156 
	C210.353363,132.864700 211.516785,131.186493 212.043564,129.364197 
	C213.396103,124.685402 215.298660,120.021461 215.677368,115.248131 
	C216.017899,110.955986 214.404602,106.543442 214.065155,102.152153 
	C213.775803,98.408905 212.330063,96.354492 208.431702,95.924713 
	C206.777603,95.742348 204.437958,95.102554 203.775864,93.911392 
	C202.117706,90.928223 199.729279,90.847450 197.000549,90.617516 
	C195.998291,88.819687 194.996704,87.403740 193.995117,85.987778 
z"/>
<path fill="#EAEBF1" opacity="1.000000" stroke="none" 
	d="
M197.030487,181.018814 
	C197.020630,176.988144 197.010773,172.957458 197.000763,168.463394 
	C200.046875,169.284409 202.590729,168.572647 205.189255,166.545227 
	C209.883240,162.882965 214.673370,159.307159 219.673004,156.082291 
	C226.480118,151.691544 228.597549,144.370483 231.504059,137.652710 
	C233.839890,132.253922 235.199127,126.432610 237.006866,120.404213 
	C239.134644,120.082069 241.247269,120.154495 243.839691,120.482285 
	C243.900009,124.462135 243.523621,128.192108 243.050491,131.909775 
	C242.408630,136.953125 241.692703,141.987061 241.008545,147.025024 
	C240.103134,147.803574 239.233994,149.204117 238.286438,149.259354 
	C232.324600,149.606873 231.789734,153.553375 232.000076,158.137238 
	C232.030212,158.793854 232.005157,159.452988 231.695068,160.706879 
	C227.320465,163.977646 222.451904,165.948181 219.362961,169.476654 
	C213.276154,176.429489 205.155075,178.519211 197.030487,181.018814 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M269.429596,108.160561 
	C268.356537,108.374428 267.283478,108.588295 266.045715,108.203186 
	C261.871735,107.604202 257.862457,107.604202 253.903152,107.604202 
	C253.466751,98.512863 253.026428,89.959778 252.685898,81.402718 
	C252.646637,80.416199 252.895752,78.824409 253.487625,78.553284 
	C254.269653,78.195045 255.748367,78.563133 256.527588,79.150093 
	C258.456207,80.602913 260.157257,82.357834 262.256500,84.146149 
	C265.186951,87.182137 267.927551,89.968201 270.383209,92.986130 
	C271.546997,94.416367 272.166199,96.289696 273.018677,98.420349 
	C273.001068,100.676369 272.867828,102.485550 273.022827,104.269691 
	C273.258820,106.985733 272.133362,108.253761 269.429596,108.160561 
z"/>
<path fill="#E0E3EB" opacity="1.000000" stroke="none" 
	d="
M197.024872,181.350739 
	C205.155075,178.519211 213.276154,176.429489 219.362961,169.476654 
	C222.451904,165.948181 227.320465,163.977646 231.695190,161.150726 
	C232.332260,165.029190 232.658600,169.059723 232.627441,173.582031 
	C230.136414,173.904907 228.002899,173.736008 225.783127,173.560303 
	C225.460342,174.493500 225.410919,176.293579 224.558014,176.840393 
	C217.666046,181.258972 210.694977,185.569489 203.566833,189.591827 
	C202.101852,190.418518 199.877686,189.899811 198.003448,190.001221 
	C197.998886,189.558029 197.994339,189.114853 198.242325,188.155365 
	C198.329300,186.759583 198.163727,185.880096 197.998169,185.000595 
	C197.684357,184.534225 197.370529,184.067871 197.036301,183.306549 
	C197.017014,182.568619 197.018127,182.125641 197.024872,181.350739 
z"/>
<path fill="#DADEE5" opacity="1.000000" stroke="none" 
	d="
M198.003754,190.448242 
	C199.877686,189.899811 202.101852,190.418518 203.566833,189.591827 
	C210.694977,185.569489 217.666046,181.258972 224.558014,176.840393 
	C225.410919,176.293579 225.460342,174.493500 225.783127,173.560303 
	C228.002899,173.736008 230.136414,173.904907 232.618088,174.043793 
	C232.974792,176.389114 232.983353,178.764404 232.625259,181.610657 
	C225.433167,186.117722 220.368698,192.949371 211.753571,194.224564 
	C207.113983,194.911301 202.644043,196.744095 198.097549,198.059784 
	C198.066376,195.671616 198.035217,193.283432 198.003754,190.448242 
z"/>
<path fill="#CACFDC" opacity="1.000000" stroke="none" 
	d="
M198.086639,198.456482 
	C202.644043,196.744095 207.113983,194.911301 211.753571,194.224564 
	C220.368698,192.949371 225.433167,186.117722 232.610336,182.056290 
	C232.978394,185.384201 232.994720,188.737442 232.627213,192.553986 
	C227.404907,195.525909 222.572388,198.046066 217.726440,200.540115 
	C211.864410,203.557129 206.155380,207.111038 199.084198,206.025681 
	C198.748047,203.634842 198.411880,201.244003 198.086639,198.456482 
z"/>
<path fill="#DADEE5" opacity="1.000000" stroke="none" 
	d="
M253.008850,117.011108 
	C256.437561,116.066002 259.866272,115.120911 264.486450,113.847397 
	C262.950165,119.624199 261.665833,124.417885 260.402557,129.217087 
	C259.650940,132.072510 258.822906,134.913727 258.219574,137.801117 
	C256.453125,146.254623 256.234619,146.489960 247.147430,146.761902 
	C247.209808,143.628601 247.859283,140.706970 248.750534,137.861099 
	C249.715347,134.780350 251.287552,131.844879 251.900055,128.710281 
	C252.647629,124.884422 252.671814,120.917198 253.008850,117.011108 
z"/>
<path fill="#959FB9" opacity="1.000000" stroke="none" 
	d="
M208.000641,242.122940 
	C201.973648,242.364212 202.457779,237.704056 202.118210,233.264069 
	C203.627121,231.756241 204.704788,230.520767 206.046371,230.069031 
	C211.900269,228.097900 217.855042,226.427185 223.716873,224.478043 
	C225.216934,223.979279 226.557098,222.086990 227.891739,222.166168 
	C232.866989,222.461304 233.302795,219.551071 233.052597,215.850601 
	C233.672607,220.259674 234.292618,224.668762 234.508331,229.527740 
	C232.428253,230.098038 230.611725,229.841049 229.101105,230.404373 
	C222.692505,232.794220 216.507217,235.883438 209.969086,237.783859 
	C206.588272,238.766556 206.908447,239.878281 208.000641,242.122940 
z"/>
<path fill="#226AB1" opacity="1.000000" stroke="none" 
	d="
M269.551941,108.440460 
	C272.133362,108.253761 273.258820,106.985733 273.022827,104.269691 
	C272.867828,102.485550 273.001068,100.676369 273.392395,98.437637 
	C275.193634,99.962364 276.209900,102.536469 278.100830,103.772507 
	C282.693054,106.774300 283.005188,111.280106 283.440613,115.891289 
	C283.664612,118.263054 283.811920,120.642052 283.608215,122.966690 
	C281.530884,123.083618 279.839142,123.251633 278.147430,123.419647 
	C279.253571,124.865601 280.278351,126.384529 281.490356,127.735504 
	C282.551025,128.917801 283.822083,129.911316 284.740997,131.098297 
	C283.985809,131.472305 283.489868,131.736252 282.631409,131.937820 
	C280.165039,131.259628 278.061157,130.643799 275.645874,130.033295 
	C274.920349,130.050781 274.506256,130.062943 274.186462,129.721924 
	C274.464630,128.094315 274.648468,126.819878 274.821655,125.619362 
	C271.763214,125.787270 269.393890,125.917343 267.046204,125.681252 
	C268.013550,123.597641 269.780365,121.858932 269.736511,120.167122 
	C269.641113,116.482468 275.828613,112.931961 270.073029,109.183182 
	C269.940125,109.028908 269.807190,108.874626 269.551941,108.440460 
z"/>
<path fill="#C0C5D6" opacity="1.000000" stroke="none" 
	d="
M199.064545,206.441406 
	C206.155380,207.111038 211.864410,203.557129 217.726440,200.540115 
	C222.572388,198.046066 227.404907,195.525909 232.613403,193.012070 
	C233.013565,195.367783 233.043686,197.728745 232.714752,200.589340 
	C230.739166,202.391174 229.292145,204.033630 227.477295,204.937714 
	C218.757629,209.281525 209.862106,213.191513 199.962097,213.987335 
	C199.656357,211.610611 199.350616,209.233871 199.064545,206.441406 
z"/>
<path fill="#B2BACE" opacity="1.000000" stroke="none" 
	d="
M199.954590,214.428802 
	C209.862106,213.191513 218.757629,209.281525 227.477295,204.937714 
	C229.292145,204.033630 230.739166,202.391174 232.705734,201.031647 
	C233.248672,204.157745 233.441574,207.341202 233.130646,210.523163 
	C231.718002,210.578125 230.611176,210.317245 229.931717,210.741272 
	C224.542648,214.104218 219.169876,217.338058 212.677109,218.290497 
	C208.474258,218.907043 204.355713,220.098297 200.199371,221.031982 
	C200.115265,218.978073 200.031174,216.924164 199.954590,214.428802 
z"/>
<path fill="#EAEBF1" opacity="1.000000" stroke="none" 
	d="
M214.142075,71.328568 
	C214.104355,70.301147 214.558655,69.353951 214.263199,68.933174 
	C211.667328,65.236198 213.969467,64.088234 217.041428,62.996284 
	C217.452637,69.636482 218.053528,69.990021 224.258591,67.512985 
	C224.167755,69.097214 224.076340,70.681427 223.984924,72.265633 
	C225.730438,72.314484 227.476868,72.419472 229.221054,72.390266 
	C230.204437,72.373802 231.184143,72.136269 232.574448,71.992126 
	C232.986420,77.339775 232.989441,82.693611 232.989532,88.512512 
	C230.435852,90.054016 228.945816,89.415062 227.755630,86.692307 
	C226.237411,83.219131 224.161316,80.103539 219.584183,79.771866 
	C218.618851,79.701912 217.729767,77.598038 216.939453,76.346741 
	C215.980881,74.829002 215.164413,73.221535 214.142075,71.328568 
z"/>
<path fill="#E3E5EC" opacity="1.000000" stroke="none" 
	d="
M252.612900,117.008202 
	C252.671814,120.917198 252.647629,124.884422 251.900055,128.710281 
	C251.287552,131.844879 249.715347,134.780350 248.750534,137.861099 
	C247.859283,140.706970 247.209808,143.628601 246.730911,146.764923 
	C245.250351,147.039764 243.492706,147.066559 241.371811,147.059204 
	C241.692703,141.987061 242.408630,136.953125 243.050491,131.909775 
	C243.523621,128.192108 243.900009,124.462135 244.156647,120.364594 
	C246.734863,118.996124 249.475906,118.000710 252.612900,117.008202 
z"/>
<path fill="#278DC9" opacity="1.000000" stroke="none" 
	d="
M192.817856,185.104706 
	C192.894424,188.754684 192.970993,192.404648 192.796478,196.530289 
	C192.370590,197.014587 192.195786,197.023224 191.597885,197.031479 
	C188.125717,197.355713 185.020248,197.423340 182.043289,198.076645 
	C179.018066,198.740570 176.119583,199.982025 173.118942,200.558243 
	C172.999146,198.764847 172.925995,197.383774 172.885162,195.667709 
	C172.886871,194.885742 172.856277,194.438766 172.883850,193.606735 
	C174.514633,191.886002 175.883331,190.027237 177.700470,189.318878 
	C182.058014,187.620255 186.626251,186.462082 191.394379,185.092438 
	C192.058838,185.100952 192.438339,185.102829 192.817856,185.104706 
z"/>
<path fill="#204082" opacity="1.000000" stroke="none" 
	d="
M172.825668,193.991791 
	C172.856277,194.438766 172.886871,194.885742 172.537750,195.710785 
	C170.782410,196.391815 169.406815,196.694763 168.021057,196.599152 
	C168.001785,195.137970 167.992676,194.075348 167.987152,192.594696 
	C167.661606,189.129776 167.332504,186.082886 167.411560,183.033340 
	C170.878723,182.348526 173.937698,181.666382 177.392273,180.979980 
	C181.859711,179.653595 185.931564,178.331497 190.343475,177.005676 
	C191.136871,176.993759 191.590225,176.985565 192.043579,176.977356 
	C191.790314,178.547638 191.537048,180.117920 191.574310,181.870575 
	C192.194778,182.844238 192.524704,183.635513 192.836243,184.765747 
	C192.438339,185.102829 192.058838,185.100952 191.035477,184.923767 
	C184.342239,185.995712 178.174072,186.865891 172.313324,188.712036 
	C170.471451,189.292252 169.759918,192.461838 172.825668,193.991791 
z"/>
<path fill="#9EA7BF" opacity="1.000000" stroke="none" 
	d="
M233.040817,215.638702 
	C233.302795,219.551071 232.866989,222.461304 227.891739,222.166168 
	C226.557098,222.086990 225.216934,223.979279 223.716873,224.478043 
	C217.855042,226.427185 211.900269,228.097900 206.046371,230.069031 
	C204.704788,230.520767 203.627121,231.756241 202.111633,232.856842 
	C201.503464,230.665726 201.211639,228.248779 201.269989,225.545959 
	C204.482483,224.866165 207.516220,224.947403 210.174667,223.988373 
	C217.849442,221.219681 225.373016,218.031876 232.955963,215.008652 
	C232.955963,215.008652 233.029037,215.426788 233.040817,215.638702 
z"/>
<path fill="#A8DAEF" opacity="1.000000" stroke="none" 
	d="
M250.177444,229.956467 
	C250.129730,230.411942 250.082031,230.867432 249.888657,231.824707 
	C249.742996,232.326492 249.676086,232.729111 249.676086,232.729111 
	C249.676086,232.729111 249.677124,232.939163 249.403442,232.986664 
	C246.161240,236.770309 243.192719,240.506439 240.224213,244.242584 
	C240.169754,243.428207 240.115265,242.613815 240.345581,241.090057 
	C240.748016,235.267822 240.865677,230.154968 240.983582,224.656418 
	C241.198502,219.236221 246.393372,219.578171 248.933472,217.403137 
	C249.397018,221.873016 249.787231,225.914749 250.177444,229.956467 
z"/>
<path fill="#3680B5" opacity="1.000000" stroke="none" 
	d="
M261.949768,83.991364 
	C260.157257,82.357834 258.456207,80.602913 256.527588,79.150093 
	C255.748367,78.563133 254.269653,78.195045 253.487625,78.553284 
	C252.895752,78.824409 252.646637,80.416199 252.685898,81.402718 
	C253.026428,89.959778 253.466751,98.512863 253.903152,107.604202 
	C257.862457,107.604202 261.871735,107.604202 265.887756,107.951157 
	C261.555847,109.549004 257.217194,110.799896 252.456467,111.708359 
	C252.001328,110.909119 251.968292,110.452293 251.950897,109.562897 
	C251.603806,102.438751 251.241104,95.747177 250.914490,88.604645 
	C250.705521,85.438820 250.200760,82.715042 250.293427,80.011757 
	C250.375000,77.632202 251.124695,75.275558 251.579346,72.908798 
	C254.938507,75.345078 258.297699,77.781357 261.812500,80.909821 
	C261.962036,82.398453 261.955902,83.194908 261.949768,83.991364 
z"/>
<path fill="#A6AFC5" opacity="1.000000" stroke="none" 
	d="
M232.991623,214.614975 
	C225.373016,218.031876 217.849442,221.219681 210.174667,223.988373 
	C207.516220,224.947403 204.482483,224.866165 201.283173,225.117386 
	C200.681152,223.885117 200.416107,222.795547 200.175217,221.368988 
	C204.355713,220.098297 208.474258,218.907043 212.677109,218.290497 
	C219.169876,217.338058 224.542648,214.104218 229.931717,210.741272 
	C230.611176,210.317245 231.718002,210.578125 232.842560,210.794922 
	C233.047943,212.119232 233.037613,213.170258 232.991623,214.614975 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M240.071533,244.611542 
	C243.192719,240.506439 246.161240,236.770309 249.419296,232.883118 
	C249.162872,235.234787 248.616928,237.737518 247.718170,240.755890 
	C243.009476,243.713974 240.594788,249.990524 233.564667,248.021545 
	C221.044388,248.896347 208.951553,250.458542 196.873169,246.698456 
	C196.489807,245.588135 196.233154,244.775024 195.980270,243.568237 
	C195.973053,242.124756 195.962036,241.074951 195.960510,239.763519 
	C195.961929,239.327515 195.953842,239.153168 195.953415,238.582230 
	C195.966629,237.127792 195.972183,236.069946 196.042984,234.957764 
	C196.108246,234.903458 196.238678,234.794708 196.238678,234.794708 
	C197.231262,238.331131 198.223862,241.867569 199.364899,245.932861 
	C212.734589,246.965088 226.354294,248.461685 240.071533,244.611542 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M248.860138,216.974960 
	C246.393372,219.578171 241.198502,219.236221 240.658859,224.643066 
	C239.900574,225.006653 239.467255,224.997910 239.033905,224.989151 
	C238.694748,219.293564 238.355591,213.597977 238.370850,207.623627 
	C242.585739,211.405487 245.205292,206.255936 248.570877,206.441895 
	C248.855835,210.256699 249.058090,213.659134 249.159592,217.043121 
	C249.058853,217.024704 248.860138,216.974960 248.860138,216.974960 
z"/>
<path fill="#9EA7BF" opacity="1.000000" stroke="none" 
	d="
M208.282928,242.350983 
	C206.908447,239.878281 206.588272,238.766556 209.969086,237.783859 
	C216.507217,235.883438 222.692505,232.794220 229.101105,230.404373 
	C230.611725,229.841049 232.428253,230.098038 234.454987,229.976608 
	C234.861679,230.728241 234.917465,231.480927 234.577576,232.625946 
	C230.058456,234.884064 225.928146,236.734924 221.813797,238.620590 
	C218.859344,239.974686 215.926224,241.375290 212.983566,242.755127 
	C211.510788,242.696411 210.038010,242.637711 208.282928,242.350983 
z"/>
<path fill="#A6AFC5" opacity="1.000000" stroke="none" 
	d="
M213.448395,242.828278 
	C215.926224,241.375290 218.859344,239.974686 221.813797,238.620590 
	C225.928146,236.734924 230.058456,234.884064 234.551971,233.003387 
	C235.401276,242.744644 235.401596,242.766235 225.732346,242.908112 
	C221.793518,242.965912 217.852997,242.907166 213.448395,242.828278 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M267.024567,126.047417 
	C269.393890,125.917343 271.763214,125.787270 274.821655,125.619362 
	C274.648468,126.819878 274.464630,128.094315 274.155273,130.100601 
	C273.361450,132.906540 272.693176,134.980621 271.724792,136.954437 
	C268.334839,137.555588 265.245056,138.256989 262.155273,138.958374 
	C262.738464,136.816742 263.321655,134.675095 264.301758,131.947784 
	C265.112274,130.054031 265.525879,128.745956 265.939514,127.437881 
	C265.939514,127.437889 265.787354,127.506058 265.999268,127.245193 
	C266.482300,126.672028 266.753418,126.359718 267.024567,126.047417 
z"/>
<path fill="#34538F" opacity="1.000000" stroke="none" 
	d="
M237.155487,172.980392 
	C236.907684,167.866577 236.762024,162.744949 236.371521,157.642044 
	C236.157318,154.843018 236.898300,153.386307 239.952606,153.041946 
	C244.037140,152.581421 248.070053,151.662994 252.335785,151.262909 
	C249.143753,153.989777 242.479050,153.789688 244.626572,160.976990 
	C237.312469,160.289886 239.308792,165.386719 238.989594,169.342926 
	C238.376907,170.800720 237.766205,171.890549 237.155487,172.980392 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M248.488159,206.029495 
	C245.205292,206.255936 242.585739,211.405487 238.433975,207.162170 
	C237.848618,201.184555 237.554535,195.389664 237.649567,189.269287 
	C238.312851,189.135345 238.614944,189.258530 238.923691,189.740051 
	C238.922363,192.470612 238.714981,194.807983 239.047592,197.065796 
	C239.246338,198.414932 240.434631,200.780365 240.930344,200.718063 
	C242.849869,200.476746 244.850952,199.706757 246.454391,198.601059 
	C247.318314,198.005325 247.437744,196.329941 247.935455,195.566681 
	C248.147934,199.337631 248.318039,202.683563 248.488159,206.029495 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M261.934326,139.281006 
	C265.245056,138.256989 268.334839,137.555588 271.708191,137.341965 
	C271.392365,140.225723 270.792908,142.621704 269.897461,145.034729 
	C266.371735,146.031052 263.142059,147.010330 259.912384,147.989624 
	C260.512726,145.194305 261.113068,142.398987 261.934326,139.281006 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M245.052490,160.967743 
	C242.479050,153.789688 249.143753,153.989777 252.753265,151.313843 
	C254.075027,150.713776 255.189972,150.384506 256.960175,150.080704 
	C258.103088,150.680908 258.590759,151.255676 259.052246,152.118256 
	C259.018677,152.597900 259.011230,152.789703 258.661011,152.982162 
	C257.860413,152.983978 257.402618,152.985123 256.569397,152.993378 
	C253.938126,153.812317 251.682266,154.624146 250.120392,155.186218 
	C251.184402,158.366348 252.113327,161.142761 252.612549,163.953354 
	C250.459076,164.011154 248.735306,164.034744 247.003510,163.700333 
	C246.347824,162.550797 245.700150,161.759277 245.052490,160.967743 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M236.249207,95.911255 
	C236.202637,94.550842 236.156067,93.190437 236.353210,91.222137 
	C236.767288,90.051460 236.937668,89.488670 237.330841,88.892960 
	C237.553665,88.860054 237.998322,88.787895 238.027832,89.090668 
	C241.997040,90.590919 242.489136,86.310326 244.926239,85.387711 
	C245.054718,86.784935 245.160782,87.879074 245.196350,89.415619 
	C245.065720,92.252388 245.005585,94.646759 244.664062,97.237709 
	C240.760666,101.791016 239.045990,95.961670 236.249207,95.911255 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M238.944916,189.313370 
	C238.614944,189.258530 238.312851,189.135345 237.809387,188.858154 
	C237.496780,187.780487 237.413422,186.788498 237.451416,185.305786 
	C237.681747,184.449738 237.566833,184.176422 237.227997,183.995102 
	C237.159470,180.626999 237.090942,177.258896 237.088959,173.435593 
	C237.766205,171.890549 238.376907,170.800720 239.386780,169.357422 
	C241.871582,169.345963 243.957199,169.687988 246.034958,170.455460 
	C246.027100,172.583725 246.027100,174.286530 245.661774,175.932434 
	C244.095444,174.957901 242.894455,174.040298 241.693451,173.122681 
	C241.112411,174.482239 240.280243,175.793106 240.005035,177.212006 
	C239.506149,179.784332 239.339066,182.421021 239.028534,185.453888 
	C238.997375,187.022400 238.971146,188.167892 238.944916,189.313370 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M236.158295,96.339828 
	C239.045990,95.961670 240.760666,101.791016 244.714691,97.652199 
	C245.000183,99.261635 244.953690,100.653160 244.631760,102.256088 
	C241.900253,103.320343 239.444199,104.173195 236.992065,105.014435 
	C236.995987,105.002831 236.973541,105.013199 236.982849,104.678604 
	C236.692535,103.560394 236.392929,102.776772 236.093323,101.993149 
	C236.084686,100.251564 236.076035,98.509987 236.158295,96.339828 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M259.078400,151.830429 
	C258.590759,151.255676 258.103088,150.680908 257.308411,150.024063 
	C257.794647,149.378815 258.587921,148.815659 259.646790,148.121063 
	C263.142059,147.010330 266.371735,146.031052 269.887390,145.414124 
	C269.726074,147.197220 269.278809,148.617981 268.526428,150.092499 
	C266.082611,151.118393 263.943848,152.090500 261.581238,153.048492 
	C261.067383,152.944687 260.771271,152.913559 260.284241,152.700226 
	C259.759094,152.249771 259.418762,152.040100 259.078400,151.830429 
z"/>
<path fill="#D1D5E2" opacity="1.000000" stroke="none" 
	d="
M245.143539,113.921593 
	C244.977753,113.929161 244.811157,113.928642 244.646317,113.945435 
	C236.001038,114.826042 236.001053,114.826210 236.242538,105.479950 
	C236.490692,105.023933 236.973541,105.013199 236.973541,105.013199 
	C236.973541,105.013199 236.995987,105.002831 237.025909,105.379272 
	C238.094635,110.604645 241.254425,110.456062 244.993469,109.494659 
	C245.087097,111.235573 245.115326,112.578583 245.143539,113.921593 
z"/>
<path fill="#406493" opacity="1.000000" stroke="none" 
	d="
M267.046204,125.681252 
	C266.753418,126.359718 266.482300,126.672028 265.955688,127.174103 
	C267.094055,121.539841 268.487885,115.715813 269.977386,109.537483 
	C275.828613,112.931961 269.641113,116.482468 269.736511,120.167122 
	C269.780365,121.858932 268.013550,123.597641 267.046204,125.681252 
z"/>
<path fill="#3680B5" opacity="1.000000" stroke="none" 
	d="
M237.998322,88.787895 
	C237.998322,88.787895 237.553665,88.860054 237.165222,88.820694 
	C236.776779,88.781326 236.426559,88.698845 236.426559,88.698845 
	C236.534698,84.787605 236.642838,80.876373 236.831757,76.237946 
	C236.870682,75.304649 236.828781,75.098534 236.786880,74.892418 
	C236.870300,71.882423 236.953720,68.872429 237.147095,65.421890 
	C237.635132,64.991898 238.013214,65.002441 238.686401,65.420593 
	C238.990677,66.903725 238.999878,67.979248 239.010010,69.482872 
	C238.676804,73.594048 238.342667,77.277115 237.997665,81.401741 
	C237.990631,84.158165 237.994476,86.473030 237.998322,88.787895 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M192.020966,197.031876 
	C192.195786,197.023224 192.370590,197.014587 192.807373,196.988831 
	C193.701401,202.036667 194.333435,207.101624 194.684906,212.600861 
	C194.404343,213.035141 193.987030,213.041458 193.750305,212.876465 
	C193.018219,212.142563 192.522858,211.573654 192.021667,210.539917 
	C192.017563,205.727341 192.019257,201.379608 192.020966,197.031876 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M245.512207,113.949234 
	C245.115326,112.578583 245.087097,111.235573 245.086090,109.217072 
	C245.179733,108.358910 245.246170,108.176231 245.655197,108.056793 
	C247.976929,108.745186 249.956100,109.370331 251.935272,109.995476 
	C251.968292,110.452293 252.001328,110.909119 252.044098,111.709160 
	C249.996170,112.693878 247.938522,113.335381 245.512207,113.949234 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M195.830612,222.032516 
	C195.991974,226.026505 196.153320,230.020477 196.276672,234.404587 
	C196.238678,234.794708 196.108246,234.903458 195.757645,234.674149 
	C194.958481,230.981659 194.509933,227.518463 194.065048,223.728149 
	C194.079987,222.967575 194.091263,222.534119 194.390305,222.082947 
	C195.062256,222.054321 195.446426,222.043411 195.830612,222.032516 
z"/>
<path fill="#CACFDC" opacity="1.000000" stroke="none" 
	d="
M239.013519,225.438568 
	C239.467255,224.997910 239.900574,225.006653 240.658630,225.028763 
	C240.865677,230.154968 240.748016,235.267822 240.350830,240.681442 
	C239.711884,235.950806 239.352493,230.919403 239.013519,225.438568 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M195.761383,221.632126 
	C195.446426,222.043411 195.062256,222.054321 194.352280,221.655487 
	C194.005341,219.829193 193.984177,218.412659 193.970840,216.600052 
	C193.981445,215.149811 193.984238,214.095642 193.987030,213.041458 
	C193.987030,213.041458 194.404343,213.035141 194.613007,213.033173 
	C195.111847,215.764709 195.401993,218.498230 195.761383,221.632126 
z"/>
<path fill="#406493" opacity="1.000000" stroke="none" 
	d="
M265.618591,127.605644 
	C265.525879,128.745956 265.112274,130.054031 264.461365,131.605682 
	C264.581940,130.490646 264.939819,129.132019 265.618591,127.605644 
z"/>
<path fill="#A8DAEF" opacity="1.000000" stroke="none" 
	d="
M236.982849,104.678604 
	C236.973541,105.013199 236.490692,105.023933 236.249298,105.025558 
	C236.008972,104.267235 236.010040,103.507301 236.052216,102.370255 
	C236.392929,102.776772 236.692535,103.560394 236.982849,104.678604 
z"/>
<path fill="#DADEE5" opacity="1.000000" stroke="none" 
	d="
M197.995010,185.376266 
	C198.163727,185.880096 198.329300,186.759583 198.244080,187.822968 
	C197.992813,187.255219 197.992340,186.503571 197.995010,185.376266 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M236.271667,89.066635 
	C236.426559,88.698845 236.776779,88.781326 236.942413,88.853600 
	C236.937668,89.488670 236.767288,90.051460 236.382660,90.814148 
	C236.151199,90.487503 236.134003,89.960968 236.271667,89.066635 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M236.644745,75.017761 
	C236.828781,75.098534 236.870682,75.304649 236.830994,75.815887 
	C236.623184,75.913116 236.471313,75.715309 236.383514,75.492287 
	C236.349167,75.405045 236.458649,75.261185 236.644745,75.017761 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M237.167374,184.266388 
	C237.566833,184.176422 237.681747,184.449738 237.452972,184.930237 
	C237.257690,184.876160 237.182220,184.706909 237.167374,184.266388 
z"/>
<path fill="#F6F8FA" opacity="1.000000" stroke="none" 
	d="
M197.123322,73.709816 
	C197.085480,72.718681 197.190506,71.400055 197.637421,70.013474 
	C197.741592,71.091125 197.503891,72.236725 197.123322,73.709816 
z"/>
<path fill="#F3F3F7" opacity="1.000000" stroke="none" 
	d="
M153.998169,141.004272 
	C153.000366,144.633041 151.808014,148.226593 151.140350,151.915115 
	C150.971512,152.847763 152.241791,154.428558 153.254608,155.119583 
	C157.391754,157.942276 161.548340,160.803741 165.992035,163.075211 
	C167.659973,163.927811 170.128922,163.213394 173.336914,163.213394 
	C171.549225,164.292465 170.170364,165.189301 168.731186,165.975769 
	C167.224991,166.798843 165.660812,167.515869 164.061310,168.639435 
	C160.567261,169.670227 157.133911,170.340820 153.294159,170.663696 
	C149.389236,167.207870 144.590775,164.720047 142.734070,160.828613 
	C140.599564,156.354935 136.811050,154.423340 133.857422,151.271835 
	C132.167023,149.468170 130.447983,147.376373 129.682770,145.098251 
	C128.666672,142.073257 127.236443,140.526581 124.000443,140.548569 
	C124.332695,134.730209 124.666962,129.365707 125.351341,124.038818 
	C126.513824,124.824821 127.610443,125.424797 128.091583,126.346176 
	C130.837723,131.604996 134.524307,135.037735 141.017105,134.057312 
	C142.252563,133.870773 143.598923,134.418488 146.291962,134.852936 
	C143.265411,136.878693 141.544952,138.030228 139.769699,139.218460 
	C141.977783,141.293686 143.670441,142.884506 145.839523,144.923080 
	C143.158722,145.841415 141.769012,146.317459 139.872955,146.966980 
	C145.782684,149.555130 148.123871,147.337097 147.668747,140.348846 
	C148.802536,140.569138 149.901825,140.782745 151.375778,140.997711 
	C152.499680,141.000824 153.248917,141.002548 153.998169,141.004272 
z"/>
<path fill="#E9EAEF" opacity="1.000000" stroke="none" 
	d="
M124.002441,141.002441 
	C127.236443,140.526581 128.666672,142.073257 129.682770,145.098251 
	C130.447983,147.376373 132.167023,149.468170 133.857422,151.271835 
	C136.811050,154.423340 140.599564,156.354935 142.734070,160.828613 
	C144.590775,164.720047 149.389236,167.207870 152.943344,170.661957 
	C152.443069,174.094879 151.887177,177.181808 150.801392,180.627441 
	C144.900681,177.088852 138.852615,173.818939 134.429169,169.044022 
	C132.021103,166.444641 128.426407,162.280975 131.558960,157.600540 
	C128.428085,157.387543 125.716888,157.203094 123.005692,157.018646 
	C122.999924,155.294525 122.994156,153.570435 122.986855,150.991882 
	C122.983330,148.439804 122.981331,146.742188 123.252678,144.805588 
	C123.684822,143.378555 123.843628,142.190491 124.002441,141.002441 
z"/>
<path fill="#204082" opacity="1.000000" stroke="none" 
	d="
M120.616203,199.029282 
	C120.935600,202.078613 121.254997,205.127930 121.190781,208.576202 
	C116.207733,210.295761 112.160927,212.358246 109.747498,217.198151 
	C108.302902,220.268600 107.145958,223.140396 105.682320,225.851990 
	C101.673851,220.982193 98.018280,216.234802 94.223213,211.601654 
	C93.615608,210.859848 92.383774,210.629364 91.325943,209.975403 
	C91.211372,209.788300 91.389748,209.387405 91.767456,209.225494 
	C95.091423,207.722321 98.037674,206.381058 101.332657,205.025726 
	C103.507622,204.110474 105.333855,203.209274 107.515640,202.248413 
	C109.247345,201.786423 110.623512,201.384079 112.237785,200.909027 
	C112.787712,200.666748 112.983856,200.409088 113.069962,200.072159 
	C113.075600,200.080978 113.095100,200.089081 113.457535,200.072662 
	C115.194405,199.668274 116.568840,199.280304 118.276718,198.923218 
	C119.278839,198.979172 119.947525,199.004227 120.616203,199.029282 
z"/>
<path fill="#E0E3EB" opacity="1.000000" stroke="none" 
	d="
M122.864632,157.333984 
	C125.716888,157.203094 128.428085,157.387543 131.558960,157.600540 
	C128.426407,162.280975 132.021103,166.444641 134.429169,169.044022 
	C138.852615,173.818939 144.900681,177.088852 150.643692,180.987778 
	C151.528152,183.177155 152.040451,185.364899 151.919235,187.738373 
	C146.274918,185.493118 141.145584,183.273529 136.296051,180.554871 
	C133.196869,178.817474 130.228210,176.627640 127.708946,174.128632 
	C124.216751,170.664490 121.201622,166.719421 117.982231,162.980270 
	C119.562675,161.203293 121.143120,159.426300 122.864632,157.333984 
z"/>
<path fill="#D1D5E2" opacity="1.000000" stroke="none" 
	d="
M108.984238,166.971436 
	C110.434608,166.011932 111.884979,165.052414 113.636307,164.385651 
	C114.205154,165.923096 114.100235,167.462845 114.809120,168.358521 
	C117.167694,171.338547 121.401672,172.862213 121.526718,177.609756 
	C121.545685,178.329483 123.079147,179.149048 124.046646,179.674850 
	C126.031250,180.753433 128.405167,181.305481 130.092422,182.707687 
	C136.518280,188.047928 142.992538,193.098679 151.941498,193.022339 
	C151.947800,194.088593 151.954102,195.154831 151.550751,196.614029 
	C141.840027,198.345062 134.844177,193.297287 127.791756,188.496841 
	C126.383705,187.538406 125.246849,186.181549 123.984940,185.008408 
	C122.236481,183.993942 120.488022,182.979477 118.405975,181.609039 
	C118.493546,179.729843 118.914696,178.206650 119.335846,176.683441 
	C118.747307,176.501724 118.158775,176.320023 117.570236,176.138306 
	C116.710754,177.753662 115.851273,179.369003 114.659874,180.986771 
	C113.885391,180.992996 113.442818,180.996811 112.868805,180.688873 
	C112.816650,176.997925 115.715240,172.458649 109.315086,172.235703 
	C109.185959,170.181030 109.085098,168.576233 108.984238,166.971436 
z"/>
<path fill="#DADEE5" opacity="1.000000" stroke="none" 
	d="
M151.961060,192.603912 
	C142.992538,193.098679 136.518280,188.047928 130.092422,182.707687 
	C128.405167,181.305481 126.031250,180.753433 124.046646,179.674850 
	C123.079147,179.149048 121.545685,178.329483 121.526718,177.609756 
	C121.401672,172.862213 117.167694,171.338547 114.809120,168.358521 
	C114.100235,167.462845 114.205154,165.923096 113.969093,164.335953 
	C115.095802,163.660141 116.190697,163.326767 117.633911,162.986832 
	C121.201622,166.719421 124.216751,170.664490 127.708946,174.128632 
	C130.228210,176.627640 133.196869,178.817474 136.296051,180.554871 
	C141.145584,183.273529 146.274918,185.493118 151.651001,187.964813 
	C152.004379,189.398834 151.992493,190.792160 151.961060,192.603912 
z"/>
<path fill="#B2BACE" opacity="1.000000" stroke="none" 
	d="
M126.181717,212.043655 
	C125.847160,209.654007 125.512604,207.264343 125.614967,204.449402 
	C128.548309,204.613083 131.322128,204.697144 133.488281,205.887253 
	C139.583054,209.235779 145.918243,211.521271 152.866623,212.034485 
	C153.147293,215.107376 153.427979,218.180252 153.277527,221.638077 
	C143.958160,218.696564 135.069931,215.370102 126.181717,212.043655 
z"/>
<path fill="#CACFDC" opacity="1.000000" stroke="none" 
	d="
M123.993973,185.473923 
	C125.246849,186.181549 126.383705,187.538406 127.791756,188.496841 
	C134.844177,193.297287 141.840027,198.345062 151.527161,197.013260 
	C152.244324,199.751816 152.575409,202.484100 152.454544,205.615509 
	C149.729828,205.704376 147.262207,205.854309 145.223557,204.991135 
	C140.509277,202.995132 136.052780,200.397186 131.378677,198.294724 
	C130.127945,197.732117 128.470062,198.074707 126.735977,198.204361 
	C126.316727,199.266037 126.160576,200.125107 125.747513,200.992065 
	C125.319351,201.006195 125.148102,201.012421 124.976860,201.018661 
	C124.652245,195.992249 124.327629,190.965851 123.993973,185.473923 
z"/>
<path fill="#B2BACE" opacity="1.000000" stroke="none" 
	d="
M86.931236,178.540878 
	C89.713432,176.721146 92.495628,174.901413 95.941048,173.190216 
	C101.687485,174.962143 98.902489,179.254349 99.332932,182.556686 
	C103.634872,180.220337 105.759018,181.397781 105.982986,185.947678 
	C101.220955,187.925232 96.458923,189.902786 91.339310,191.505188 
	C90.818840,189.186676 91.021141,187.116913 90.403152,185.331024 
	C89.579941,182.952042 88.118515,180.793884 86.931236,178.540878 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M100.983925,205.039795 
	C98.037674,206.381058 95.091423,207.722321 91.799393,209.207001 
	C91.453606,209.350403 91.518822,209.315689 91.566139,209.132828 
	C91.531990,209.098450 91.450531,209.246948 91.167969,209.656494 
	C90.314102,209.957825 89.661339,209.998093 88.782257,209.748810 
	C87.612747,209.369873 86.635681,209.076065 85.732071,209.225754 
	C81.290787,209.961472 78.332787,207.294266 78.838516,202.898239 
	C78.838516,202.898239 78.888710,202.865860 79.150330,202.830566 
	C79.710320,202.648911 79.913933,202.418701 80.022789,202.104660 
	C84.385170,199.985062 88.747559,197.865479 93.436958,196.054352 
	C96.170624,199.255142 98.577271,202.147476 100.983925,205.039795 
z"/>
<path fill="#C0C5D6" opacity="1.000000" stroke="none" 
	d="
M126.999084,198.001755 
	C128.470062,198.074707 130.127945,197.732117 131.378677,198.294724 
	C136.052780,200.397186 140.509277,202.995132 145.223557,204.991135 
	C147.262207,205.854309 149.729828,205.704376 152.414307,206.028381 
	C152.871658,207.754166 152.917282,209.466187 152.914764,211.606354 
	C145.918243,211.521271 139.583054,209.235779 133.488281,205.887253 
	C131.322128,204.697144 128.548309,204.613083 125.660072,204.043961 
	C125.186264,203.303101 125.104279,202.542389 124.999573,201.400177 
	C125.148102,201.012421 125.319351,201.006195 126.010162,200.790222 
	C126.686172,199.720901 126.842628,198.861328 126.999084,198.001755 
z"/>
<path fill="#9EA7BF" opacity="1.000000" stroke="none" 
	d="
M153.868698,227.984253 
	C153.886887,228.165512 153.905075,228.346756 153.578857,228.960526 
	C150.943970,230.111893 147.026352,231.937180 146.636368,231.363663 
	C144.634354,228.419510 141.856918,228.349243 139.139755,227.946014 
	C139.185730,227.927414 139.092560,227.963394 138.614273,227.991272 
	C134.541290,226.662933 130.853424,225.342728 127.165550,224.022537 
	C127.117546,222.628876 127.069542,221.235214 127.415039,219.423233 
	C136.495270,221.998032 145.181992,224.991150 153.868698,227.984253 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M110.035133,216.999527 
	C112.160927,212.358246 116.207733,210.295761 121.162491,208.976044 
	C121.971886,212.707504 122.425964,216.438065 122.604172,220.819916 
	C122.195549,222.297043 122.062805,223.122910 121.623756,224.108917 
	C120.001266,224.140945 118.319702,224.482666 117.437485,223.796539 
	C114.802010,221.746918 112.479729,219.294586 110.035133,216.999527 
z"/>
<path fill="#A6AFC5" opacity="1.000000" stroke="none" 
	d="
M153.914810,227.561401 
	C145.181992,224.991150 136.495270,221.998032 127.404785,219.004150 
	C126.705933,216.959213 126.410835,214.915039 126.148727,212.457245 
	C135.069931,215.370102 143.958160,218.696564 153.232101,222.040359 
	C153.732193,223.751328 153.846558,225.444946 153.914810,227.561401 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M157.238998,221.918365 
	C156.854126,217.911514 156.469238,213.904678 156.441956,209.519592 
	C157.387207,209.814377 157.974854,210.487396 158.542664,211.137711 
	C158.974075,210.475510 159.471832,209.711487 160.199860,209.217438 
	C162.925766,209.336990 165.421432,209.186584 167.955383,209.473267 
	C168.193359,213.299332 168.393036,216.688324 168.219879,220.069092 
	C164.311035,220.680023 160.775009,221.299194 157.238998,221.918365 
z"/>
<path fill="#34538F" opacity="1.000000" stroke="none" 
	d="
M156.972412,241.174744 
	C157.438766,241.165588 157.905121,241.156433 159.060654,241.110748 
	C162.017349,239.292877 164.284866,237.511551 166.867233,235.912506 
	C167.454742,236.731964 167.727371,237.369110 168.000107,238.002945 
	C168.000214,237.999619 168.006393,237.999344 167.682037,238.040298 
	C166.906403,238.731903 166.455109,239.382553 166.003098,240.271622 
	C166.002380,240.510040 166.001541,240.986893 165.756287,241.156158 
	C165.340271,241.882217 165.169525,242.439011 164.741486,243.203003 
	C165.320648,245.276627 166.157089,247.143036 166.990219,249.379028 
	C166.477783,252.788605 164.918884,254.608948 161.265274,254.754807 
	C162.361084,252.875183 163.216644,251.407654 164.515076,249.180466 
	C159.926727,249.323395 155.283813,252.108871 154.140320,245.682007 
	C154.525314,244.242081 154.770599,243.122604 155.295410,241.986694 
	C156.040771,241.705093 156.506592,241.439926 156.972412,241.174744 
z"/>
<path fill="#C0C5D6" opacity="1.000000" stroke="none" 
	d="
M106.355240,185.936813 
	C105.759018,181.397781 103.634872,180.220337 99.332932,182.556686 
	C98.902489,179.254349 101.687485,174.962143 96.287598,173.135193 
	C97.750023,172.000412 99.529114,171.029160 101.630844,170.341614 
	C102.003998,171.368729 101.755287,172.426376 102.153030,172.805084 
	C104.964996,175.482468 107.875832,178.060638 110.845413,180.563309 
	C111.337219,180.977798 112.270821,180.868073 113.000252,181.000610 
	C113.442818,180.996811 113.885391,180.992996 114.882629,181.102890 
	C115.630325,181.485107 115.823349,181.753616 116.016373,182.022125 
	C112.920082,183.323395 109.823784,184.624664 106.355240,185.936813 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M166.552368,235.730225 
	C164.284866,237.511551 162.017349,239.292877 159.405884,241.046967 
	C158.759552,236.591125 158.457169,232.162521 158.603012,227.400391 
	C162.320572,227.061249 165.589890,227.055664 168.943192,227.269623 
	C169.027161,227.489182 169.191483,227.930054 169.138000,228.335159 
	C168.240448,231.070251 167.396408,233.400223 166.552368,235.730225 
z"/>
<path fill="#959FB9" opacity="1.000000" stroke="none" 
	d="
M71.922897,187.975159 
	C74.040077,186.386368 76.157257,184.797577 78.865799,183.253418 
	C82.025375,185.507935 84.651970,187.655182 87.128700,189.963242 
	C87.946342,190.725204 88.377159,191.902237 88.984848,192.889481 
	C87.315559,193.629929 85.646278,194.370377 83.263023,194.976379 
	C79.101807,194.178162 76.250809,192.812546 74.775917,189.254395 
	C74.485649,188.554108 72.907234,188.387756 71.922897,187.975159 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M78.489304,202.916962 
	C78.332787,207.294266 81.290787,209.961472 85.732071,209.225754 
	C86.635681,209.076065 87.612747,209.369873 88.601006,210.035034 
	C87.182892,211.160568 85.719734,211.710312 84.256569,212.260071 
	C77.952614,211.995132 71.648651,211.730194 65.091110,211.171417 
	C69.271713,208.230286 73.705902,205.582977 78.489304,202.916962 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M192.148834,176.527527 
	C191.590225,176.985565 191.136871,176.993759 190.186401,176.641205 
	C189.450073,174.511734 189.486755,172.638733 188.872086,171.011841 
	C188.479355,169.972351 186.917648,168.429489 186.231186,168.592346 
	C179.439835,170.203568 172.721939,172.124435 165.606888,173.969879 
	C164.475647,173.978226 163.720779,173.974335 162.933380,173.628448 
	C162.862686,173.060638 162.824539,172.834808 162.822998,172.454132 
	C162.859634,172.299286 162.914551,171.985779 162.914551,171.985779 
	C162.914551,171.985779 162.985184,172.006485 163.244232,171.955078 
	C163.503265,171.903687 163.959290,171.699875 163.959290,171.699875 
	C171.898041,169.683121 179.836807,167.666351 188.102661,165.433502 
	C189.531860,165.217392 190.633972,165.217392 192.254089,165.217392 
	C192.254089,168.939407 192.254089,172.508560 192.148834,176.527527 
z"/>
<path fill="#959FB9" opacity="1.000000" stroke="none" 
	d="
M139.000610,228.000610 
	C141.856918,228.349243 144.634354,228.419510 146.636368,231.363663 
	C147.026352,231.937180 150.943970,230.111893 153.531631,229.229645 
	C154.116608,230.993179 154.404404,232.920105 154.903000,236.258606 
	C151.814606,235.413483 149.106094,235.015823 146.695419,233.946671 
	C140.106705,231.024536 133.643524,227.819397 127.148087,224.374451 
	C130.853424,225.342728 134.541290,226.662933 138.614578,227.991577 
	C139.000000,228.000000 139.000610,228.000610 139.000610,228.000610 
z"/>
<path fill="#405186" opacity="1.000000" stroke="none" 
	d="
M156.599731,241.081665 
	C156.506592,241.439926 156.040771,241.705093 154.903412,241.937775 
	C146.162766,239.215469 138.187698,236.178726 129.991653,233.957291 
	C124.484825,232.464752 121.826164,229.538483 121.930054,223.948761 
	C122.062805,223.122910 122.195549,222.297043 122.545380,221.240570 
	C122.632233,228.287888 127.657600,230.983688 133.432297,233.008057 
	C141.029480,235.671280 148.628708,238.328674 156.599731,241.081665 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M101.332657,205.025726 
	C98.577271,202.147476 96.170624,199.255142 93.811058,196.047073 
	C93.858147,195.731323 94.163742,195.539566 94.665970,195.637360 
	C95.808105,195.250153 96.448021,194.765137 97.087929,194.280121 
	C97.087929,194.280121 97.491608,194.059586 97.990799,194.069229 
	C98.674751,194.110168 98.859520,194.141479 99.085434,194.516235 
	C99.934097,195.536377 100.950996,196.939758 101.516602,196.776794 
	C106.854362,195.238861 106.473732,199.275940 107.160095,202.308075 
	C105.333855,203.209274 103.507622,204.110474 101.332657,205.025726 
z"/>
<path fill="#9EA7BF" opacity="1.000000" stroke="none" 
	d="
M89.278809,192.880569 
	C88.377159,191.902237 87.946342,190.725204 87.128700,189.963242 
	C84.651970,187.655182 82.025375,185.507935 79.170250,183.098434 
	C81.327316,181.516724 83.771309,180.134628 86.573273,178.646698 
	C88.118515,180.793884 89.579941,182.952042 90.403152,185.331024 
	C91.021141,187.116913 90.818840,189.186676 90.970108,191.515854 
	C90.496574,192.224991 90.034676,192.548325 89.278809,192.880569 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M159.969604,208.947479 
	C159.471832,209.711487 158.974075,210.475510 158.542664,211.137711 
	C157.974854,210.487396 157.387207,209.814377 156.506134,209.106110 
	C156.150421,207.963470 156.088120,206.856064 156.440659,205.279205 
	C157.545670,202.872910 158.235840,200.936050 159.382874,198.999573 
	C162.537949,199.020554 165.236191,199.041183 167.944504,199.495071 
	C167.962860,201.952148 167.971130,203.975967 167.583893,205.949036 
	C164.182800,205.495789 159.977646,202.253418 159.969604,208.947479 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M168.859222,227.050064 
	C165.589890,227.055664 162.320572,227.061249 158.702774,226.984222 
	C157.997009,225.484726 157.639709,224.067841 157.260712,222.284653 
	C160.775009,221.299194 164.311035,220.680023 168.304306,220.498672 
	C168.794113,222.974350 168.826675,225.012207 168.859222,227.050064 
z"/>
<path fill="#306AA2" opacity="1.000000" stroke="none" 
	d="
M120.652069,198.619766 
	C119.947525,199.004227 119.278839,198.979172 118.289917,198.494385 
	C117.434036,195.470444 116.898392,192.906235 116.362747,190.342026 
	C113.570091,191.193405 110.777435,192.044785 107.606422,192.919067 
	C105.097794,192.924194 102.967522,192.906403 100.837242,192.888611 
	C106.864532,190.505280 112.891815,188.121948 119.425919,185.538208 
	C119.897575,190.274094 120.292755,194.242172 120.652069,198.619766 
z"/>
<path fill="#278DC9" opacity="1.000000" stroke="none" 
	d="
M100.620811,192.882172 
	C102.967522,192.906403 105.097794,192.924194 107.751633,193.238281 
	C109.881828,195.719421 111.488464,197.904251 113.095100,200.089081 
	C113.095100,200.089081 113.075600,200.080978 112.838150,200.153458 
	C112.311478,200.407181 112.111137,200.659119 111.999687,200.981750 
	C110.623512,201.384079 109.247345,201.786423 107.515640,202.248413 
	C106.473732,199.275940 106.854362,195.238861 101.516602,196.776794 
	C100.950996,196.939758 99.934097,195.536377 99.280571,194.353821 
	C99.621880,193.572372 99.809174,193.296753 99.996475,193.021149 
	C99.996475,193.021149 100.404381,192.875732 100.620811,192.882172 
z"/>
<path fill="#9EA7BF" opacity="1.000000" stroke="none" 
	d="
M71.613594,188.021469 
	C72.907234,188.387756 74.485649,188.554108 74.775917,189.254395 
	C76.250809,192.812546 79.101807,194.178162 82.921646,195.025452 
	C81.099159,196.424240 78.904076,197.639481 76.360733,198.838455 
	C74.004219,196.091629 71.995964,193.361053 69.956772,190.243759 
	C70.385323,189.260605 70.844810,188.664200 71.613594,188.021469 
z"/>
<path fill="#406493" opacity="1.000000" stroke="none" 
	d="
M162.786377,172.608978 
	C162.824539,172.834808 162.862686,173.060638 162.667770,173.761993 
	C161.930664,174.840164 161.426620,175.442825 160.565338,176.035736 
	C159.496109,175.995010 158.784149,175.964005 157.769226,175.835327 
	C157.169144,182.386749 156.872009,189.035873 156.574860,195.684982 
	C156.111298,194.981033 155.251465,194.280121 155.246094,193.572678 
	C155.197632,187.202316 155.299072,180.830811 156.025146,174.221268 
	C157.309616,173.725510 157.925476,173.468124 158.541321,173.210754 
	C158.881393,173.204315 159.221466,173.197876 160.141296,173.107361 
	C161.409500,172.885193 162.097931,172.747086 162.786377,172.608978 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M156.497391,196.156097 
	C156.872009,189.035873 157.169144,182.386749 157.746735,176.310028 
	C158.036682,182.263321 158.046173,187.644226 158.042267,193.425049 
	C158.327927,195.549698 158.626968,197.274445 158.926010,198.999207 
	C158.235840,200.936050 157.545670,202.872910 156.516663,204.871429 
	C156.258530,202.164490 156.339218,199.395844 156.497391,196.156097 
z"/>
<path fill="#CACFDC" opacity="1.000000" stroke="none" 
	d="
M116.344887,182.008514 
	C115.823349,181.753616 115.630325,181.485107 115.214539,181.100479 
	C115.851273,179.369003 116.710754,177.753662 117.570236,176.138306 
	C118.158775,176.320023 118.747307,176.501724 119.335846,176.683441 
	C118.914696,178.206650 118.493546,179.729843 118.029572,181.589569 
	C117.548973,181.949036 117.111183,181.971985 116.344887,182.008514 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M96.782379,194.234375 
	C96.448021,194.765137 95.808105,195.250153 94.843140,195.603409 
	C95.170990,195.043976 95.823914,194.616302 96.782379,194.234375 
z"/>
<path fill="#E1F3ED" opacity="1.000000" stroke="none" 
	d="
M162.822998,172.454132 
	C162.097931,172.747086 161.409500,172.885193 160.372406,172.988190 
	C160.770477,172.627350 161.517181,172.301590 162.589233,171.980804 
	C162.914551,171.985779 162.859634,172.299286 162.822998,172.454132 
z"/>
<path fill="#63B8E1" opacity="1.000000" stroke="none" 
	d="
M99.692268,193.003082 
	C99.809174,193.296753 99.621880,193.572372 99.239441,194.010376 
	C98.859520,194.141479 98.674751,194.110168 98.215164,194.018677 
	C98.422920,193.633987 98.905487,193.309509 99.692268,193.003082 
z"/>
<path fill="#E1F3ED" opacity="1.000000" stroke="none" 
	d="
M158.190491,173.158661 
	C157.925476,173.468124 157.309616,173.725510 156.354294,173.939972 
	C156.623093,173.633545 157.231384,173.370056 158.190491,173.158661 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M79.780746,202.129044 
	C79.913933,202.418701 79.710320,202.648911 79.179733,202.835785 
	C79.006203,202.522141 79.203262,202.281189 79.780746,202.129044 
z"/>
<path fill="#E1F3ED" opacity="1.000000" stroke="none" 
	d="
M163.842865,171.575699 
	C163.959290,171.699875 163.503265,171.903687 163.254364,171.919708 
	C163.245789,171.774338 163.486115,171.612930 163.842865,171.575699 
z"/>
<path fill="#2A3F95" opacity="1.000000" stroke="none" 
	d="
M234.011078,248.032806 
	C240.594788,249.990524 243.009476,243.713974 247.672806,241.132111 
	C248.592773,240.814529 249.243805,240.715942 249.810028,240.442032 
	C251.102783,239.816757 253.245758,238.302658 253.477325,238.559830 
	C254.974930,240.222885 258.364838,240.936172 257.012817,244.678619 
	C256.577301,245.865906 256.351654,247.006790 255.664291,247.487396 
	C253.200150,249.210495 250.660049,250.869431 247.981110,252.220352 
	C246.815170,252.808289 245.242783,252.590286 243.507355,252.775406 
	C243.631592,253.733429 243.789200,254.948883 243.897919,256.505859 
	C239.008347,258.612427 234.240082,260.620605 229.310883,262.088257 
	C220.958725,264.575073 212.601105,268.089203 204.071350,268.688416 
	C191.316040,269.584351 178.400055,268.511780 165.575027,267.842987 
	C162.812729,267.698975 160.146667,265.709717 157.177948,263.932007 
	C156.674240,262.001587 156.428864,260.709625 155.929993,258.083160 
	C158.743729,259.706635 160.284653,260.554108 161.784454,261.468994 
	C166.160904,264.138550 173.900375,262.664734 178.385529,260.597351 
	C182.108170,258.881470 186.974716,259.598328 191.341934,259.409546 
	C191.755447,259.391663 192.719162,260.736847 192.588684,261.224976 
	C190.950043,267.354980 195.488800,265.926208 198.471298,265.917725 
	C201.251816,265.909821 204.030701,265.324554 207.818176,264.873383 
	C205.071152,263.065460 203.052689,261.737000 201.255737,260.554352 
	C205.552765,253.220291 212.165344,255.143646 218.126038,255.027069 
	C224.331314,254.905685 230.540802,255.000000 237.389496,255.000000 
	C235.896439,251.920914 234.953751,249.976852 234.011078,248.032806 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M296.987579,166.994263 
	C294.977753,178.865250 293.267883,190.740952 283.306610,199.417175 
	C280.318512,202.019775 278.902954,206.348114 276.180237,209.354462 
	C272.434662,213.490265 268.247803,217.255112 264.025726,220.920578 
	C261.224060,223.352921 258.030029,225.333313 253.999283,228.248093 
	C253.999283,225.260040 253.999283,223.055267 253.996582,220.046661 
	C253.994095,218.496231 253.994293,217.749649 254.339706,217.033936 
	C255.912735,217.067749 257.560303,217.585342 258.296295,216.985138 
	C261.611511,214.281464 265.043030,211.569672 267.743073,208.292664 
	C274.129272,200.541763 279.809784,192.193558 286.412476,184.643814 
	C289.578247,181.023956 291.674927,177.554291 292.443451,172.782593 
	C292.784668,170.664154 295.402924,168.912445 296.987579,166.994263 
z"/>
<path fill="#264B9C" opacity="1.000000" stroke="none" 
	d="
M296.990753,166.554840 
	C295.402924,168.912445 292.784668,170.664154 292.443451,172.782593 
	C291.674927,177.554291 289.578247,181.023956 286.412476,184.643814 
	C279.809784,192.193558 274.129272,200.541763 267.743073,208.292664 
	C265.043030,211.569672 261.611511,214.281464 258.296295,216.985138 
	C257.560303,217.585342 255.912735,217.067749 254.103424,217.028961 
	C253.521927,216.993103 253.049179,216.984543 253.084595,216.589859 
	C253.414780,213.795227 253.709518,211.395279 254.359772,209.032349 
	C259.757446,210.900970 262.593750,207.439362 265.073456,204.243546 
	C270.635956,197.074661 276.034668,189.757706 281.102936,182.233536 
	C284.565704,177.092773 287.778992,171.689499 290.312897,166.048325 
	C292.233643,161.772293 292.856140,156.920914 294.196442,152.366806 
	C294.369293,151.779510 295.383881,151.439941 296.008545,150.985596 
	C296.029907,152.103897 296.051270,153.222183 296.047974,154.631607 
	C296.346832,158.653625 296.670380,162.384506 296.990753,166.554840 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M253.049179,216.984543 
	C253.049179,216.984543 253.521927,216.993103 253.758209,216.998077 
	C253.994293,217.749649 253.994095,218.496231 253.993896,219.615875 
	C252.885620,223.124573 251.777359,226.260254 250.423279,229.676208 
	C249.787231,225.914749 249.397018,221.873016 248.933472,217.403137 
	C248.860138,216.974960 249.058853,217.024704 249.537048,217.025543 
	C251.026550,217.012436 252.037872,216.998489 253.049179,216.984543 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M249.875458,232.756058 
	C249.676086,232.729111 249.742996,232.326492 249.871216,232.167862 
	C250.139648,232.205963 250.279877,232.402695 250.420105,232.599426 
	C250.305008,232.660614 250.189926,232.721817 249.875458,232.756058 
z"/>
<path fill="#4FA44A" opacity="1.000000" stroke="none" 
	d="
M119.184647,153.994522 
	C118.175911,155.311783 117.439621,157.130203 116.108261,157.853973 
	C111.818008,160.186264 107.308464,162.115204 102.501709,163.922760 
	C102.128922,163.449341 102.137894,163.252808 102.405014,162.847275 
	C103.489761,161.118652 104.316360,159.599014 105.503082,158.078857 
	C106.584312,158.067139 107.305412,158.055939 108.407867,158.042923 
	C110.877289,156.699203 112.965363,155.357300 115.268326,153.902557 
	C115.751053,153.587402 115.935860,153.326294 116.259781,152.829773 
	C115.996307,152.092712 115.510696,151.532288 115.022903,150.586914 
	C115.339165,148.471176 115.657608,146.740341 115.982948,144.569611 
	C115.420830,138.884476 115.244789,133.560043 114.206589,128.409409 
	C111.469589,114.830757 113.429359,102.328148 121.614632,90.971581 
	C123.068253,88.954758 124.674683,86.972214 125.683884,84.736145 
	C126.423141,83.098175 126.300156,81.071060 126.561470,79.217384 
	C126.169029,78.922211 125.776588,78.627029 125.384155,78.331856 
	C128.925659,76.218575 132.731247,74.430199 135.898178,71.856079 
	C137.501434,70.552933 137.950912,67.830292 138.922028,65.749420 
	C156.057739,56.338440 174.426956,52.526550 194.483307,53.114597 
	C194.239944,57.253746 194.028915,60.843090 193.287582,64.752541 
	C192.505661,65.043724 192.254044,65.014793 192.001022,64.583519 
	C192.200974,60.679188 189.818726,59.868320 187.155273,60.059780 
	C180.069778,60.569092 172.657028,57.271019 165.891754,61.825573 
	C165.416275,62.145676 164.510956,61.767403 163.945679,62.023399 
	C159.158569,64.191353 154.392792,66.407547 149.646103,68.662941 
	C148.073654,69.410088 146.663635,70.701271 145.026978,71.073166 
	C140.109833,72.190460 137.131531,75.277542 134.903824,79.584366 
	C133.890564,81.543297 132.546371,83.771767 130.739059,84.798111 
	C124.896553,88.115982 124.020576,93.883995 121.581078,100.165802 
	C124.537193,98.793640 126.385460,97.935707 128.163620,97.429550 
	C126.412323,100.727730 123.579315,103.549171 123.243774,106.641602 
	C121.535324,122.387344 120.473091,138.203201 119.184647,153.994522 
z"/>
<path fill="#FDFDFB" opacity="1.000000" stroke="none" 
	d="
M193.989990,86.450806 
	C194.996704,87.403740 195.998291,88.819687 197.000671,91.012192 
	C198.278091,94.117683 199.326538,98.237633 200.874771,98.434875 
	C205.263443,98.993996 206.045654,101.953430 206.293854,104.860947 
	C206.752625,110.235207 206.793716,115.704399 206.354630,121.080627 
	C205.838150,127.404480 198.843094,129.136749 196.060593,134.036804 
	C195.804123,134.488449 192.731827,133.341156 190.969879,132.555206 
	C190.931900,131.407501 190.891113,130.640945 190.895920,129.927582 
	C190.941528,129.980789 190.881073,129.854370 191.192505,129.875427 
	C191.586441,129.257706 191.668961,128.618927 191.756210,127.572815 
	C191.833496,126.082115 191.906052,124.998756 192.377869,123.855957 
	C193.002243,117.403061 193.239120,111.009872 193.395920,104.614723 
	C193.400650,104.421959 192.568039,104.208656 192.077408,103.591705 
	C192.074127,101.794235 192.119247,100.409996 192.164398,99.025757 
	C192.768509,97.084381 193.614807,95.178940 193.909714,93.191673 
	C194.214081,91.140511 193.982147,89.009758 193.989990,86.450806 
z"/>
<path fill="#39A042" opacity="1.000000" stroke="none" 
	d="
M138.572235,65.833565 
	C137.950912,67.830292 137.501434,70.552933 135.898178,71.856079 
	C132.731247,74.430199 128.925659,76.218575 125.384155,78.331856 
	C125.776588,78.627029 126.169029,78.922211 126.561470,79.217384 
	C126.300156,81.071060 126.423141,83.098175 125.683884,84.736145 
	C124.674683,86.972214 123.068253,88.954758 121.614632,90.971581 
	C113.429359,102.328148 111.469589,114.830757 114.206589,128.409409 
	C115.244789,133.560043 115.420830,138.884476 115.582581,144.566193 
	C113.758545,143.806274 111.486153,142.839142 111.092094,141.368668 
	C110.078102,137.584778 109.805496,133.587280 109.398506,129.658386 
	C108.079674,116.926895 108.088867,104.358292 115.562881,93.154465 
	C118.027115,89.460510 117.069420,87.736221 113.018463,87.001709 
	C113.011215,86.998657 112.997040,87.003624 112.856636,86.750290 
	C112.401062,86.277542 112.085892,86.058136 111.770721,85.838730 
	C120.587967,79.198395 129.405212,72.558052 138.572235,65.833565 
z"/>
<path fill="#60B144" opacity="1.000000" stroke="none" 
	d="
M192.002441,64.985870 
	C192.254044,65.014793 192.505661,65.043724 193.132095,65.144455 
	C193.317581,67.825912 193.128265,70.435547 192.508881,73.312332 
	C187.872864,73.053329 183.596680,71.817696 179.483109,72.224861 
	C176.173782,72.552399 173.061203,74.867569 169.514771,76.242630 
	C167.492844,75.550522 165.817474,74.916603 164.076233,73.917068 
	C162.837677,73.040703 161.254639,71.765144 160.556870,72.139534 
	C154.673798,75.296051 148.940628,78.733955 143.189178,82.132614 
	C141.097351,83.368721 139.077988,84.727516 137.052750,85.672714 
	C145.083572,71.596985 159.025635,68.061241 172.950226,65.209839 
	C179.060043,63.958702 185.637390,64.990616 192.002441,64.985870 
z"/>
<path fill="#259446" opacity="1.000000" stroke="none" 
	d="
M115.053436,154.015396 
	C112.965363,155.357300 110.877289,156.699203 108.352745,157.643799 
	C107.485970,154.995895 107.551872,152.488434 106.542076,150.537842 
	C101.321655,140.453812 99.548500,129.847397 101.019760,118.655647 
	C101.339157,116.226089 102.036057,113.843559 102.310478,111.411324 
	C102.663864,108.279198 102.766747,105.118797 102.983299,101.601685 
	C103.836731,99.510056 104.705284,97.797241 105.527000,96.062225 
	C106.675735,93.636742 107.789513,91.194687 108.918243,88.759720 
	C109.347908,88.219780 109.777573,87.679848 110.827423,87.043762 
	C111.964081,86.966286 112.480560,86.984955 112.997040,87.003624 
	C112.997040,87.003624 113.011215,86.998657 112.968658,87.367935 
	C111.647369,91.024605 109.914673,94.214294 109.181976,97.619171 
	C107.525757,105.315712 105.433807,113.097878 105.260902,120.883888 
	C105.087402,128.696655 106.455284,136.694061 108.294144,144.334595 
	C109.151611,147.897415 112.716003,150.808762 115.053436,154.015396 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M101.688065,164.302963 
	C98.318512,165.908890 94.818283,167.292480 91.612328,169.176758 
	C87.565964,171.555008 83.759605,174.341583 79.145744,176.940826 
	C78.307693,175.437759 77.513786,173.536362 78.144951,172.519455 
	C82.770355,165.067093 81.378410,156.964310 81.123581,148.966797 
	C81.123581,148.966797 80.941505,149.061111 80.839020,149.063812 
	C80.865341,148.613159 80.994141,148.159836 81.596161,147.366974 
	C83.040787,147.015381 84.012192,147.003342 85.301468,147.042786 
	C85.474159,148.750992 85.045700,150.439957 85.230057,152.059158 
	C86.369576,162.067581 90.323608,165.188843 100.222664,164.125732 
	C100.699295,164.074554 101.199066,164.238968 101.688065,164.302963 
M91.410919,166.482056 
	C91.410919,166.482056 91.523438,166.414932 91.410919,166.482056 
z"/>
<path fill="#8FC445" opacity="1.000000" stroke="none" 
	d="
M192.798187,75.089951 
	C192.536209,78.455284 192.274231,81.820610 191.721359,85.831512 
	C191.281616,87.317703 191.132767,88.158318 190.608978,88.955994 
	C186.805557,87.804741 183.377075,86.696434 179.540604,85.456245 
	C179.111221,88.903160 178.812561,91.300728 178.197540,93.520416 
	C174.109756,91.844902 173.507645,89.143486 174.334213,85.404213 
	C174.757767,83.488136 174.042923,81.320435 174.036682,78.907845 
	C178.559692,77.895439 182.899445,77.304527 187.212448,76.558823 
	C189.102524,76.232040 190.937943,75.589249 192.798187,75.089951 
z"/>
<path fill="#247649" opacity="1.000000" stroke="none" 
	d="
M84.983597,146.991287 
	C84.012192,147.003342 83.040787,147.015381 81.705154,147.028564 
	C83.779884,124.669731 93.748001,105.820770 108.629807,88.944183 
	C107.789513,91.194687 106.675735,93.636742 105.527000,96.062225 
	C104.705284,97.797241 103.836731,99.510056 102.681213,101.758453 
	C95.618202,106.693039 94.518585,113.944145 92.661469,121.021942 
	C91.558121,122.041367 90.773666,123.013664 89.718201,124.212952 
	C86.372963,129.889496 85.063133,135.786682 84.991081,142.374908 
	C84.989647,143.505157 84.989799,144.258774 84.989685,145.342270 
	C84.987473,146.111862 84.985535,146.551575 84.983597,146.991287 
z"/>
<path fill="#A2CB3C" opacity="1.000000" stroke="none" 
	d="
M178.513885,93.698296 
	C178.812561,91.300728 179.111221,88.903160 179.540604,85.456245 
	C183.377075,86.696434 186.805557,87.804741 190.810303,88.968964 
	C191.386566,89.024872 191.789505,89.036659 191.789505,89.036659 
	C191.805176,90.722969 191.820847,92.409271 191.445892,94.628799 
	C190.710419,96.781891 190.365585,98.401764 189.669800,99.957001 
	C185.506622,94.710518 185.237137,94.732491 181.470688,100.147507 
	C180.746292,101.188957 179.823761,102.092590 178.785217,103.067642 
	C178.577805,103.075478 178.162735,103.082527 178.082062,102.688667 
	C178.166840,100.477234 178.332321,98.659668 178.802460,96.792343 
	C178.994766,96.159805 178.882431,95.577019 178.708572,94.780807 
	C178.584442,94.280479 178.540054,93.990784 178.513885,93.698296 
z"/>
<path fill="#74B452" opacity="1.000000" stroke="none" 
	d="
M192.847076,74.794022 
	C190.937943,75.589249 189.102524,76.232040 187.212448,76.558823 
	C182.899445,77.304527 178.559692,77.895439 173.727203,78.826790 
	C172.102844,78.169830 170.982086,77.235321 169.861313,76.300812 
	C173.061203,74.867569 176.173782,72.552399 179.483109,72.224861 
	C183.596680,71.817696 187.872864,73.053329 192.428314,73.744720 
	C192.817215,74.106010 192.856598,74.302055 192.847076,74.794022 
z"/>
<path fill="#C7D564" opacity="1.000000" stroke="none" 
	d="
M190.020752,100.021645 
	C190.365585,98.401764 190.710419,96.781891 191.391510,95.046852 
	C191.817108,96.026054 191.906479,97.120415 192.080109,98.620270 
	C192.119247,100.409996 192.074127,101.794235 191.939087,103.789444 
	C191.701859,104.702881 191.474579,104.922569 190.861420,104.825043 
	C190.377289,103.067627 190.199020,101.544632 190.020752,100.021645 
z"/>
<path fill="#C7D564" opacity="1.000000" stroke="none" 
	d="
M191.892700,88.671860 
	C191.789505,89.036659 191.386566,89.024872 191.185242,89.011902 
	C191.132767,88.158318 191.281616,87.317703 191.669006,86.265060 
	C191.936981,86.804390 191.966431,87.555725 191.892700,88.671860 
z"/>
<path fill="#247649" opacity="1.000000" stroke="none" 
	d="
M112.856636,86.750290 
	C112.480560,86.984955 111.964081,86.966286 111.060760,86.918610 
	C110.873642,86.602882 111.073364,86.316162 111.521904,85.934082 
	C112.085892,86.058136 112.401062,86.277542 112.856636,86.750290 
z"/>
<path fill="#14813F" opacity="1.000000" stroke="none" 
	d="
M85.301468,147.042786 
	C84.985535,146.551575 84.987473,146.111862 85.243118,145.159012 
	C85.328766,143.763351 85.160721,142.880814 84.992668,141.998291 
	C85.063133,135.786682 86.372963,129.889496 89.923599,124.494812 
	C91.093773,125.970581 92.736122,127.809738 92.324509,128.743225 
	C89.978020,134.064774 90.324692,138.833313 93.701622,143.585129 
	C95.022530,145.443832 97.010338,148.019485 96.572495,149.723328 
	C94.991188,155.876785 99.638863,158.922638 102.146866,163.056259 
	C102.137894,163.252808 102.128922,163.449341 102.133163,163.940887 
	C102.146370,164.235870 101.935326,164.398346 101.811691,164.350647 
	C101.199066,164.238968 100.699295,164.074554 100.222664,164.125732 
	C90.323608,165.188843 86.369576,162.067581 85.230057,152.059158 
	C85.045700,150.439957 85.474159,148.750992 85.301468,147.042786 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M119.508446,153.934265 
	C120.473091,138.203201 121.535324,122.387344 123.243774,106.641602 
	C123.579315,103.549171 126.412323,100.727730 128.373505,97.356018 
	C128.887772,96.728394 129.035522,96.475517 129.447708,96.137848 
	C132.252106,94.125198 134.705566,92.146782 137.473190,90.037231 
	C146.470871,84.678062 155.154373,79.450035 163.802917,74.706909 
	C163.211990,78.134460 162.656021,81.077095 161.812012,83.776337 
	C161.171417,82.133759 160.818909,80.734566 160.103226,77.893875 
	C157.326721,79.911736 155.109146,81.487869 152.931824,83.117760 
	C151.382019,84.277924 149.919800,85.555954 148.359909,86.701622 
	C144.571091,89.484360 140.748093,92.220573 136.595032,95.031342 
	C134.153931,97.387276 132.056686,99.687401 129.719788,102.156731 
	C129.309174,102.875061 129.138199,103.424194 128.659424,104.141556 
	C127.561096,106.209335 126.770584,108.108879 125.979782,110.375122 
	C125.979240,111.473709 125.978989,112.205582 125.683380,113.161674 
	C124.590401,115.434692 123.381035,117.423859 123.073982,119.543724 
	C122.226051,125.397949 121.493095,131.296265 121.256096,137.198883 
	C121.152176,139.786880 122.364616,142.427719 122.979340,145.044571 
	C122.981331,146.742188 122.983330,148.439804 122.979324,150.561035 
	C121.926308,151.947769 120.879280,152.910873 119.508446,153.934265 
z"/>
<path fill="#247649" opacity="1.000000" stroke="none" 
	d="
M80.988594,149.354309 
	C81.378410,156.964310 82.770355,165.067093 78.144951,172.519455 
	C77.513786,173.536362 78.307693,175.437759 78.809082,177.003632 
	C75.670944,179.626465 72.165520,182.175293 68.419769,184.924744 
	C68.179443,185.125366 68.226181,185.147842 68.226181,185.147842 
	C70.786148,180.711746 72.916519,175.941513 76.031364,171.937027 
	C78.931129,168.209045 80.697624,164.548279 79.933319,159.417099 
	C80.183365,155.904663 80.518486,152.823242 80.988594,149.354309 
z"/>
<path fill="#CACFDC" opacity="1.000000" stroke="none" 
	d="
M112.868805,180.688873 
	C112.270821,180.868073 111.337219,180.977798 110.845413,180.563309 
	C107.875832,178.060638 104.964996,175.482468 102.153030,172.805084 
	C101.755287,172.426376 102.003998,171.368729 101.978256,170.306076 
	C104.088768,168.986725 106.174507,167.986618 108.622238,166.978973 
	C109.085098,168.576233 109.185959,170.181030 109.315086,172.235703 
	C115.715240,172.458649 112.816650,176.997925 112.868805,180.688873 
z"/>
<path fill="#247649" opacity="1.000000" stroke="none" 
	d="
M68.158829,185.139465 
	C68.138222,185.153564 68.108803,185.234695 68.167496,185.191269 
	C68.226181,185.147842 68.179443,185.125366 68.158829,185.139465 
z"/>
<path fill="#F2F9FB" opacity="1.000000" stroke="none" 
	d="
M84.122391,212.575989 
	C85.719734,211.710312 87.182892,211.160568 88.827316,210.324585 
	C89.661339,209.998093 90.314102,209.957825 91.206123,209.636169 
	C91.445374,209.354767 91.518822,209.315689 91.518822,209.315689 
	C91.518822,209.315689 91.453606,209.350403 91.421677,209.368896 
	C91.389748,209.387405 91.211372,209.788300 91.306030,210.358124 
	C95.972656,218.249069 100.544624,225.570190 105.116592,232.891312 
	C105.116585,232.891312 104.955574,233.355698 104.953743,233.601288 
	C102.499321,233.993225 100.046730,234.139542 97.293335,234.534912 
	C91.950966,234.825546 86.909401,234.867096 81.395950,234.970139 
	C77.425583,234.308823 73.873520,233.775177 70.445442,232.803925 
	C67.655350,232.013428 65.355904,230.441757 65.902023,226.495819 
	C67.230820,224.792511 68.475182,223.314835 69.988205,222.202652 
	C73.345291,219.734955 76.823631,217.430496 80.281410,215.102356 
	C81.472939,214.300079 82.750137,213.625061 84.122391,212.575989 
z"/>
<path fill="#2D3E90" opacity="1.000000" stroke="none" 
	d="
M233.564667,248.021545 
	C234.953751,249.976852 235.896439,251.920914 237.389496,255.000000 
	C230.540802,255.000000 224.331314,254.905685 218.126038,255.027069 
	C212.165344,255.143646 205.552765,253.220291 201.255737,260.554352 
	C203.052689,261.737000 205.071152,263.065460 207.818176,264.873383 
	C204.030701,265.324554 201.251816,265.909821 198.471298,265.917725 
	C195.488800,265.926208 190.950043,267.354980 192.588684,261.224976 
	C192.719162,260.736847 191.755447,259.391663 191.341934,259.409546 
	C186.974716,259.598328 182.108170,258.881470 178.385529,260.597351 
	C173.900375,262.664734 166.160904,264.138550 161.784454,261.468994 
	C160.284653,260.554108 158.743729,259.706635 155.929993,258.083160 
	C156.428864,260.709625 156.674240,262.001587 156.952087,263.662109 
	C155.320404,263.238495 153.656265,262.446259 152.129608,261.014160 
	C152.469864,258.788055 152.672638,257.201813 152.875412,255.615601 
	C151.120224,255.970917 149.199539,256.002838 147.664536,256.788391 
	C146.527405,257.370331 145.891708,258.932159 145.032623,260.057434 
	C136.449234,254.636429 127.870728,249.207657 119.277855,243.801712 
	C117.763565,242.849045 116.189201,241.991852 114.281197,241.071381 
	C113.919815,241.052185 113.943840,241.033051 113.857559,240.801682 
	C113.575783,240.289459 113.313805,240.100998 112.985352,240.004898 
	C112.985352,240.004898 113.001259,240.000732 112.995102,239.641418 
	C111.324280,236.521286 109.659615,233.760452 108.360527,230.960892 
	C111.770515,229.320465 113.912247,230.869812 116.302216,232.676590 
	C118.715431,234.500961 121.472443,235.996368 124.281151,237.138245 
	C127.405037,238.408234 131.161636,238.469925 133.928177,240.200211 
	C140.159348,244.097397 146.578674,246.579987 154.000610,246.002441 
	C155.283813,252.108871 159.926727,249.323395 164.515076,249.180466 
	C163.216644,251.407654 162.361084,252.875183 161.265274,254.754807 
	C164.918884,254.608948 166.477783,252.788605 167.144714,249.133850 
	C167.868149,248.012726 168.433792,247.506363 169.373337,246.999115 
	C170.494690,246.996414 171.242142,246.994598 172.410400,247.005646 
	C178.962814,247.679214 185.083344,248.539658 191.232681,248.880692 
	C193.114441,248.985062 195.075256,247.663986 196.999863,246.995697 
	C208.951553,250.458542 221.044388,248.896347 233.564667,248.021545 
z"/>
<path fill="#34538F" opacity="1.000000" stroke="none" 
	d="
M145.251495,260.315491 
	C145.891708,258.932159 146.527405,257.370331 147.664536,256.788391 
	C149.199539,256.002838 151.120224,255.970917 152.875412,255.615601 
	C152.672638,257.201813 152.469864,258.788055 152.122406,260.693298 
	C149.808594,260.866089 147.639481,260.719818 145.251495,260.315491 
z"/>
<path fill="#306AA2" opacity="1.000000" stroke="none" 
	d="
M262.256500,84.146149 
	C261.955902,83.194908 261.962036,82.398453 261.953766,81.201172 
	C272.883423,90.441002 281.904053,101.559769 288.168884,115.177063 
	C289.152069,119.718552 290.069550,123.929787 290.726929,128.553528 
	C290.291290,128.971924 290.115723,128.977814 289.606079,128.836334 
	C286.229706,127.992607 283.163879,127.318108 283.993896,123.017776 
	C283.811920,120.642052 283.664612,118.263054 283.440613,115.891289 
	C283.005188,111.280106 282.693054,106.774300 278.100830,103.772507 
	C276.209900,102.536469 275.193634,99.962364 273.405823,97.979584 
	C272.166199,96.289696 271.546997,94.416367 270.383209,92.986130 
	C267.927551,89.968201 265.186951,87.182137 262.256500,84.146149 
z"/>
<path fill="#165891" opacity="1.000000" stroke="none" 
	d="
M245.266830,88.973221 
	C245.160782,87.879074 245.054718,86.784935 244.990448,84.979782 
	C245.030563,83.175301 245.028900,82.081825 245.042999,80.531898 
	C245.023193,76.725494 244.987640,73.375549 245.081833,69.795471 
	C245.494171,69.359444 245.776779,69.153549 246.059372,68.947655 
	C247.780396,70.063843 249.501419,71.180023 251.400894,72.602501 
	C251.124695,75.275558 250.375000,77.632202 250.293427,80.011757 
	C250.200760,82.715042 250.705521,85.438820 250.516022,88.587273 
	C248.476578,89.004982 246.871704,88.989098 245.266830,88.973221 
z"/>
<path fill="#226AB1" opacity="1.000000" stroke="none" 
	d="
M246.006500,68.613464 
	C245.776779,69.153549 245.494171,69.359444 244.708496,69.742310 
	C242.473312,69.631104 240.741180,69.342934 239.009064,69.054764 
	C238.999878,67.979248 238.990677,66.903725 238.970032,65.425888 
	C239.107208,61.856289 238.599915,58.421036 242.945938,56.163639 
	C243.631134,59.975388 244.238342,63.353310 245.188019,66.862556 
	C245.671509,67.422348 245.812561,67.850815 246.006500,68.613464 
z"/>
<path fill="#2551A1" opacity="1.000000" stroke="none" 
	d="
M74.651550,236.019714 
	C74.469833,237.813263 73.885788,239.587448 73.163445,241.685425 
	C70.959351,241.666656 68.893547,241.324097 66.415909,240.606506 
	C66.005783,238.150131 66.007492,236.068817 66.009193,233.987488 
	C68.755867,234.658447 71.502548,235.329391 74.651550,236.019714 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M65.866920,233.671082 
	C66.007492,236.068817 66.005783,238.150131 66.018532,240.593964 
	C64.438553,239.551620 62.333717,238.410980 61.423332,236.652100 
	C60.781879,235.412796 61.762833,233.333740 62.019398,231.629623 
	C63.254482,232.204636 64.489563,232.779648 65.866920,233.671082 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M93.585739,236.047272 
	C93.997917,238.072800 93.999191,240.090927 93.999031,242.541824 
	C89.953850,242.554214 85.910103,242.133820 81.866364,241.713440 
	C81.627693,241.278152 81.389015,240.842880 81.150345,240.407593 
	C83.231438,239.272354 85.224785,237.905045 87.421097,237.074493 
	C89.213158,236.396805 91.247765,236.360519 93.585739,236.047272 
z"/>
<path fill="#364782" opacity="1.000000" stroke="none" 
	d="
M105.338837,232.928406 
	C100.544624,225.570190 95.972656,218.249069 91.420593,210.545227 
	C92.383774,210.629364 93.615608,210.859848 94.223213,211.601654 
	C98.018280,216.234802 101.673851,220.982193 105.685822,226.246506 
	C106.005135,227.852051 106.014252,228.902832 106.013809,230.339233 
	C106.004547,231.497238 106.004852,232.269638 106.005157,233.042053 
	C106.005157,233.042053 105.561073,232.965485 105.338837,232.928406 
z"/>
<path fill="#204082" opacity="1.000000" stroke="none" 
	d="
M106.130035,233.346100 
	C106.004852,232.269638 106.004547,231.497238 106.266373,230.459473 
	C107.017326,230.462616 107.506142,230.731125 107.994957,230.999634 
	C109.659615,233.760452 111.324280,236.521286 112.989822,239.648514 
	C110.745445,237.893326 108.500183,235.771729 106.130035,233.346100 
z"/>
<path fill="#204082" opacity="1.000000" stroke="none" 
	d="
M113.070572,240.239334 
	C113.313805,240.100998 113.575783,240.289459 113.867981,240.796112 
	C113.621315,240.948013 113.351685,240.765305 113.070572,240.239334 
z"/>
<path fill="#2551A1" opacity="1.000000" stroke="none" 
	d="
M289.940125,128.983719 
	C290.115723,128.977814 290.291290,128.971924 290.728943,128.938416 
	C292.664917,135.994263 294.338776,143.077713 296.010559,150.573395 
	C295.383881,151.439941 294.369293,151.779510 294.196442,152.366806 
	C292.856140,156.920914 292.233643,161.772293 290.312897,166.048325 
	C287.778992,171.689499 284.565704,177.092773 281.102936,182.233536 
	C276.034668,189.757706 270.635956,197.074661 265.073456,204.243546 
	C262.593750,207.439362 259.757446,210.900970 254.355728,208.594925 
	C254.001022,206.077682 254.005890,204.034866 254.378357,201.989609 
	C256.661987,200.327682 258.616730,198.710632 260.487457,197.001541 
	C269.414368,188.845795 275.457794,178.701889 280.058136,167.701202 
	C282.909027,160.884003 284.355286,153.383682 287.850403,146.961960 
	C290.631775,141.851624 288.651276,136.534302 290.595581,131.660934 
	C290.643250,131.541458 290.165375,131.212296 289.946472,130.648438 
	C289.953644,129.871887 289.946869,129.427795 289.940125,128.983719 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M236.999390,326.995605 
	C230.284485,327.662537 223.569565,328.329468 216.426544,328.666138 
	C215.996307,327.893829 215.994186,327.451813 216.359207,326.848022 
	C225.843277,324.782593 235.007156,323.068756 244.041931,320.833130 
	C246.131027,320.316193 247.778610,318.015045 249.629364,316.534912 
	C248.224930,314.930542 247.051712,312.988525 245.347717,311.821564 
	C243.801880,310.762878 241.633667,310.659760 239.818008,309.938599 
	C235.850845,308.362885 231.933304,306.662201 227.995499,305.012573 
	C231.069504,302.803009 234.150467,304.670502 237.832275,305.284149 
	C239.648178,306.052887 240.848618,306.567566 242.075134,307.009918 
	C247.004730,308.787933 252.025146,310.351074 256.829803,312.417328 
	C258.400513,313.092773 259.417725,315.055481 260.688049,316.429474 
	C259.274170,317.707520 258.069275,319.524200 256.409363,320.168121 
	C250.018326,322.647491 243.481720,324.751587 236.999390,326.995605 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M237.260895,327.280762 
	C243.481720,324.751587 250.018326,322.647491 256.409363,320.168121 
	C258.069275,319.524200 259.274170,317.707520 260.688049,316.429474 
	C259.417725,315.055481 258.400513,313.092773 256.829803,312.417328 
	C252.025146,310.351074 247.004730,308.787933 242.075134,307.009918 
	C240.848618,306.567566 239.648178,306.052887 238.217606,305.283447 
	C238.928879,304.473022 240.017441,303.331482 240.763306,303.523224 
	C248.868591,305.606659 256.921783,307.893219 264.983185,310.145844 
	C265.616852,310.322937 266.356506,310.511261 266.789490,310.949005 
	C268.805237,312.986877 270.730408,315.114349 272.687988,317.209778 
	C270.507599,318.503418 268.476227,320.294342 266.119781,321.000549 
	C257.945160,323.450409 249.669357,325.569305 241.399979,327.688995 
	C240.197006,327.997345 238.819260,327.623840 237.260895,327.280762 
z"/>
<path fill="#E0E0E1" opacity="1.000000" stroke="none" 
	d="
M227.746780,305.007141 
	C231.933304,306.662201 235.850845,308.362885 239.818008,309.938599 
	C241.633667,310.659760 243.801880,310.762878 245.347717,311.821564 
	C247.051712,312.988525 248.224930,314.930542 249.629364,316.534912 
	C247.778610,318.015045 246.131027,320.316193 244.041931,320.833130 
	C235.007156,323.068756 225.843277,324.782593 215.985657,326.846375 
	C214.497421,327.014343 213.749893,327.022247 212.999329,326.776855 
	C212.993057,326.354736 212.989853,326.185913 213.362061,325.943604 
	C220.001938,324.243622 226.381607,322.948364 232.474731,320.829224 
	C234.944870,319.970093 236.790497,317.315399 238.920303,315.477722 
	C237.348450,314.016815 235.998871,312.020569 234.140778,311.248962 
	C232.362915,310.510742 230.056458,311.045502 227.632629,311.016357 
	C226.185760,310.677887 225.088608,310.343781 223.993469,309.757263 
	C223.996994,309.336548 223.998489,309.168274 224.041397,308.677399 
	C225.055389,307.234558 226.027985,306.114319 227.000565,304.994049 
	C227.000565,304.994049 227.498062,305.001740 227.746780,305.007141 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M226.833496,304.748474 
	C226.027985,306.114319 225.055389,307.234558 223.686798,308.637665 
	C218.946014,308.280060 214.610001,307.168427 210.254822,307.087402 
	C197.173798,306.844116 184.085297,307.002869 170.846710,306.760498 
	C170.129501,306.013245 169.565277,305.510315 169.292786,304.709839 
	C171.372040,304.275269 173.161957,304.163574 174.946579,303.995728 
	C178.299332,303.680359 181.649033,303.332520 185.000000,302.998169 
	C187.370560,302.998505 189.741104,302.998840 192.848724,303.295135 
	C198.723846,303.393463 203.861923,303.195831 209.000000,302.998169 
	C211.699509,302.998383 214.399033,302.998566 217.905609,303.035828 
	C220.808258,303.379883 222.903854,303.686829 224.999451,303.993774 
	C225.555099,304.163483 226.110748,304.333191 226.833496,304.748474 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M212.986633,326.017090 
	C212.989853,326.185913 212.993057,326.354736 212.906952,327.167603 
	C208.544601,328.208557 204.271561,328.605499 199.998520,329.002441 
	C197.956589,329.001678 195.914642,329.000916 193.436096,328.669189 
	C192.999466,327.896210 192.999435,327.454193 193.000061,326.758789 
	C193.000488,326.336456 193.000244,326.167603 193.464264,325.998322 
	C198.954193,325.329071 203.979843,324.660248 209.337891,323.994568 
	C210.113495,324.002441 210.556686,324.007172 211.117065,324.278534 
	C211.818375,325.035797 212.402512,325.526459 212.986633,326.017090 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M184.706635,302.702515 
	C181.649033,303.332520 178.299332,303.680359 174.946579,303.995728 
	C173.161957,304.163574 171.372040,304.275269 168.959930,304.708130 
	C167.891663,305.003998 167.447968,305.004089 166.650253,304.997101 
	C165.529541,304.327911 164.762863,303.665802 163.996170,303.003662 
	C170.801865,302.804718 177.607559,302.605774 184.706635,302.702515 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M163.622375,303.003601 
	C164.762863,303.665802 165.529541,304.327911 166.649170,305.330841 
	C167.001938,306.116821 167.001770,306.562012 166.602386,307.006409 
	C165.139099,307.009338 164.075027,307.013031 162.556183,307.011719 
	C159.067398,307.004089 156.033386,307.001434 152.999390,306.998779 
	C154.119858,306.333374 155.181183,305.520813 156.376083,305.040649 
	C157.803879,304.466888 159.345932,304.177429 160.922272,303.385437 
	C161.753555,303.005249 162.501083,303.004395 163.622375,303.003601 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M200.088959,329.396423 
	C204.271561,328.605499 208.544601,328.208557 212.910004,327.420898 
	C213.749893,327.022247 214.497421,327.014343 215.618500,327.008118 
	C215.994186,327.451813 215.996307,327.893829 215.999512,328.667480 
	C210.843964,331.603546 205.581406,332.090973 200.088959,329.396423 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M208.706604,302.702545 
	C203.861923,303.195831 198.723846,303.393463 193.292877,303.294678 
	C198.137741,302.801147 203.275467,302.604004 208.706604,302.702545 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M177.445282,329.001770 
	C176.360550,328.552643 175.719879,328.102814 175.373779,327.326782 
	C176.445724,326.667603 177.223145,326.334564 178.437988,326.000031 
	C180.916946,325.998596 182.958481,325.998688 184.999634,326.252136 
	C184.999710,326.674408 185.000168,326.843292 185.000916,327.343689 
	C185.000916,328.117218 185.000626,328.559204 185.000336,329.001221 
	C182.630005,329.001160 180.259659,329.001129 177.445282,329.001770 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M185.267151,329.284912 
	C185.000626,328.559204 185.000916,328.117218 185.444977,327.341370 
	C188.258972,327.009094 190.629181,327.010651 192.999390,327.012207 
	C192.999435,327.454193 192.999466,327.896210 192.999573,328.669739 
	C190.511093,329.190369 188.022522,329.379517 185.267151,329.284912 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M224.795349,303.716583 
	C222.903854,303.686829 220.808258,303.379883 218.355469,303.035217 
	C220.503860,298.550140 222.433762,302.692841 224.795349,303.716583 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M159.007629,326.990204 
	C157.290146,326.993988 155.572662,326.997772 153.425766,327.003784 
	C152.552719,327.004669 152.109116,327.003235 151.187744,326.775970 
	C150.139801,326.699188 149.569595,326.848236 148.999390,326.997253 
	C147.934418,326.996765 146.869446,326.996277 145.405334,326.994873 
	C143.887482,326.329926 142.842224,325.451782 141.637512,325.038666 
	C136.086365,323.135101 130.454620,321.461517 124.935623,319.472717 
	C123.490372,318.951965 122.345818,317.596771 120.251259,316.009552 
	C128.987610,308.424194 138.687103,306.672058 149.474686,307.370270 
	C147.787643,308.495453 145.750275,309.410858 143.619858,309.974670 
	C139.022308,311.191345 133.511627,310.558167 131.038025,316.594513 
	C135.260391,321.220795 140.982529,321.826141 146.536621,323.076019 
	C150.772705,324.029266 154.856232,325.660553 159.007629,326.990204 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M149.144440,327.223511 
	C149.569595,326.848236 150.139801,326.699188 150.854996,326.776276 
	C150.429825,327.151520 149.859650,327.300629 149.144440,327.223511 
z"/>
<path fill="#E0E0E1" opacity="1.000000" stroke="none" 
	d="
M159.456833,326.991852 
	C154.856232,325.660553 150.772705,324.029266 146.536621,323.076019 
	C140.982529,321.826141 135.260391,321.220795 131.038025,316.594513 
	C133.511627,310.558167 139.022308,311.191345 143.619858,309.974670 
	C145.750275,309.410858 147.787643,308.495453 149.936401,307.372803 
	C150.753998,307.004608 151.502426,307.003113 152.625122,307.000183 
	C156.033386,307.001434 159.067398,307.004089 162.478226,307.381104 
	C162.041229,308.171234 161.270142,308.780426 160.406616,308.971191 
	C155.127289,310.137390 149.774857,311.017731 144.568466,312.438995 
	C143.181717,312.817566 141.135162,314.958466 141.316208,315.932495 
	C141.595901,317.437225 143.296448,319.141113 144.818542,319.850739 
	C147.586746,321.141327 150.615784,322.230042 153.627899,322.557648 
	C158.379105,323.074402 163.206314,322.892609 168.374985,323.005554 
	C169.495682,323.007935 170.242722,323.011292 171.069183,323.385803 
	C173.432571,324.505096 175.716568,325.253296 178.000549,326.001526 
	C177.223145,326.334564 176.445724,326.667603 175.328796,327.007538 
	C173.603531,327.009552 172.217758,327.004639 170.270462,326.773010 
	C169.139282,326.695526 168.569641,326.844788 167.999985,326.994019 
	C165.302002,326.993835 162.604019,326.993683 159.456833,326.991852 
z"/>
<path fill="#E7E8E8" opacity="1.000000" stroke="none" 
	d="
M168.144684,327.221008 
	C168.569641,326.844788 169.139282,326.695526 169.854462,326.771301 
	C169.429794,327.146881 168.859573,327.297455 168.144684,327.221008 
z"/>
<path fill="#FBFBFC" opacity="1.000000" stroke="none" 
	d="
M190.967041,132.936325 
	C192.731827,133.341156 195.804123,134.488449 196.060593,134.036804 
	C198.843094,129.136749 205.838150,127.404480 206.354630,121.080627 
	C206.793716,115.704399 206.752625,110.235207 206.293854,104.860947 
	C206.045654,101.953430 205.263443,98.993996 200.874771,98.434875 
	C199.326538,98.237633 198.278091,94.117683 197.001343,91.394058 
	C199.729279,90.847450 202.117706,90.928223 203.775864,93.911392 
	C204.437958,95.102554 206.777603,95.742348 208.431702,95.924713 
	C212.330063,96.354492 213.775803,98.408905 214.065155,102.152153 
	C214.404602,106.543442 216.017899,110.955986 215.677368,115.248131 
	C215.298660,120.021461 213.396103,124.685402 212.043564,129.364197 
	C211.516785,131.186493 210.353363,132.864700 210.046158,134.703156 
	C209.080658,140.481079 205.451233,144.284851 199.848373,145.018387 
	C199.046021,145.123428 197.989807,145.119827 197.514236,145.613052 
	C191.520416,151.829498 183.551620,151.722412 175.970520,151.816330 
	C171.162933,151.875900 166.338165,150.126755 161.512100,149.237595 
	C156.991348,148.404663 156.373108,144.352158 154.362030,141.071625 
	C153.248917,141.002548 152.499680,141.000824 151.410095,140.659134 
	C151.898407,136.920258 154.172607,138.907104 156.158371,139.022812 
	C156.375168,139.034088 156.807968,139.025055 156.878021,139.296432 
	C156.956696,139.754486 156.965302,139.941147 157.024857,140.475235 
	C159.045685,141.208252 161.235123,142.336487 162.941757,141.831863 
	C167.072891,140.610306 170.978928,138.627563 175.155426,137.285553 
	C180.102158,140.121826 181.900391,139.573929 184.370499,134.973114 
	C185.466110,134.967453 186.196548,134.966446 187.289703,134.972824 
	C188.436005,134.313370 189.219574,133.646530 190.244171,132.970245 
	C190.485214,132.960800 190.967041,132.936325 190.967041,132.936325 
M190.551468,141.981918 
	C191.644684,140.828949 192.737900,139.675980 193.831116,138.523010 
	C192.898224,137.748032 191.699646,136.163345 191.086990,136.364365 
	C189.755920,136.801117 188.509033,138.008347 187.713516,139.219635 
	C187.501678,139.542160 189.048096,141.019424 190.551468,141.981918 
z"/>
<path fill="#1C4483" opacity="1.000000" stroke="none" 
	d="
M254.010742,201.992065 
	C254.005890,204.034866 254.001022,206.077682 254.000214,208.557907 
	C253.709518,211.395279 253.414780,213.795227 253.084595,216.589859 
	C252.037872,216.998489 251.026550,217.012436 249.637787,217.043976 
	C249.058090,213.659134 248.855835,210.256699 248.570877,206.441895 
	C248.318039,202.683563 248.147934,199.337631 247.997803,195.107498 
	C247.697723,190.468781 247.377655,186.714264 247.073578,182.545654 
	C246.735428,180.084167 246.381271,178.036743 246.027100,175.989334 
	C246.027100,174.286530 246.027100,172.583725 246.019531,170.444641 
	C246.011978,170.008347 246.011429,170.034134 246.231644,169.926086 
	C246.732971,169.620529 246.911194,169.354172 246.998199,168.604492 
	C247.010437,166.812775 247.010986,165.435562 247.011520,164.058334 
	C248.735306,164.034744 250.459076,164.011154 252.965469,163.941437 
	C255.502411,163.340317 257.256744,162.785278 259.416626,162.197937 
	C261.562073,161.780243 263.302002,161.394867 265.419891,160.967468 
	C266.809082,157.296539 267.820282,153.667633 268.831512,150.038727 
	C269.278809,148.617981 269.726074,147.197220 270.183411,145.397079 
	C270.792908,142.621704 271.392365,140.225723 272.008362,137.442230 
	C272.693176,134.980621 273.361450,132.906540 274.060913,130.453781 
	C274.506256,130.062943 274.920349,130.050781 275.692047,130.394012 
	C277.005310,133.493744 277.960999,136.238083 278.646393,139.262146 
	C277.512817,144.416611 276.919769,149.359497 275.712158,154.147369 
	C274.445465,159.169601 272.589539,164.043213 270.554230,168.982666 
	C264.620239,169.694778 259.123138,170.406006 254.288727,171.031494 
	C253.842026,176.980026 253.343384,181.886719 253.166367,186.804977 
	C253.092545,188.856598 253.703110,190.932846 253.993774,193.447815 
	C253.993637,196.595840 254.002197,199.293961 254.010742,201.992065 
z"/>
<path fill="#235DA8" opacity="1.000000" stroke="none" 
	d="
M254.378357,201.989609 
	C254.002197,199.293961 253.993637,196.595840 254.359161,193.443390 
	C257.511810,190.613083 260.649811,188.540131 262.974976,185.781921 
	C265.683807,182.568634 266.937317,177.622589 270.178741,175.493866 
	C274.117218,172.907410 272.589691,171.367310 270.991150,168.981781 
	C272.589539,164.043213 274.445465,159.169601 275.712158,154.147369 
	C276.919769,149.359497 277.512817,144.416611 279.009460,139.215836 
	C282.596985,137.486786 283.377228,135.026474 282.993896,132.000198 
	C283.489868,131.736252 283.985809,131.472305 285.153564,131.100586 
	C287.194397,130.988861 288.563507,130.984894 289.932587,130.980927 
	C290.165375,131.212296 290.643250,131.541458 290.595581,131.660934 
	C288.651276,136.534302 290.631775,141.851624 287.850403,146.961960 
	C284.355286,153.383682 282.909027,160.884003 280.058136,167.701202 
	C275.457794,178.701889 269.414368,188.845795 260.487457,197.001541 
	C258.616730,198.710632 256.661987,200.327682 254.378357,201.989609 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M289.946472,130.648438 
	C288.563507,130.984894 287.194397,130.988861 285.412781,130.990524 
	C283.822083,129.911316 282.551025,128.917801 281.490356,127.735504 
	C280.278351,126.384529 279.253571,124.865601 278.147430,123.419647 
	C279.839142,123.251633 281.530884,123.083618 283.608246,122.966690 
	C283.163879,127.318108 286.229706,127.992607 289.606079,128.836334 
	C289.946869,129.427795 289.953644,129.871887 289.946472,130.648438 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M282.631409,131.937820 
	C283.377228,135.026474 282.596985,137.486786 279.279785,138.936096 
	C277.960999,136.238083 277.005310,133.493744 276.003479,130.388702 
	C278.061157,130.643799 280.165039,131.259628 282.631409,131.937820 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M191.597870,197.031479 
	C192.019257,201.379608 192.017563,205.727341 191.586121,210.540421 
	C189.548721,211.249893 187.941818,211.689255 186.333237,211.695526 
	C183.420380,211.706894 180.270309,212.088501 177.671600,211.111908 
	C175.768448,210.396683 174.588882,207.756058 173.065948,205.557037 
	C173.085281,203.750488 173.125443,202.360519 173.165604,200.970566 
	C176.119583,199.982025 179.018066,198.740570 182.043289,198.076645 
	C185.020248,197.423340 188.125717,197.355713 191.597870,197.031479 
z"/>
<path fill="#1C4483" opacity="1.000000" stroke="none" 
	d="
M173.118942,200.558243 
	C173.125443,202.360519 173.085281,203.750488 173.033081,205.987457 
	C173.339417,209.892059 173.657761,212.949631 173.971436,216.404449 
	C173.988434,217.860153 174.010086,218.918640 174.018219,220.395660 
	C174.003540,222.210312 174.002411,223.606430 173.848633,225.293839 
	C172.194473,226.366776 170.692978,227.148422 169.191483,227.930054 
	C169.191483,227.930054 169.027161,227.489182 168.943207,227.269623 
	C168.826675,225.012207 168.794113,222.974350 168.677139,220.506897 
	C168.393036,216.688324 168.193359,213.299332 167.995789,209.094666 
	C167.991745,207.519257 167.985565,206.759521 167.979401,205.999786 
	C167.971130,203.975967 167.962860,201.952148 167.970490,199.150787 
	C168.001343,197.914734 168.016281,197.456238 168.031219,196.997711 
	C169.406815,196.694763 170.782410,196.391815 172.505432,196.045776 
	C172.925995,197.383774 172.999146,198.764847 173.118942,200.558243 
z"/>
<path fill="#1C4483" opacity="1.000000" stroke="none" 
	d="
M172.883850,193.606735 
	C169.759918,192.461838 170.471451,189.292252 172.313324,188.712036 
	C178.174072,186.865891 184.342239,185.995712 190.750549,184.917114 
	C186.626251,186.462082 182.058014,187.620255 177.700470,189.318878 
	C175.883331,190.027237 174.514633,191.886002 172.883850,193.606735 
z"/>
<path fill="#264B9C" opacity="1.000000" stroke="none" 
	d="
M165.983246,173.957626 
	C172.721939,172.124435 179.439835,170.203568 186.231186,168.592346 
	C186.917648,168.429489 188.479355,169.972351 188.872086,171.011841 
	C189.486755,172.638733 189.450073,174.511734 189.846375,176.644928 
	C185.931564,178.331497 181.859711,179.653595 177.535614,180.653717 
	C177.946060,175.636932 175.927521,173.624237 171.516632,174.232346 
	C169.718964,174.480179 167.830429,174.068985 165.983246,173.957626 
z"/>
<path fill="#2A3F95" opacity="1.000000" stroke="none" 
	d="
M158.055664,193.025146 
	C158.046173,187.644226 158.036682,182.263321 158.049683,176.407700 
	C158.784149,175.964005 159.496109,175.995010 160.593323,176.391571 
	C162.986847,178.850082 164.995132,180.943039 167.003403,183.035995 
	C167.332504,186.082886 167.661606,189.129776 167.574158,192.590546 
	C164.123596,193.011337 161.089630,193.018250 158.055664,193.025146 
z"/>
<path fill="#235DA8" opacity="1.000000" stroke="none" 
	d="
M167.411560,183.033340 
	C164.995132,180.943039 162.986847,178.850082 160.950592,176.401306 
	C161.426620,175.442825 161.930664,174.840164 162.700287,174.103973 
	C163.720779,173.974335 164.475647,173.978226 165.606888,173.969879 
	C167.830429,174.068985 169.718964,174.480179 171.516632,174.232346 
	C175.927521,173.624237 177.946060,175.636932 177.140045,180.657990 
	C173.937698,181.666382 170.878723,182.348526 167.411560,183.033340 
z"/>
<path fill="#2551A1" opacity="1.000000" stroke="none" 
	d="
M158.042267,193.425049 
	C161.089630,193.018250 164.123596,193.011337 167.570587,193.008591 
	C167.992676,194.075348 168.001785,195.137970 168.021057,196.599152 
	C168.016281,197.456238 168.001343,197.914734 167.960419,198.717529 
	C165.236191,199.041183 162.537949,199.020554 159.382874,198.999573 
	C158.626968,197.274445 158.327927,195.549698 158.042267,193.425049 
z"/>
<path fill="#1C4483" opacity="1.000000" stroke="none" 
	d="
M245.196350,89.415611 
	C246.871704,88.989098 248.476578,89.004982 250.479919,89.038231 
	C251.241104,95.747177 251.603806,102.438751 251.950897,109.562897 
	C249.956100,109.370331 247.976929,108.745186 245.582886,107.635696 
	C245.081055,105.449135 244.994125,103.746902 244.907196,102.044678 
	C244.953690,100.653160 245.000183,99.261635 244.996063,97.455627 
	C245.005585,94.646759 245.065720,92.252388 245.196350,89.415611 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M169.138000,228.335159 
	C170.692978,227.148422 172.194473,226.366776 174.201752,225.353149 
	C175.495590,226.669434 175.940720,228.604111 177.150192,229.677567 
	C179.072922,231.384003 181.372711,233.252136 183.760071,233.683075 
	C186.132935,234.111404 191.769852,226.819489 192.350388,224.023071 
	C193.148621,224.031174 193.604996,224.043228 194.061371,224.055283 
	C194.509933,227.518463 194.958481,230.981659 195.692383,234.728455 
	C195.972183,236.069946 195.966629,237.127792 195.796890,238.681549 
	C195.738815,239.460037 195.844910,239.742599 195.951019,240.025162 
	C195.962036,241.074951 195.973053,242.124756 195.711288,243.351868 
	C194.191803,242.365173 193.013565,240.305099 191.685623,240.203583 
	C187.545502,239.887085 182.916351,239.240982 179.278992,240.709534 
	C174.288055,242.724548 171.125168,241.562485 168.000000,238.006271 
	C167.727371,237.369110 167.454742,236.731964 166.867233,235.912506 
	C167.396408,233.400223 168.240448,231.070251 169.138000,228.335159 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M168.000107,238.002945 
	C171.125168,241.562485 174.288055,242.724548 179.278992,240.709534 
	C182.916351,239.240982 187.545502,239.887085 191.685623,240.203583 
	C193.013565,240.305099 194.191803,242.365173 195.707504,243.745544 
	C196.233154,244.775024 196.489807,245.588135 196.873169,246.698471 
	C195.075256,247.663986 193.114441,248.985062 191.232681,248.880692 
	C185.083344,248.539658 178.962814,247.679214 172.405914,246.640839 
	C169.987579,244.504395 167.994568,242.745636 166.001541,240.986893 
	C166.001541,240.986893 166.002380,240.510040 166.323074,240.218781 
	C167.097977,239.284805 167.552170,238.642075 168.006378,237.999329 
	C168.006393,237.999344 168.000214,237.999619 168.000107,238.002945 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M195.960510,239.763519 
	C195.844910,239.742599 195.738815,239.460037 195.789230,239.078125 
	C195.953842,239.153168 195.961929,239.327515 195.960510,239.763519 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M247.003510,163.700333 
	C247.010986,165.435562 247.010437,166.812775 246.779587,168.712799 
	C246.275772,169.438293 246.096481,169.704468 246.011429,170.034134 
	C246.011429,170.034134 246.011978,170.008347 246.027405,170.019165 
	C243.957199,169.687988 241.871582,169.345963 239.388763,168.989471 
	C239.308792,165.386719 237.312469,160.289886 244.626572,160.976990 
	C245.700150,161.759277 246.347824,162.550797 247.003510,163.700333 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M247.057587,182.959747 
	C247.377655,186.714264 247.697723,190.468781 247.955429,194.682480 
	C247.437744,196.329941 247.318314,198.005325 246.454391,198.601059 
	C244.850952,199.706757 242.849869,200.476746 240.930344,200.718063 
	C240.434631,200.780365 239.246338,198.414932 239.047592,197.065796 
	C238.714981,194.807983 238.922363,192.470612 238.923691,189.740051 
	C238.971146,188.167892 238.997375,187.022400 239.266083,185.624390 
	C240.012955,185.768402 240.750702,186.613403 240.985214,186.491364 
	C243.060074,185.411652 245.045639,184.160355 247.057587,182.959747 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M259.011078,162.230255 
	C257.256744,162.785278 255.502411,163.340317 253.395172,163.907257 
	C252.113327,161.142761 251.184402,158.366348 250.120392,155.186218 
	C251.682266,154.624146 253.938126,153.812317 256.589783,153.363388 
	C257.660767,156.560944 258.335938,159.395615 259.011078,162.230255 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M259.416626,162.197952 
	C258.335938,159.395615 257.660767,156.560944 256.965210,153.356277 
	C257.402618,152.985123 257.860413,152.983978 259.027435,152.977814 
	C259.980743,152.962204 260.224884,152.951614 260.468994,152.941025 
	C260.771271,152.913559 261.067383,152.944687 261.649048,153.403107 
	C262.974487,156.184387 264.008209,158.596924 265.041901,161.009476 
	C263.302002,161.394867 261.562073,161.780243 259.416626,162.197952 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M260.284241,152.700226 
	C260.224884,152.951614 259.980743,152.962204 259.370209,152.977142 
	C259.011230,152.789703 259.018677,152.597900 259.052246,152.118256 
	C259.418762,152.040100 259.759094,152.249771 260.284241,152.700226 
z"/>
<path fill="#349AD0" opacity="1.000000" stroke="none" 
	d="
M245.027237,80.988358 
	C245.028900,82.081825 245.030563,83.175301 244.968018,84.676697 
	C242.489136,86.310326 241.997040,90.590919 238.027832,89.090668 
	C237.994476,86.473030 237.990631,84.158165 238.366669,81.413010 
	C240.076981,81.474800 241.407425,81.966873 242.712357,82.449516 
	C243.257843,82.105202 244.142548,81.546783 245.027237,80.988358 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M247.073608,182.545670 
	C245.045639,184.160355 243.060074,185.411652 240.985214,186.491364 
	C240.750702,186.613403 240.012955,185.768402 239.271011,185.201340 
	C239.339066,182.421021 239.506149,179.784332 240.005035,177.212006 
	C240.280243,175.793106 241.112411,174.482239 241.693451,173.122681 
	C242.894455,174.040298 244.095444,174.957901 245.661774,175.932434 
	C246.381271,178.036743 246.735428,180.084167 247.073608,182.545670 
z"/>
<path fill="#A8DAEF" opacity="1.000000" stroke="none" 
	d="
M244.631760,102.256088 
	C244.994125,103.746902 245.081055,105.449135 245.240311,107.572449 
	C245.246170,108.176231 245.179733,108.358910 245.020691,108.819176 
	C241.254425,110.456062 238.094635,110.604645 237.021973,105.390877 
	C239.444199,104.173195 241.900253,103.320343 244.631760,102.256088 
z"/>
<path fill="#A8DAEF" opacity="1.000000" stroke="none" 
	d="
M265.419891,160.967468 
	C264.008209,158.596924 262.974487,156.184387 261.872955,153.417221 
	C263.943848,152.090500 266.082611,151.118393 268.526428,150.092499 
	C267.820282,153.667633 266.809082,157.296539 265.419891,160.967468 
z"/>
<path fill="#278DC9" opacity="1.000000" stroke="none" 
	d="
M245.042999,80.531898 
	C244.142548,81.546783 243.257843,82.105202 242.712357,82.449516 
	C241.407425,81.966873 240.076981,81.474800 238.377548,80.971451 
	C238.342667,77.277115 238.676804,73.594048 239.010010,69.482872 
	C240.741180,69.342934 242.473312,69.631104 244.578766,69.972443 
	C244.987640,73.375549 245.023193,76.725494 245.042999,80.531898 
z"/>
<path fill="#226AB1" opacity="1.000000" stroke="none" 
	d="
M173.976105,216.007217 
	C173.657761,212.949631 173.339417,209.892059 173.053925,206.404053 
	C174.588882,207.756058 175.768448,210.396683 177.671600,211.111908 
	C180.270309,212.088501 183.420380,211.706894 186.333237,211.695526 
	C187.941818,211.689255 189.548721,211.249893 191.591919,211.005264 
	C192.522858,211.573654 193.018219,212.142563 193.750305,212.876465 
	C193.984238,214.095642 193.981445,215.149811 193.605255,216.607941 
	C189.060181,216.815811 184.936096,220.367355 180.661804,217.229462 
	C180.585144,217.173172 179.570694,218.394302 178.742340,219.014313 
	C178.323166,219.006744 178.155472,219.004272 177.805664,218.734207 
	C176.407730,217.646835 175.191925,216.827026 173.976105,216.007217 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M178.993835,219.019379 
	C179.570694,218.394302 180.585144,217.173172 180.661804,217.229462 
	C184.936096,220.367355 189.060181,216.815811 193.597443,217.003998 
	C193.984177,218.412659 194.005341,219.829193 194.064514,221.673218 
	C194.091263,222.534119 194.079987,222.967575 194.065048,223.728149 
	C193.604996,224.043228 193.148621,224.031174 192.015411,224.063629 
	C190.245819,225.023407 189.153061,225.938660 187.727356,227.132797 
	C184.736603,224.354401 181.865219,221.686890 178.993835,219.019379 
z"/>
<path fill="#F6F8FA" opacity="1.000000" stroke="none" 
	d="
M155.941574,139.011536 
	C154.172607,138.907104 151.898407,136.920258 151.035446,140.657745 
	C149.901825,140.782745 148.802536,140.569138 147.668747,140.348846 
	C148.123871,147.337097 145.782684,149.555130 139.872955,146.966980 
	C141.769012,146.317459 143.158722,145.841415 145.839523,144.923080 
	C143.670441,142.884506 141.977783,141.293686 139.769699,139.218460 
	C141.544952,138.030228 143.265411,136.878693 146.291962,134.852936 
	C143.598923,134.418488 142.252563,133.870773 141.017105,134.057312 
	C134.524307,135.037735 130.837723,131.604996 128.091583,126.346176 
	C127.610443,125.424797 126.513824,124.824821 125.350754,123.651398 
	C125.332756,121.817490 125.665459,120.408592 126.348930,118.961395 
	C129.581055,116.082130 129.174057,111.040176 125.980072,110.008423 
	C126.770584,108.108879 127.561096,106.209335 128.942093,104.261917 
	C130.236404,104.187279 130.940247,104.160515 131.644073,104.133759 
	C131.082535,103.418350 130.520981,102.702942 129.959442,101.987534 
	C132.056686,99.687401 134.153931,97.387276 137.001526,95.039749 
	C139.422241,94.410400 141.108322,93.326492 142.758865,93.378120 
	C144.668060,93.437836 146.798798,94.168228 148.289688,95.314537 
	C148.762100,95.677757 148.003555,98.998116 146.935883,99.792175 
	C138.889771,105.776306 139.342331,113.926537 140.425659,122.519615 
	C142.133270,122.327881 143.588791,122.169701 145.042908,121.999695 
	C146.467834,121.833099 147.891342,121.654449 149.432663,121.466835 
	C147.282623,126.358353 148.732086,129.244797 155.021927,133.394394 
	C155.328140,135.524414 155.634857,137.267975 155.941574,139.011536 
z"/>
<path fill="#FAFAFA" opacity="1.000000" stroke="none" 
	d="
M125.998169,118.999695 
	C125.665459,120.408592 125.332756,121.817490 125.000641,123.613800 
	C124.666962,129.365707 124.332695,134.730209 124.000443,140.548569 
	C123.843628,142.190491 123.684822,143.378555 123.252678,144.805588 
	C122.364616,142.427719 121.152176,139.786880 121.256096,137.198883 
	C121.493095,131.296265 122.226051,125.397949 123.073982,119.543724 
	C123.381035,117.423859 124.590401,115.434692 125.691727,113.597321 
	C125.996330,115.539070 125.997246,117.269379 125.998169,118.999695 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M108.360527,230.960892 
	C107.506142,230.731125 107.017326,230.462616 106.275940,230.073868 
	C106.014252,228.902832 106.005135,227.852051 105.992516,226.406738 
	C107.145958,223.140396 108.302902,220.268600 109.747498,217.198151 
	C112.479729,219.294586 114.802010,221.746918 117.437485,223.796539 
	C118.319702,224.482666 120.001266,224.140945 121.623749,224.108917 
	C121.826164,229.538483 124.484825,232.464752 129.991653,233.957291 
	C138.187698,236.178726 146.162766,239.215469 154.623871,241.954193 
	C154.770599,243.122604 154.525314,244.242081 154.140320,245.682007 
	C146.578674,246.579987 140.159348,244.097397 133.928177,240.200211 
	C131.161636,238.469925 127.405037,238.408234 124.281151,237.138245 
	C121.472443,235.996368 118.715431,234.500961 116.302216,232.676590 
	C113.912247,230.869812 111.770515,229.320465 108.360527,230.960892 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M113.457535,200.072662 
	C111.488464,197.904251 109.881828,195.719421 108.129982,193.215363 
	C110.777435,192.044785 113.570091,191.193405 116.362747,190.342026 
	C116.898392,192.906235 117.434036,195.470444 117.956474,198.463501 
	C116.568840,199.280304 115.194405,199.668274 113.457535,200.072662 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M112.237785,200.909042 
	C112.111137,200.659119 112.311478,200.407181 112.832520,200.144638 
	C112.983856,200.409088 112.787712,200.666748 112.237785,200.909042 
z"/>
<path fill="#B2BACE" opacity="1.000000" stroke="none" 
	d="
M126.735977,198.204361 
	C126.842628,198.861328 126.686172,199.720901 126.267075,200.782318 
	C126.160576,200.125107 126.316727,199.266037 126.735977,198.204361 
z"/>
<path fill="#89CBE4" opacity="1.000000" stroke="none" 
	d="
M91.566139,209.132828 
	C91.518822,209.315689 91.445374,209.354767 91.407227,209.375092 
	C91.450531,209.246948 91.531990,209.098450 91.566139,209.132828 
z"/>
<path fill="#959FB9" opacity="1.000000" stroke="none" 
	d="
M138.999695,227.999695 
	C139.092560,227.963394 139.185730,227.927414 139.139755,227.946014 
	C139.000610,228.000610 139.000000,228.000000 138.999695,227.999695 
z"/>
<path fill="#207CC0" opacity="1.000000" stroke="none" 
	d="
M167.583893,205.949036 
	C167.985565,206.759521 167.991745,207.519257 167.957489,208.657593 
	C165.421432,209.186584 162.925766,209.336990 160.199860,209.217438 
	C159.977646,202.253418 164.182800,205.495789 167.583893,205.949036 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M165.756287,241.156158 
	C167.994568,242.745636 169.987579,244.504395 171.985107,246.627960 
	C171.242142,246.994598 170.494690,246.996414 169.238007,246.710251 
	C167.485428,245.280106 166.242111,244.137955 164.998779,242.995804 
	C165.169525,242.439011 165.340271,241.882217 165.756287,241.156158 
z"/>
<path fill="#294598" opacity="1.000000" stroke="none" 
	d="
M164.741486,243.203003 
	C166.242111,244.137955 167.485428,245.280106 168.864105,246.711121 
	C168.433792,247.506363 167.868149,248.012726 167.148010,248.764252 
	C166.157089,247.143036 165.320648,245.276627 164.741486,243.203003 
z"/>
<path fill="#2D5BA4" opacity="1.000000" stroke="none" 
	d="
M167.682037,238.040283 
	C167.552170,238.642075 167.097977,239.284805 166.323792,239.980377 
	C166.455109,239.382553 166.906403,238.731903 167.682037,238.040283 
z"/>
<path fill="#55AC42" opacity="1.000000" stroke="none" 
	d="
M129.096756,96.172081 
	C129.035522,96.475517 128.887772,96.728394 128.443619,97.004257 
	C126.385460,97.935707 124.537193,98.793640 121.581078,100.165802 
	C124.020576,93.883995 124.896553,88.115982 130.739059,84.798111 
	C132.546371,83.771767 133.890564,81.543297 134.903824,79.584366 
	C137.131531,75.277542 140.109833,72.190460 145.026978,71.073166 
	C146.663635,70.701271 148.073654,69.410088 149.646103,68.662941 
	C154.392792,66.407547 159.158569,64.191353 163.945679,62.023399 
	C164.510956,61.767403 165.416275,62.145676 165.891754,61.825573 
	C172.657028,57.271019 180.069778,60.569092 187.155273,60.059780 
	C189.818726,59.868320 192.200974,60.679188 192.001022,64.583519 
	C185.637390,64.990616 179.060043,63.958702 172.950226,65.209839 
	C159.025635,68.061241 145.083572,71.596985 137.046539,85.915573 
	C137.013153,86.516060 136.999390,87.002441 136.676453,87.027405 
	C135.567734,87.711823 134.781952,88.371277 133.701172,89.048904 
	C132.941956,89.381371 132.477737,89.695656 131.639862,90.013931 
	C130.518219,91.018768 129.770264,92.019608 129.022400,93.414566 
	C129.047256,94.596489 129.072006,95.384285 129.096756,96.172081 
z"/>
<path fill="#1B8F43" opacity="1.000000" stroke="none" 
	d="
M92.980354,120.974823 
	C94.518585,113.944145 95.618202,106.693039 102.675446,102.127350 
	C102.766747,105.118797 102.663864,108.279198 102.310478,111.411324 
	C102.036057,113.843559 101.339157,116.226089 101.019760,118.655647 
	C99.548500,129.847397 101.321655,140.453812 106.542076,150.537842 
	C107.551872,152.488434 107.485970,154.995895 107.971390,157.645599 
	C107.305412,158.055939 106.584312,158.067139 105.172829,157.916290 
	C99.813515,150.818970 96.691002,143.381409 95.898331,134.907730 
	C95.459221,130.213516 93.986931,125.615952 92.980354,120.974823 
z"/>
<path fill="#2E9A43" opacity="1.000000" stroke="none" 
	d="
M115.268326,153.902557 
	C112.716003,150.808762 109.151611,147.897415 108.294144,144.334595 
	C106.455284,136.694061 105.087402,128.696655 105.260902,120.883888 
	C105.433807,113.097878 107.525757,105.315712 109.181976,97.619171 
	C109.914673,94.214294 111.647369,91.024605 112.975906,87.370987 
	C117.069420,87.736221 118.027115,89.460510 115.562881,93.154465 
	C108.088867,104.358292 108.079674,116.926895 109.398506,129.658386 
	C109.805496,133.587280 110.078102,137.584778 111.092094,141.368668 
	C111.486153,142.839142 113.758545,143.806274 115.575684,145.006104 
	C115.657608,146.740341 115.339165,148.471176 115.034698,150.892365 
	C115.378342,152.057297 115.707993,152.531845 116.037651,153.006393 
	C115.935860,153.326294 115.751053,153.587402 115.268326,153.902557 
z"/>
<path fill="#1A8741" opacity="1.000000" stroke="none" 
	d="
M92.661469,121.021942 
	C93.986931,125.615952 95.459221,130.213516 95.898331,134.907730 
	C96.691002,143.381409 99.813515,150.818970 104.812698,157.916809 
	C104.316360,159.599014 103.489761,161.118652 102.405014,162.847275 
	C99.638863,158.922638 94.991188,155.876785 96.572495,149.723328 
	C97.010338,148.019485 95.022530,145.443832 93.701622,143.585129 
	C90.324692,138.833313 89.978020,134.064774 92.324509,128.743225 
	C92.736122,127.809738 91.093773,125.970581 90.194611,124.267822 
	C90.773666,123.013664 91.558121,122.041367 92.661469,121.021942 
z"/>
<path fill="#39A042" opacity="1.000000" stroke="none" 
	d="
M116.259781,152.829773 
	C115.707993,152.531845 115.378342,152.057297 115.036880,151.277298 
	C115.510696,151.532288 115.996307,152.092712 116.259781,152.829773 
z"/>
<path fill="#C7D564" opacity="1.000000" stroke="none" 
	d="
M178.162735,103.082527 
	C178.162735,103.082527 178.577805,103.075478 178.780304,103.514893 
	C178.983566,106.969597 178.984314,109.984886 178.927200,113.413757 
	C178.587799,118.399437 178.236679,122.969353 178.093689,127.545761 
	C178.070023,128.303131 178.773575,129.475342 179.452805,129.796509 
	C182.883804,131.418823 188.978363,127.594978 189.024811,123.860764 
	C189.078110,119.575897 189.027069,115.289726 189.031952,110.650085 
	C189.735367,109.184349 190.428192,108.072647 191.356812,107.298569 
	C191.721283,113.062599 191.849945,118.488991 191.978607,123.915390 
	C191.906052,124.998756 191.833496,126.082115 191.551971,127.745224 
	C191.189011,128.834763 191.035034,129.344574 190.881073,129.854370 
	C190.881073,129.854370 190.941528,129.980789 190.525940,129.935883 
	C184.743347,131.073578 179.376343,132.256180 174.260956,133.316666 
	C174.673813,133.099991 174.835037,133.005432 175.374573,132.752518 
	C176.556183,122.756943 177.359451,112.919731 178.162735,103.082527 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M174.009338,133.438782 
	C179.376343,132.256180 184.743347,131.073578 190.480331,129.882675 
	C190.891113,130.640945 190.931900,131.407501 190.969879,132.555206 
	C190.967041,132.936325 190.485214,132.960800 189.888672,132.974548 
	C188.503754,133.647339 187.715363,134.306381 186.926971,134.965424 
	C186.196548,134.966446 185.466110,134.967453 183.968323,134.980072 
	C180.459198,135.645004 177.717438,136.298309 174.975693,136.951630 
	C170.978928,138.627563 167.072891,140.610306 162.941757,141.831863 
	C161.235123,142.336487 159.045685,141.208252 157.424957,140.482025 
	C162.175125,138.693848 167.299133,138.751480 170.525406,134.122223 
	C171.905731,133.859695 172.957535,133.649231 174.009338,133.438782 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M192.377869,123.855957 
	C191.849945,118.488991 191.721283,113.062599 191.377136,106.982124 
	C191.163528,105.905190 191.165421,105.482338 191.167297,105.059479 
	C191.474579,104.922569 191.701859,104.702881 191.987488,104.202675 
	C192.568039,104.208656 193.400650,104.421959 193.395920,104.614723 
	C193.239120,111.009872 193.002243,117.403061 192.377869,123.855957 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M191.192505,129.875427 
	C191.035034,129.344574 191.189011,128.834763 191.547226,128.152557 
	C191.668961,128.618927 191.586441,129.257706 191.192505,129.875427 
z"/>
<path fill="#065321" opacity="1.000000" stroke="none" 
	d="
M178.082062,102.688667 
	C177.359451,112.919731 176.556183,122.756943 175.376892,132.296982 
	C174.661102,126.968658 174.474136,121.918785 173.861801,116.921021 
	C173.727875,115.827919 172.076813,114.920708 171.098053,113.563919 
	C171.064819,112.468414 171.057968,111.736877 171.309814,110.764557 
	C171.690552,109.350243 171.812592,108.176704 171.958435,106.587112 
	C171.987320,104.781967 171.992416,103.392868 172.047272,101.619400 
	C172.419556,99.366280 172.742126,97.497505 173.033218,95.811050 
	C168.769852,93.749695 164.783478,98.702568 161.161026,94.581238 
	C161.364197,91.451370 161.617676,88.727211 161.977676,85.682816 
	C162.089478,84.914970 162.094772,84.467354 162.100067,84.019730 
	C162.656021,81.077095 163.211990,78.134460 163.843689,74.743370 
	C163.919418,74.294914 164.142090,74.282684 164.142090,74.282684 
	C165.817474,74.916603 167.492844,75.550522 169.514771,76.242630 
	C170.982086,77.235321 172.102844,78.169830 173.533096,79.185394 
	C174.042923,81.320435 174.757767,83.488136 174.334213,85.404213 
	C173.507645,89.143486 174.109756,91.844902 178.197525,93.520416 
	C178.540054,93.990784 178.584442,94.280479 178.628815,95.082626 
	C178.572998,96.012611 178.535385,96.427353 178.497803,96.842094 
	C178.332321,98.659668 178.166840,100.477234 178.082062,102.688667 
z"/>
<path fill="#74B452" opacity="1.000000" stroke="none" 
	d="
M164.076233,73.917068 
	C164.142090,74.282684 163.919418,74.294914 163.878662,74.258453 
	C155.154373,79.450035 146.470871,84.678062 137.399750,89.960892 
	C137.012115,90.015694 136.864807,89.864555 136.910690,89.509995 
	C136.970840,88.437767 136.985107,87.720108 136.999390,87.002441 
	C136.999390,87.002441 137.013153,86.516060 137.019363,86.273193 
	C139.077988,84.727516 141.097351,83.368721 143.189178,82.132614 
	C148.940628,78.733955 154.673798,75.296051 160.556870,72.139534 
	C161.254639,71.765144 162.837677,73.040703 164.076233,73.917068 
z"/>
<path fill="#14813F" opacity="1.000000" stroke="none" 
	d="
M91.467178,166.448486 
	C91.523438,166.414932 91.410919,166.482056 91.467178,166.448486 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M84.991081,142.374908 
	C85.160721,142.880814 85.328766,143.763351 85.243385,144.829132 
	C84.989799,144.258774 84.989647,143.505157 84.991081,142.374908 
z"/>
<path fill="#B8D532" opacity="1.000000" stroke="none" 
	d="
M190.861420,104.825043 
	C191.165421,105.482338 191.163528,105.905190 191.141327,106.644501 
	C190.428192,108.072647 189.735367,109.184349 188.690536,110.602478 
	C183.197067,105.288918 181.881714,106.385536 178.985077,113.000168 
	C178.984314,109.984886 178.983566,106.969597 178.987732,103.507057 
	C179.823761,102.092590 180.746292,101.188957 181.470688,100.147507 
	C185.237137,94.732491 185.506622,94.710518 189.669800,99.957001 
	C190.199020,101.544632 190.377289,103.067627 190.861420,104.825043 
z"/>
<path fill="#8FC445" opacity="1.000000" stroke="none" 
	d="
M178.802444,96.792343 
	C178.535385,96.427353 178.572998,96.012611 178.690338,95.296051 
	C178.882431,95.577019 178.994766,96.159805 178.802444,96.792343 
z"/>
<path fill="#FBFBFC" opacity="1.000000" stroke="none" 
	d="
M161.812012,83.776337 
	C162.094772,84.467354 162.089478,84.914970 161.631485,85.743011 
	C159.794434,91.595131 157.790115,96.996635 157.160858,102.553848 
	C156.016922,112.656395 155.690186,122.851471 155.022430,133.007935 
	C148.732086,129.244797 147.282623,126.358353 149.432663,121.466835 
	C147.891342,121.654449 146.467834,121.833099 145.042908,121.999695 
	C143.588791,122.169701 142.133270,122.327881 140.425659,122.519615 
	C139.342331,113.926537 138.889771,105.776306 146.935883,99.792175 
	C148.003555,98.998116 148.762100,95.677757 148.289688,95.314537 
	C146.798798,94.168228 144.668060,93.437836 142.758865,93.378120 
	C141.108322,93.326492 139.422241,94.410400 137.345367,94.983940 
	C140.748093,92.220573 144.571091,89.484360 148.359909,86.701622 
	C149.919800,85.555954 151.382019,84.277924 152.931824,83.117760 
	C155.109146,81.487869 157.326721,79.911736 160.103226,77.893875 
	C160.818909,80.734566 161.171417,82.133759 161.812012,83.776337 
z"/>
<path fill="#74B452" opacity="1.000000" stroke="none" 
	d="
M129.447708,96.137848 
	C129.072006,95.384285 129.047256,94.596489 129.360382,93.375084 
	C130.470016,91.964302 131.241776,90.987122 132.013535,90.009941 
	C132.477737,89.695656 132.941956,89.381371 133.899841,89.300110 
	C135.217285,89.643616 136.041046,89.754089 136.864807,89.864555 
	C136.864807,89.864555 137.012115,90.015694 137.085571,90.092026 
	C134.705566,92.146782 132.252106,94.125198 129.447708,96.137848 
z"/>
<path fill="#F3F3F7" opacity="1.000000" stroke="none" 
	d="
M126.348938,118.961395 
	C125.997246,117.269379 125.996330,115.539070 125.987076,113.373108 
	C125.978989,112.205582 125.979240,111.473709 125.979782,110.375122 
	C129.174057,111.040176 129.581055,116.082130 126.348938,118.961395 
z"/>
<path fill="#F3F3F7" opacity="1.000000" stroke="none" 
	d="
M129.719788,102.156738 
	C130.520981,102.702942 131.082535,103.418350 131.644073,104.133759 
	C130.940247,104.160515 130.236404,104.187279 129.249908,104.093674 
	C129.138199,103.424194 129.309174,102.875061 129.719788,102.156738 
z"/>
<path fill="#D8D9DA" opacity="1.000000" stroke="none" 
	d="
M168.001343,323.006500 
	C163.206314,322.892609 158.379105,323.074402 153.627899,322.557648 
	C150.615784,322.230042 147.586746,321.141327 144.818542,319.850739 
	C143.296448,319.141113 141.595901,317.437225 141.316208,315.932495 
	C141.135162,314.958466 143.181717,312.817566 144.568466,312.438995 
	C149.774857,311.017731 155.127289,310.137390 160.406616,308.971191 
	C161.270142,308.780426 162.041229,308.171234 162.933014,307.386078 
	C164.075027,307.013031 165.139099,307.009338 166.812973,307.266235 
	C168.615097,307.352844 169.807404,307.178802 170.999710,307.004761 
	C184.085297,307.002869 197.173798,306.844116 210.254822,307.087402 
	C214.610001,307.168427 218.946014,308.280060 223.645386,308.960266 
	C223.998489,309.168274 223.996994,309.336548 223.653015,309.790283 
	C222.539749,311.057404 221.768967,312.039093 220.671631,312.966919 
	C219.235825,312.281921 218.126572,311.650726 216.683258,310.981934 
	C214.567184,311.798950 212.739685,305.126526 210.590607,310.980042 
	C209.134140,310.980927 208.074890,310.978516 206.918274,310.582764 
	C206.321167,309.820648 205.822556,309.131989 205.321564,309.130280 
	C195.317200,309.095917 185.312256,309.117462 175.308945,309.246490 
	C174.861511,309.252228 174.428558,310.379547 173.590179,310.986511 
	C172.128616,310.987091 171.065704,310.985596 169.743225,310.703430 
	C162.991867,311.060486 155.843628,309.768158 150.757553,316.345062 
	C151.977066,317.201324 152.574112,317.845856 153.306305,318.098236 
	C158.188538,319.781067 163.099625,321.380188 168.001343,323.006500 
z"/>
<path fill="#CFCFD1" opacity="1.000000" stroke="none" 
	d="
M220.998169,313.020782 
	C221.768967,312.039093 222.539749,311.057404 223.651001,310.042694 
	C225.088608,310.343781 226.185760,310.677887 227.696091,311.363098 
	C233.163727,315.349640 233.232132,315.523193 228.895401,318.662109 
	C225.081726,321.422424 220.967072,322.640167 216.078903,321.562134 
	C214.636185,321.243927 212.706451,323.133881 210.999893,324.011902 
	C210.556686,324.007172 210.113495,324.002441 209.335938,323.735596 
	C208.998901,323.300751 208.996216,323.128021 208.994293,322.556030 
	C208.998337,321.087799 209.001617,320.018829 209.379333,318.957031 
	C210.502975,318.967987 211.252167,318.971802 212.217682,319.242828 
	C215.891281,321.165161 215.625656,318.728638 215.454727,316.179779 
	C218.698807,317.316345 221.197159,317.191040 220.998169,313.020782 
z"/>
<path fill="#D8D9DA" opacity="1.000000" stroke="none" 
	d="
M211.117065,324.278503 
	C212.706451,323.133881 214.636185,321.243927 216.078903,321.562134 
	C220.967072,322.640167 225.081726,321.422424 228.895401,318.662109 
	C233.232132,315.523193 233.163727,315.349640 228.045807,311.367493 
	C230.056458,311.045502 232.362915,310.510742 234.140778,311.248962 
	C235.998871,312.020569 237.348450,314.016815 238.920303,315.477722 
	C236.790497,317.315399 234.944870,319.970093 232.474731,320.829224 
	C226.381607,322.948364 220.001938,324.243622 213.362061,325.943604 
	C212.402512,325.526459 211.818375,325.035797 211.117065,324.278503 
z"/>
<path fill="#E0E0E1" opacity="1.000000" stroke="none" 
	d="
M170.846710,306.760498 
	C169.807404,307.178802 168.615097,307.352844 167.212204,307.267029 
	C167.001770,306.562012 167.001938,306.116821 167.003189,305.337891 
	C167.447968,305.004089 167.891663,305.003998 168.668213,305.005646 
	C169.565277,305.510315 170.129501,306.013245 170.846710,306.760498 
z"/>
<path fill="#D8D9DA" opacity="1.000000" stroke="none" 
	d="
M208.993530,322.955292 
	C208.996216,323.128021 208.998901,323.300751 209.003540,323.732422 
	C203.979843,324.660248 198.954193,325.329071 193.464508,325.626892 
	C192.994797,324.513031 192.989105,323.770111 193.430573,323.018127 
	C197.929718,322.989380 201.981689,322.969727 206.403351,322.959229 
	C207.513184,322.964020 208.253357,322.959656 208.993530,322.955292 
z"/>
<path fill="#E0E0E1" opacity="1.000000" stroke="none" 
	d="
M192.983398,323.027222 
	C192.989105,323.770111 192.994797,324.513031 193.000244,325.627350 
	C193.000244,326.167603 193.000488,326.336456 193.000061,326.758789 
	C190.629181,327.010651 188.258972,327.009094 185.444687,327.009888 
	C185.000168,326.843292 184.999710,326.674408 184.999390,325.880707 
	C185.005219,324.513000 185.010910,323.770111 185.458649,323.021576 
	C188.261581,323.019714 190.622498,323.023468 192.983398,323.027222 
z"/>
<path fill="#D8D9DA" opacity="1.000000" stroke="none" 
	d="
M185.016602,323.027161 
	C185.010910,323.770111 185.005219,324.513000 184.999756,325.627350 
	C182.958481,325.998688 180.916946,325.998596 178.437988,326.000000 
	C175.716568,325.253296 173.432571,324.505096 171.535004,323.380066 
	C176.286484,323.011230 180.651535,323.019196 185.016602,323.027161 
z"/>
<path fill="#CFCFD1" opacity="1.000000" stroke="none" 
	d="
M185.458649,323.021545 
	C180.651535,323.019196 176.286484,323.011230 171.455597,323.008972 
	C170.242722,323.011292 169.495682,323.007935 168.374985,323.005554 
	C163.099625,321.380188 158.188538,319.781067 153.306305,318.098236 
	C152.574112,317.845856 151.977066,317.201324 150.757553,316.345062 
	C155.843628,309.768158 162.991867,311.060486 169.561920,311.037720 
	C169.113312,312.039246 168.626862,312.504974 168.052277,312.797943 
	C166.079849,313.803741 164.076553,314.749023 162.085022,315.717377 
	C164.184235,316.896759 166.212875,318.931152 168.394577,319.111206 
	C179.277481,320.009460 190.205322,320.349457 201.104538,321.078033 
	C202.781799,321.190155 204.392303,322.301086 206.033676,322.950073 
	C201.981689,322.969727 197.929718,322.989380 193.430573,323.018127 
	C190.622498,323.023468 188.261581,323.019714 185.458649,323.021545 
z"/>
<path fill="#E1F7DE" opacity="1.000000" stroke="none" 
	d="
M155.021912,133.394394 
	C155.690186,122.851471 156.016922,112.656395 157.160858,102.553848 
	C157.790115,96.996635 159.794434,91.595131 161.524979,86.063232 
	C161.617676,88.727211 161.364197,91.451370 161.039322,94.974579 
	C160.729935,98.197197 160.491913,100.620781 160.166473,103.275009 
	C160.079041,103.505661 160.180573,103.989143 160.102539,104.236122 
	C160.054260,104.653198 160.084000,104.823288 159.964218,105.394470 
	C159.702606,107.211136 159.590500,108.626709 159.474670,110.145676 
	C159.470947,110.249062 159.644714,110.361404 159.427887,110.578186 
	C159.218246,111.197273 159.225449,111.599564 159.148209,112.245522 
	C159.092346,112.658707 159.120911,112.828232 159.012054,113.385956 
	C158.739182,116.155838 158.603760,118.537521 158.302582,121.358673 
	C158.048035,125.519753 157.959244,129.241348 157.648758,133.273895 
	C157.220703,135.398239 157.014328,137.211639 156.807953,139.025055 
	C156.807968,139.025055 156.375168,139.034088 156.158371,139.022812 
	C155.634857,137.267975 155.328140,135.524414 155.021912,133.394394 
z"/>
<path fill="#74B452" opacity="1.000000" stroke="none" 
	d="
M156.878021,139.296432 
	C157.014328,137.211639 157.220703,135.398239 158.023804,133.224899 
	C160.819855,128.269867 164.569473,127.845650 168.939392,129.438599 
	C169.371796,131.285049 169.784332,132.729675 170.196869,134.174301 
	C167.299133,138.751480 162.175125,138.693848 157.373993,140.134583 
	C156.965302,139.941147 156.956696,139.754486 156.878021,139.296432 
z"/>
<path fill="#FDFDFB" opacity="1.000000" stroke="none" 
	d="
M190.169373,141.974228 
	C189.048096,141.019424 187.501678,139.542160 187.713516,139.219635 
	C188.509033,138.008347 189.755920,136.801117 191.086990,136.364365 
	C191.699646,136.163345 192.898224,137.748032 193.831116,138.523010 
	C192.737900,139.675980 191.644684,140.828949 190.169373,141.974228 
z"/>
<path fill="#FDFDFB" opacity="1.000000" stroke="none" 
	d="
M175.155426,137.285553 
	C177.717438,136.298309 180.459198,135.645004 183.603119,134.984741 
	C181.900391,139.573929 180.102158,140.121826 175.155426,137.285553 
z"/>
<path fill="#FDFDFB" opacity="1.000000" stroke="none" 
	d="
M187.289688,134.972809 
	C187.715363,134.306381 188.503754,133.647339 189.647644,132.983994 
	C189.219574,133.646530 188.436005,134.313370 187.289688,134.972809 
z"/>
<path fill="#226AB1" opacity="1.000000" stroke="none" 
	d="
M270.554230,168.982666 
	C272.589691,171.367310 274.117218,172.907410 270.178741,175.493866 
	C266.937317,177.622589 265.683807,182.568634 262.974976,185.781921 
	C260.649811,188.540131 257.511810,190.613083 254.367859,192.993469 
	C253.703110,190.932846 253.092545,188.856598 253.166367,186.804977 
	C253.343384,181.886719 253.842026,176.980026 254.288727,171.031494 
	C259.123138,170.406006 264.620239,169.694778 270.554230,168.982666 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M246.231644,169.926086 
	C246.096481,169.704468 246.275772,169.438293 246.767914,169.127289 
	C246.911194,169.354172 246.732971,169.620529 246.231644,169.926086 
z"/>
<path fill="#235DA8" opacity="1.000000" stroke="none" 
	d="
M178.742340,219.014297 
	C181.865219,221.686890 184.736603,224.354401 187.727356,227.132797 
	C189.153061,225.938660 190.245819,225.023407 191.673553,224.067566 
	C191.769852,226.819489 186.132935,234.111404 183.760071,233.683075 
	C181.372711,233.252136 179.072922,231.384003 177.150192,229.677567 
	C175.940720,228.604111 175.495590,226.669434 174.354431,225.061829 
	C174.002411,223.606430 174.003540,222.210312 174.364120,220.399292 
	C175.811646,219.656860 176.899719,219.329330 177.987793,219.001785 
	C178.155472,219.004272 178.323166,219.006744 178.742340,219.014297 
z"/>
<path fill="#2E72AD" opacity="1.000000" stroke="none" 
	d="
M177.805664,218.734207 
	C176.899719,219.329330 175.811646,219.656860 174.377655,219.980743 
	C174.010086,218.918640 173.988434,217.860153 173.971436,216.404449 
	C175.191925,216.827026 176.407730,217.646835 177.805664,218.734207 
z"/>
<path fill="#60B144" opacity="1.000000" stroke="none" 
	d="
M131.639862,90.013931 
	C131.241776,90.987122 130.470016,91.964302 129.360291,92.980957 
	C129.770264,92.019608 130.518219,91.018768 131.639862,90.013931 
z"/>
<path fill="#60B144" opacity="1.000000" stroke="none" 
	d="
M136.910690,89.509995 
	C136.041046,89.754089 135.217285,89.643616 134.194855,89.281937 
	C134.781952,88.371277 135.567734,87.711823 136.676453,87.027405 
	C136.985107,87.720108 136.970840,88.437767 136.910690,89.509995 
z"/>
<path fill="#D0DE29" opacity="1.000000" stroke="none" 
	d="
M178.927200,113.413757 
	C181.881714,106.385536 183.197067,105.288918 188.679947,110.956512 
	C189.027069,115.289726 189.078110,119.575897 189.024811,123.860764 
	C188.978363,127.594978 182.883804,131.418823 179.452805,129.796509 
	C178.773575,129.475342 178.070023,128.303131 178.093689,127.545761 
	C178.236679,122.969353 178.587799,118.399437 178.927200,113.413757 
z"/>
<path fill="#0A6439" opacity="1.000000" stroke="none" 
	d="
M170.525391,134.122223 
	C169.784332,132.729675 169.371796,131.285049 169.142181,129.104187 
	C169.598541,126.594467 169.871948,124.820976 170.358490,123.042465 
	C170.571625,123.037437 170.996155,122.997627 171.294006,122.958572 
	C171.910278,122.513702 172.228699,122.107903 172.547119,121.702103 
	C172.030533,121.467712 171.513962,121.233330 170.988495,120.671432 
	C170.963882,119.907822 170.948166,119.471718 170.956024,118.611580 
	C171.027893,116.767662 171.076172,115.347771 171.124451,113.927887 
	C172.076813,114.920708 173.727875,115.827919 173.861801,116.921021 
	C174.474136,121.918785 174.661102,126.968658 174.998566,132.455353 
	C174.835037,133.005432 174.673813,133.099991 174.260956,133.316681 
	C172.957535,133.649231 171.905731,133.859695 170.525391,134.122223 
z"/>
<path fill="#1B8F43" opacity="1.000000" stroke="none" 
	d="
M171.098053,113.563919 
	C171.076172,115.347771 171.027893,116.767662 170.542709,118.598572 
	C166.736465,119.681732 163.367096,120.353859 159.742523,121.008438 
	C159.147629,120.966995 158.807983,120.943100 158.468323,120.919197 
	C158.603760,118.537521 158.739182,116.155838 159.225006,113.384033 
	C159.575409,112.993904 160.002975,112.981537 160.447357,112.989578 
	C164.278183,112.333527 167.664658,111.669434 171.051117,111.005333 
	C171.057968,111.736877 171.064819,112.468414 171.098053,113.563919 
z"/>
<path fill="#0A6439" opacity="1.000000" stroke="none" 
	d="
M160.253891,103.044365 
	C160.491913,100.620781 160.729935,98.197197 161.089645,95.380280 
	C164.783478,98.702568 168.769852,93.749695 173.033218,95.811050 
	C172.742126,97.497505 172.419556,99.366280 171.633057,101.619034 
	C167.784348,102.675179 164.399582,103.347343 161.004822,104.008255 
	C160.994797,103.997009 160.972580,103.978081 160.906677,103.785172 
	C160.709412,103.340790 160.513794,103.158157 160.253891,103.044365 
z"/>
<path fill="#14813F" opacity="1.000000" stroke="none" 
	d="
M171.309814,110.764557 
	C167.664658,111.669434 164.278183,112.333527 160.443817,112.744568 
	C159.995926,112.491516 159.973511,112.000763 159.917740,111.585625 
	C159.789551,110.900795 159.717133,110.631096 159.644714,110.361404 
	C159.644714,110.361404 159.470947,110.249062 159.878906,110.105713 
	C163.792480,107.492195 167.472305,105.707626 171.934647,107.003159 
	C171.812592,108.176704 171.690552,109.350243 171.309814,110.764557 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M171.958435,106.587112 
	C167.472305,105.707626 163.792480,107.492195 159.882629,110.002327 
	C159.590500,108.626709 159.702606,107.211136 160.163055,105.281967 
	C160.754837,104.569595 160.922653,104.319977 161.014832,104.019516 
	C164.399582,103.347343 167.784348,102.675179 171.583313,102.003387 
	C171.992416,103.392868 171.987320,104.781967 171.958435,106.587112 
z"/>
<path fill="#C4C4C6" opacity="1.000000" stroke="none" 
	d="
M206.403351,322.959229 
	C204.392303,322.301086 202.781799,321.190155 201.104538,321.078033 
	C190.205322,320.349457 179.277481,320.009460 168.394577,319.111206 
	C166.212875,318.931152 164.184235,316.896759 162.085022,315.717377 
	C164.076553,314.749023 166.079849,313.803741 168.052277,312.797943 
	C168.626862,312.504974 169.113312,312.039246 169.821472,311.318390 
	C171.065704,310.985596 172.128616,310.987091 174.060059,310.993256 
	C185.624268,310.990662 196.319946,310.983398 207.015640,310.976074 
	C208.074890,310.978516 209.134140,310.980927 210.873444,311.193848 
	C214.772644,315.036377 215.686050,314.972046 217.017319,311.019531 
	C218.126572,311.650726 219.235825,312.281921 220.671631,312.966919 
	C221.197159,317.191040 218.698807,317.316345 215.454727,316.179779 
	C215.625656,318.728638 215.891281,321.165161 212.351807,318.928467 
	C211.572769,317.203949 211.086090,315.322266 210.140701,315.053375 
	C206.998795,314.159790 203.697403,313.434906 200.447342,313.392212 
	C192.802750,313.291779 185.152496,313.623322 177.504517,313.780060 
	C177.358047,314.354034 177.211578,314.928040 177.065109,315.502045 
	C178.904846,316.647339 180.754913,318.782593 182.582489,318.763550 
	C189.794037,318.688324 196.993912,317.830170 204.209213,317.519897 
	C205.784363,317.452209 207.405121,318.445282 209.004883,318.949829 
	C209.001617,320.018829 208.998337,321.087799 208.994293,322.556030 
	C208.253357,322.959656 207.513184,322.964020 206.403351,322.959229 
z"/>
<path fill="#CFCFD1" opacity="1.000000" stroke="none" 
	d="
M206.918274,310.582764 
	C196.319946,310.983398 185.624268,310.990662 174.458710,310.991211 
	C174.428558,310.379547 174.861511,309.252228 175.308945,309.246490 
	C185.312256,309.117462 195.317200,309.095917 205.321564,309.130280 
	C205.822556,309.131989 206.321167,309.820648 206.918274,310.582764 
z"/>
<path fill="#CFCFD1" opacity="1.000000" stroke="none" 
	d="
M216.683258,310.981934 
	C215.686050,314.972046 214.772644,315.036377 211.270660,311.190552 
	C212.739685,305.126526 214.567184,311.798950 216.683258,310.981934 
z"/>
<path fill="#B3B4B6" opacity="1.000000" stroke="none" 
	d="
M209.379333,318.957031 
	C207.405121,318.445282 205.784363,317.452209 204.209213,317.519897 
	C196.993912,317.830170 189.794037,318.688324 182.582489,318.763550 
	C180.754913,318.782593 178.904846,316.647339 177.065109,315.502045 
	C177.211578,314.928040 177.358047,314.354034 177.504517,313.780060 
	C185.152496,313.623322 192.802750,313.291779 200.447342,313.392212 
	C203.697403,313.434906 206.998795,314.159790 210.140701,315.053375 
	C211.086090,315.322266 211.572769,317.203949 212.135468,318.661194 
	C211.252167,318.971802 210.502975,318.967987 209.379333,318.957031 
z"/>
<path fill="#4FA44A" opacity="1.000000" stroke="none" 
	d="
M158.302582,121.358673 
	C158.807983,120.943100 159.147629,120.966995 159.753723,121.402199 
	C161.340240,122.488976 162.624741,123.626076 163.988785,123.731201 
	C166.011398,123.887093 168.090378,123.311638 170.145355,123.047493 
	C169.871948,124.820976 169.598541,126.594467 169.122330,128.702362 
	C164.569473,127.845650 160.819855,128.269867 158.245514,132.913971 
	C157.959244,129.241348 158.048035,125.519753 158.302582,121.358673 
z"/>
<path fill="#1B8F43" opacity="1.000000" stroke="none" 
	d="
M159.427887,110.578186 
	C159.717133,110.631096 159.789551,110.900795 159.732941,111.587509 
	C159.603928,112.004539 159.232635,112.001862 159.232635,112.001862 
	C159.225449,111.599564 159.218246,111.197273 159.427887,110.578186 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M159.148193,112.245522 
	C159.232635,112.001862 159.603928,112.004539 159.788727,112.002655 
	C159.973511,112.000763 159.995926,112.491516 159.999451,112.736526 
	C160.002975,112.981537 159.575409,112.993904 159.362457,112.995834 
	C159.120911,112.828232 159.092346,112.658707 159.148193,112.245522 
z"/>
<path fill="#0A6439" opacity="1.000000" stroke="none" 
	d="
M161.004822,104.008263 
	C160.922653,104.319977 160.754837,104.569595 160.312561,104.880875 
	C160.084000,104.823288 160.054260,104.653198 160.300568,104.236694 
	C160.576645,103.990280 160.972580,103.978081 160.972580,103.978081 
	C160.972580,103.978081 160.994797,103.997009 161.004822,104.008263 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M160.906677,103.785172 
	C160.972580,103.978081 160.576645,103.990280 160.378601,103.989716 
	C160.180573,103.989143 160.079041,103.505661 160.166473,103.275009 
	C160.513794,103.158157 160.709412,103.340790 160.906677,103.785172 
z"/>
<path fill="#2E9A43" opacity="1.000000" stroke="none" 
	d="
M170.358490,123.042465 
	C168.090378,123.311638 166.011398,123.887093 163.988785,123.731201 
	C162.624741,123.626076 161.340240,122.488976 160.008942,121.419746 
	C163.367096,120.353859 166.736465,119.681732 170.519135,119.022614 
	C170.948166,119.471718 170.963882,119.907822 170.977844,121.004517 
	C170.982788,122.109283 170.989471,122.553452 170.996155,122.997627 
	C170.996155,122.997627 170.571625,123.037437 170.358490,123.042465 
z"/>
<path fill="#0F703E" opacity="1.000000" stroke="none" 
	d="
M171.294006,122.958572 
	C170.989471,122.553452 170.982788,122.109283 170.986740,121.332024 
	C171.513962,121.233330 172.030533,121.467712 172.547119,121.702103 
	C172.228699,122.107903 171.910278,122.513702 171.294006,122.958572 
z"/>

        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}